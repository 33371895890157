import { combineReducers } from 'redux'
import reducers from './reducers'
import { genesisReducers, store } from '@alatimier/genesis-uic'

store.replaceReducer(
	combineReducers({
		...genesisReducers,
		...reducers,
	})
)

export default store
