import { SALES_UPLOAD, SALES_UPLOAD_FAILURE, SALES_UPLOAD_SUCCESS } from './salesTypes'

const initialState = {
	pending: false,
}

const salesReducer = (state = initialState, action) => {
	switch (action.type) {
		case SALES_UPLOAD:
			return {
				...state,
				pending: true,
			}
		case SALES_UPLOAD_SUCCESS:
			return {
				...state,
				pending: false,
			}
		case SALES_UPLOAD_FAILURE:
			return {
				...state,
				pending: false,
			}
		default:
			return state
	}
}

export default salesReducer
