import { RESOLUTIONS_GET_RESOLUTIONS, RESOLUTIONS_GET_RESOLUTIONS_FAILURE, RESOLUTIONS_GET_RESOLUTIONS_SUCCESS } from './resolutionsTypes'

const initialState = {
	pending: false,
	list: [],
}

const resolutionsReducer = (state = initialState, action) => {
	switch (action.type) {
		case RESOLUTIONS_GET_RESOLUTIONS:
			return {
				...state,
				pending: true,
			}
		case RESOLUTIONS_GET_RESOLUTIONS_SUCCESS:
			return {
				pending: false,
				list: action.list,
			}
		case RESOLUTIONS_GET_RESOLUTIONS_FAILURE:
			return {
				...state,
				pending: false,
			}
		default:
			return state
	}
}

export default resolutionsReducer
