export const svgToBlob = (svgString) => {
	const svg = '<?xml version="1.0"?>\n' + svgString
	const sliceSize = 512
	const byteArrays = []
	for (let offset = 0; offset < svg.length; offset += sliceSize) {
		const slice = svg.slice(offset, offset + sliceSize)
		const byteNumbers = new Array(slice.length)
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i)
		}
		const byteArray = new Uint8Array(byteNumbers)
		byteArrays.push(byteArray)
	}
	const blob = new Blob(byteArrays, { type: 'image/svg+xml' })
	return blob
}

export const getFileExtension = (fileName) => fileName.replace(/^.*\./, '')
