/** @jsxImportSource @emotion/react */
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { editorContext, TAB_SHAPE, TAB_TEXT, TAB_IMAGES, TAB_WIDGET, SET_FORMAT, TAB_VISUAL, SET_RESOLUTION } from '../editorContext'
import TabIcon from './TabIcon'
import AspectRatioSelect from '../../ratios/AspectRatioSelect'
import ResolutionSelect from '../../resolutions/ResolutionSelect'

const TopBar = () => {
	const { t } = useTranslation()
	const [editorState, dispatchEditorState] = useContext(editorContext)

	const setFormat = (format) => dispatchEditorState({ type: SET_FORMAT, format })
	const setResolution = (resolution) => dispatchEditorState({ type: SET_RESOLUTION, resolution })

	return (
		<div id="topBar" className="d-flex justify-content-between">
			<nav>
				<TabIcon icon="fa-shapes" name={TAB_SHAPE} />
				<TabIcon icon="fa-font" name={TAB_TEXT} />
				<TabIcon icon="fa-file-download" name={TAB_VISUAL} />
				<TabIcon icon="fa-images" name={TAB_IMAGES} />
				{/* <TabIcon icon="fa-cube" name={TAB_WIDGET} /> // V2 */}
			</nav>
			<div className="d-flex align-items-center">
				<label htmlFor="formatSelect" className="d-flex align-items-center">
					{t('editor.legend.format')}
					<AspectRatioSelect id="formatSelect" value={editorState.format} onChange={setFormat} selectClassName="ms-2" simple />
					<ResolutionSelect id="resolutionSelect" value={editorState.resolution} onChange={setResolution} selectClassName="ms-2" simple />
				</label>
			</div>
		</div>
	)
}

export default TopBar
