import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { GSecured, GButton, GCard, GMessage, GTable, GTitleBar, GIcon, isEmpty } from '@alatimier/genesis-uic'
import './ObjectiveList.scss'
import SalesChart from '../../components/objectives/SalesChart/SalesChart'
import AttentionCompareChart from '../../components/objectives/AttentionCompareChart/AttentionCompareChart'
import TradeMarketingChart from '../../components/objectives/TradeMarketingChart/TradeMarketingChart'
import GeneralROIChart from '../../components/objectives/GeneralROIChart/GeneralROIChart'
import ReachChart from '../../components/objectives/ReachChart/ReachChart'
import { deleteObjectives, getObjectives } from '../../store/objectives/objectivesActions'
import { isType, objectiveGraphContext, objectiveTypes } from '../../components/objectives/utils'

const ObjectiveList = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const objectives = useSelector((state) => state.objectives.list)
	const pending = useSelector((state) => state.objectives.pending)
	const status = useSelector((state) => state.objectives.status)

	useEffect(() => fetchObjectives(), [])
	useEffect(() => handleStatusChange(), [status])

	const fetchObjectives = () => {
		dispatch(getObjectives(true))
	}

	const handleStatusChange = () => {
		if (status === 'deleted') {
			dispatch(getObjectives(false))
		}
	}

	const handleEdit = (id) => {
		navigate(`/objectives/${id}`)
	}

	const handleDelete = (ids) => {
		dispatch(deleteObjectives(ids))
	}

	const columns = [
		{ itemProp: 'name', label: t('objectives.table.name'), width: '20%' },
		{ itemProp: 'progression', label: t('objectives.table.progression'), width: '60%', filter: false },
		{ itemProp: 'deadline', label: t('objectives.table.deadline'), width: '10%' },
	]

	const rowActions = [
		{
			title: t('action.edit'),
			icon: 'fa-edit',
			fn: (item) => handleEdit(item.id),
			permission: 'OBJECTIVES_EDIT',
		},
		{
			title: t('action.delete'),
			icon: 'fa-trash',
			fn: (item) => handleDelete([item.id]),
			confirmMessage: t('action.delete.confirm'),
			permission: 'OBJECTIVES_EDIT',
		},
	]

	const globalActions = [
		{
			icon: 'fa-trash',
			title: t('action.delete'),
			fn: (selected) => handleDelete(selected.map((item) => item.id)),
			confirmMessage: t('action.delete.confirm'),
			permission: 'OBJECTIVES_EDIT',
		},
	]

	// Mapper to human-readable values which will also be used for filtering, and row colors
	const objectiveRawValueMapper = (objective) => ({
		...objective,
		progression: objective.data?.progression != null ? Math.floor(objective.data.progression) + '%' : '-',
		deadline: DateTime.fromISO(objective.deadline).toFormat(t('format.date')),
		_props: {
			color: { success: 'success', failure: 'danger' }[objective.data?.result] ?? 'secondary',
		},
	})

	const richCellContent = {
		progression: (objective) => (
			<td>
				<div className="d-flex align-items-center justify-content-around">
					{objective.data ? (
						<>
							{objective.progression}
							<div className="d-flex justify-content-center align-items-center" style={{ width: '85%', minHeight: '150px' }}>
								{isType(objective, objectiveTypes.IMPROVE_SALES) && (
									<SalesChart data={objective.data} target={objective.target} context={objectiveGraphContext.LIST} />
								)}
								{isType(objective, objectiveTypes.IMPROVE_ATTENTION_TIME) && (
									<AttentionCompareChart data={objective.data} target={objective.target} context={objectiveGraphContext.LIST} />
								)}
								{isType(objective, objectiveTypes.ROI_SALES) && (
									<TradeMarketingChart data={objective.data} cost={objective.cost} context={objectiveGraphContext.LIST} />
								)}
								{isType(objective, objectiveTypes.ROI) && (
									<GeneralROIChart
										data={objective.data}
										initialCost={objective.initialCost}
										monthlyCost={objective.monthlyCost}
										target={objective.target}
										context={objectiveGraphContext.LIST}
									/>
								)}
								{isType(objective, objectiveTypes.REACH) && (
									<ReachChart data={objective.data} target={objective.target} progression={objective.data.progression} />
								)}
							</div>
						</>
					) : objective.error ? (
						<GMessage value={t('error.unknown')} color="danger" className="mb-0" />
					) : (
						<GIcon name="fa-spinner" spin />
					)}
				</div>
			</td>
		),
	}

	const renderPending = () => (
		<div className="text-center">
			<GIcon name="fa-spinner" spin />
		</div>
	)

	const renderObjectives = () => {
		if (isEmpty(objectives)) {
			return <GMessage value={t('objectives.list.empty')} />
		}
		return (
			<GTable
				id="objectivesList"
				items={objectives.map((o) => objectiveRawValueMapper(o))}
				columns={columns}
				hover
				columnSorter
				columnFilter
				tableFilter
				rowActions={rowActions}
				globalActions={globalActions}
				pagination
				itemsPerPage={15}
				richCellContent={richCellContent}
			/>
		)
	}

	return (
		<GSecured permission="OBJECTIVES_READ">
			<GTitleBar>{t('objectives.title')}</GTitleBar>
			<GCard title={t('objectives.list.title')} row className="table-overflow-visible">
				{pending && isEmpty(objectives) ? renderPending() : renderObjectives()}
				<GSecured permission="OBJECTIVES_EDIT">
					<Link to="/objectives/new">
						<GButton label={t('objectives.action.create')} location={['page', 'page-top']} />
					</Link>
				</GSecured>
			</GCard>
		</GSecured>
	)
}

export default ObjectiveList
