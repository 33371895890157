import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { GInput, GSecured, GButton, userService } from '@alatimier/genesis-uic'
import { variantShape } from './VariantTypes'

const VariantForm = (props) => {
	const { t } = useTranslation()

	const isDarkMode = useSelector((store) => store.g_display.darkMode)

	const [software, setSoftware] = useState(props.variant?.software)
	const [name, setName] = useState(props.variant?.name)
	const [value, setValue] = useState(props.variant?.value)

	useEffect(() => props.onChange && props.onChange({ software, name, value }, props.index), [software, name, value])

	const handleSubmit = (e) => {
		e.preventDefault()
		if (props.onAdd && props.onAdd({ software, name, value })) {
			setSoftware('')
			setName('')
			setValue('')
			e.target.querySelector('input').focus()
		}
	}

	const isEdition = !!props.variant
	const hasEditPermission = userService.hasPermission('DVM_EDIT')

	return (
		<>
			<form className="d-flex mb-3" onSubmit={handleSubmit}>
				<GInput
					id="software"
					value={props.variant ? props.variant.software : software}
					placeholder={t('variants.form.software')}
					onChange={setSoftware}
					inputClassName="pe-0 me-2 my-1"
					simple
					required={isEdition}
					readOnly={!hasEditPermission}
				/>
				<GInput
					id="name"
					value={props.variant ? props.variant.name : name}
					placeholder={t('variants.form.name')}
					onChange={setName}
					inputClassName="pe-0 me-2 my-1"
					simple
					required={isEdition}
					readOnly={!hasEditPermission}
				/>
				<GInput
					id="value"
					value={props.variant ? props.variant.value : value}
					placeholder={t('variants.form.value')}
					onChange={setValue}
					inputClassName="pe-0 me-2 my-1"
					simple
					required={isEdition}
					readOnly={!hasEditPermission}
				/>
				{isEdition ? (
					<GSecured permission="DVM_EDIT">
						<GButton
							icon="fa-minus"
							title={t('action.remove')}
							onClick={props.onRemove}
							color={isDarkMode ? 'dark' : 'light'}
							className="mx-1 my-1"
						/>
					</GSecured>
				) : (
					<GSecured permission="DVM_EDIT">
						<GButton type="submit" icon="fa-plus" title={t('action.add')} color={isDarkMode ? 'dark' : 'light'} className="mx-1 my-1" />
					</GSecured>
				)}
			</form>
		</>
	)
}

VariantForm.propTypes = {
	variant: variantShape,
	index: PropTypes.number,
	onAdd: PropTypes.func,
	onChange: PropTypes.func,
	onRemove: PropTypes.func,
}

export default VariantForm
