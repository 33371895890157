import { apiClientService as client, setErrors, setSuccess } from '@alatimier/genesis-uic'
import { getObjectiveProgression, getObjectiveType } from '../../components/objectives/utils'
import { getCampaigns, getVisuals } from '../organizations/organizationsActions'
import { getObjectiveStats, ServerException } from './getObjectiveStats'
import {
	OBJECTIVES_CLEAR_STATE,
	OBJECTIVES_CREATE_OBJECTIVE,
	OBJECTIVES_CREATE_OBJECTIVE_FAILURE,
	OBJECTIVES_CREATE_OBJECTIVE_SUCCESS,
	OBJECTIVES_DELETE_OBJECTIVE,
	OBJECTIVES_DELETE_OBJECTIVE_FAILURE,
	OBJECTIVES_DELETE_OBJECTIVE_SUCCESS,
	OBJECTIVES_GET_OBJECTIVE,
	OBJECTIVES_GET_OBJECTIVE_FAILURE,
	OBJECTIVES_GET_OBJECTIVE_SUCCESS,
	OBJECTIVES_GET_OBJECTIVES,
	OBJECTIVES_GET_OBJECTIVES_FAILURE,
	OBJECTIVES_GET_OBJECTIVES_SUCCESS,
	OBJECTIVES_UPDATE_OBJECTIVE,
	OBJECTIVES_UPDATE_OBJECTIVE_FAILURE,
	OBJECTIVES_UPDATE_OBJECTIVE_SUCCESS,
	OBJECTIVES_GET_PREVIEW_STATISTICS,
	OBJECTIVES_GET_PREVIEW_STATISTICS_SUCCESS,
	OBJECTIVES_GET_PREVIEW_STATISTICS_FAILURE,
	OBJECTIVES_GET_OBJECTIVE_STATISTICS_SUCCESS,
	OBJECTIVES_GET_OBJECTIVE_STATISTICS_FAILURE,
} from './objectivesTypes'

const objectivesEndPoint = '/api/admin/v1/objectives'

const updateOrganizationsStateForObjective = async (dispatch, getState, objective) => {
	const objectiveType = getObjectiveType(objective.type, objective.metric)
	if (!getState().organizations.visuals[objective.organizationId]) {
		await getVisuals(objective.organizationId)(dispatch, getState)
	}
	if (objectiveType.selectors.campaign && !getState().organizations.campaigns[objective.targetOrganizationId]) {
		await getCampaigns(objective.targetOrganizationId, 'TARGET')(dispatch, getState)
	}
	return getState()
}

export const getResult = (progression, deadline) => {
	if (progression != null && deadline) {
		const now = new Date().toISOString()
		if (progression >= 100) return 'success'
		else if (now > deadline) return 'failure'
		else return undefined
	} else {
		return undefined
	}
}

const fetchObjectiveStatistics = (dispatch, getState) => async (objective) => {
	try {
		const state = await updateOrganizationsStateForObjective(dispatch, getState, objective)
		const data = await getObjectiveStats(state, objective)
		const progression = getObjectiveProgression(data, {
			target: objective.target,
			visualCost: objective.cost,
			hardwareCost: objective.initialCost,
			monthlySoftwareCost: objective.monthlyCost,
		})
		const result = getResult(progression, objective.deadline)
		dispatch({
			type: OBJECTIVES_GET_OBJECTIVE_STATISTICS_SUCCESS,
			id: objective.id,
			data: { ...data, progression, result },
		})
	} catch (e) {
		if (e instanceof ServerException) {
			dispatch({
				type: OBJECTIVES_GET_OBJECTIVE_STATISTICS_FAILURE,
				id: objective.id,
			})
		} else {
			throw e
		}
	}
}

export const getObjectives = (updateStatistics) => {
	return (dispatch, getState) => {
		dispatch({ type: OBJECTIVES_GET_OBJECTIVES })
		return client.get(objectivesEndPoint).then(async (res) => {
			if (res?.ok) {
				const objectives = await res.json()
				dispatch({
					type: OBJECTIVES_GET_OBJECTIVES_SUCCESS,
					list: objectives,
				})
				if (updateStatistics) {
					await Promise.all(objectives.map(fetchObjectiveStatistics(dispatch, getState)))
				}
			} else {
				dispatch({ type: OBJECTIVES_GET_OBJECTIVES_FAILURE })
			}
		})
	}
}

export const getObjective = (id) => {
	return (dispatch) => {
		dispatch({ type: OBJECTIVES_GET_OBJECTIVE })
		return client.get(`${objectivesEndPoint}/${id}`).then(async (res) => {
			if (res?.ok) {
				const data = await res.json()
				dispatch({
					type: OBJECTIVES_GET_OBJECTIVE_SUCCESS,
					objective: data,
				})
			} else {
				dispatch({
					type: OBJECTIVES_GET_OBJECTIVE_FAILURE,
				})
			}
		})
	}
}

export const createObjective = (
	organizationId,
	targetOrganizationId,
	type,
	metric,
	campaignId,
	visualId,
	referenceCampaignId,
	referenceVisualId,
	referenceStartDate,
	referenceEndDate,
	cost,
	startDate,
	initialCost,
	monthlyCost,
	target,
	name,
	deadline
) => {
	return (dispatch) => {
		dispatch({ type: OBJECTIVES_CREATE_OBJECTIVE })
		return client
			.post(objectivesEndPoint, {
				organizationId,
				targetOrganizationId,
				type,
				metric,
				campaignId,
				visualId,
				referenceCampaignId,
				referenceVisualId,
				referenceStartDate,
				referenceEndDate,
				cost,
				startDate,
				initialCost,
				monthlyCost,
				target,
				name,
				deadline,
			})
			.then(async (res) => {
				const data = await res.json()
				if (res?.ok) {
					dispatch(setSuccess('action.save.success'))
					dispatch({
						type: OBJECTIVES_CREATE_OBJECTIVE_SUCCESS,
						newObjective: data,
					})
				} else {
					dispatch(setErrors(data.errors, data.validationErrors))
					dispatch({
						type: OBJECTIVES_CREATE_OBJECTIVE_FAILURE,
					})
				}
			})
	}
}

export const updateObjective = (
	id,
	organizationId,
	targetOrganizationId,
	type,
	metric,
	campaignId,
	visualId,
	referenceCampaignId,
	referenceVisualId,
	referenceStartDate,
	referenceEndDate,
	cost,
	startDate,
	initialCost,
	monthlyCost,
	target,
	name,
	deadline
) => {
	return (dispatch) => {
		dispatch({ type: OBJECTIVES_UPDATE_OBJECTIVE })
		return client
			.put(`${objectivesEndPoint}/${id}`, {
				organizationId,
				targetOrganizationId,
				type,
				metric,
				campaignId,
				visualId,
				referenceCampaignId,
				referenceVisualId,
				referenceStartDate,
				referenceEndDate,
				cost,
				startDate,
				initialCost,
				monthlyCost,
				target,
				name,
				deadline,
			})
			.then(async (res) => {
				if (res?.ok) {
					dispatch(setSuccess('action.save.success'))
					dispatch({
						type: OBJECTIVES_UPDATE_OBJECTIVE_SUCCESS,
					})
				} else {
					const data = await res.json()
					dispatch(setErrors(data.errors, data.validationErrors))
					dispatch({
						type: OBJECTIVES_UPDATE_OBJECTIVE_FAILURE,
					})
				}
			})
	}
}

export const deleteObjectives = (ids) => {
	return (dispatch) => {
		dispatch({ type: OBJECTIVES_DELETE_OBJECTIVE })
		const promises = ids.map((id) => client.del(`${objectivesEndPoint}/${id}`))
		return Promise.all(promises).then(async (results) => {
			if (results.every((r) => r?.ok)) {
				dispatch(setSuccess('action.delete.success'))
			} else {
				const data = await results.find((r) => !r?.ok).json()
				dispatch(setErrors(data.errors, data.validationErrors))
				dispatch({ type: OBJECTIVES_DELETE_OBJECTIVE_FAILURE })
			}
			if (results.some((r) => r?.ok)) dispatch({ type: OBJECTIVES_DELETE_OBJECTIVE_SUCCESS })
		})
	}
}

export const getPreviewStatistics = (objective) => {
	return async (dispatch, getState) => {
		dispatch({ type: OBJECTIVES_GET_PREVIEW_STATISTICS })
		try {
			const state = await updateOrganizationsStateForObjective(dispatch, getState, objective)
			dispatch({
				type: OBJECTIVES_GET_PREVIEW_STATISTICS_SUCCESS,
				data: await getObjectiveStats(state, objective),
			})
		} catch (e) {
			if (e instanceof ServerException) {
				dispatch(setErrors(['error.unknown']))
				dispatch({ type: OBJECTIVES_GET_PREVIEW_STATISTICS_FAILURE })
			} else {
				throw e
			}
		}
	}
}

export const clearState = () => {
	return {
		type: OBJECTIVES_CLEAR_STATE,
	}
}
