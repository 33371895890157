export const OBJECTIVES_GET_OBJECTIVES = '@@objectives/GET_OBJECTIVES'
export const OBJECTIVES_GET_OBJECTIVES_SUCCESS = '@@objectives/GET_OBJECTIVES_SUCCESS'
export const OBJECTIVES_GET_OBJECTIVES_FAILURE = '@@objectives/GET_OBJECTIVES_FAILURE'

export const OBJECTIVES_GET_OBJECTIVE = '@@objectives/GET_OBJECTIVE'
export const OBJECTIVES_GET_OBJECTIVE_SUCCESS = '@@objectives/GET_OBJECTIVE_SUCCESS'
export const OBJECTIVES_GET_OBJECTIVE_FAILURE = '@@objectives/GET_OBJECTIVE_FAILURE'

export const OBJECTIVES_CREATE_OBJECTIVE = '@@objectives/CREATE_OBJECTIVE'
export const OBJECTIVES_CREATE_OBJECTIVE_SUCCESS = '@@objectives/CREATE_OBJECTIVE_SUCCESS'
export const OBJECTIVES_CREATE_OBJECTIVE_FAILURE = '@@objectives/CREATE_OBJECTIVE_FAILURE'

export const OBJECTIVES_UPDATE_OBJECTIVE = '@@objectives/UPDATE_OBJECTIVE'
export const OBJECTIVES_UPDATE_OBJECTIVE_SUCCESS = '@@objectives/UPDATE_OBJECTIVE_SUCCESS'
export const OBJECTIVES_UPDATE_OBJECTIVE_FAILURE = '@@objectives/UPDATE_OBJECTIVE_FAILURE'

export const OBJECTIVES_DELETE_OBJECTIVE = '@@objectives/DELETE_OBJECTIVE'
export const OBJECTIVES_DELETE_OBJECTIVE_SUCCESS = '@@objectives/DELETE_OBJECTIVE_SUCCESS'
export const OBJECTIVES_DELETE_OBJECTIVE_FAILURE = '@@objectives/DELETE_OBJECTIVE_FAILURE'

export const OBJECTIVES_GET_PREVIEW_STATISTICS = '@@objectives/GET_PREVIEW_STATISTICS'
export const OBJECTIVES_GET_PREVIEW_STATISTICS_SUCCESS = '@@objectives/GET_PREVIEW_STATISTICS_SUCCESS'
export const OBJECTIVES_GET_PREVIEW_STATISTICS_FAILURE = '@@objectives/GET_PREVIEW_STATISTICS_FAILURE'

export const OBJECTIVES_GET_OBJECTIVE_STATISTICS_SUCCESS = '@@objectives/GET_OBJECTIVE_STATISTICS_SUCCESS'
export const OBJECTIVES_GET_OBJECTIVE_STATISTICS_FAILURE = '@@objectives/GET_OBJECTIVE_STATISTICS_FAILURE'

export const OBJECTIVES_CLEAR_STATE = '@@objectives/CLEAR_STATE'
