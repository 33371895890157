import { useTranslation } from 'react-i18next'
import { GCard, GSecured, GTitleBar } from '@alatimier/genesis-uic'
import { EditorContextProvider } from '../../components/editor/editorContext'
import TopBar from '../../components/editor/TopBar/TopBar'
import LeftPanel from '../../components/editor/LeftPanel/LeftPanel'
import RightPanel from '../../components/editor/RightPanel/RightPanel'
import EditorView from '../../components/editor/EditorView/EditorView'
import './Editor.scss'

const Editor = () => {
	const { t } = useTranslation()

	const handleDragStart = (e) => {
		e.preventDefault()
	}

	const handleDrop = (e) => {
		e.preventDefault()
	}
	const handleDragOver = (e) => {
		e.preventDefault()
		e.dataTransfer.dropEffect = 'none'
	}

	return (
		<GSecured permission="VISUALS_EDIT">
			<EditorContextProvider>
				<GTitleBar>{t('editor.title')}</GTitleBar>
				<GCard title={<TopBar />} className="editor" row>
					<div className="d-flex position-relative" onDragStart={handleDragStart} onDragOver={handleDragOver} onDrop={handleDrop}>
						<LeftPanel />
						<EditorView />
						<RightPanel />
					</div>
				</GCard>
			</EditorContextProvider>
		</GSecured>
	)
}

export default Editor
