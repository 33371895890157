import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { GButton } from '@alatimier/genesis-uic'
import { editorContext, UPDATE } from '../../editorContext'
import { deleteSelected, clearDrawing, setMode, undo, redo } from '../../utils/actions'

const GeneralTools = () => {
	const { t } = useTranslation()
	const [editorState, dispatchEditorState] = useContext(editorContext)

	const clickUndo = () => undo(editorState)
	const clickRedo = () => redo(editorState)

	const clickMode = (mode) => () => {
		setMode(editorState, dispatchEditorState, mode)
	}

	const clickClone = () => {
		editorState.canvas.cloneSelectedElements(20, 20)
	}

	const clickEraseAll = () => {
		clearDrawing(editorState.canvas)
		setMode(editorState, dispatchEditorState, 'select')
		dispatchEditorState({ type: UPDATE, new: true })
	}

	const clickDelete = () => {
		if (editorState.mode === 'textedit') editorState.canvas.textActions.toSelectMode()
		deleteSelected(editorState, dispatchEditorState)
	}

	const elementsAreSelected = editorState.selectedElement || editorState.multiSelected
	const canDelete = elementsAreSelected || editorState.images.selected

	return (
		<div className="generalTools">
			<div className="toolsGroup">
				<GButton
					icon="fa-undo"
					color="link"
					className="text-secondary"
					onClick={clickUndo}
					title={t('editor.action.undo')}
					disabled={editorState.canvas?.undoMgr.getUndoStackSize() === 0}
				/>
				<GButton
					icon="fa-redo"
					color="link"
					className="text-secondary"
					onClick={clickRedo}
					title={t('editor.action.redo')}
					disabled={editorState.canvas?.undoMgr.getRedoStackSize() === 0}
				/>
			</div>
			<div className="toolsGroup">
				<GButton
					icon="fa-hand-paper"
					color="link"
					className="text-secondary"
					onClick={clickMode('panning')}
					title={t('editor.action.hand')}
					active={editorState.mode === 'panning'}
				/>
				<GButton
					icon="fa-mouse-pointer"
					color="link"
					className="text-secondary"
					onClick={clickMode('select')}
					title={t('editor.action.select')}
					active={editorState.mode === 'select'}
				/>
			</div>
			<div className="toolsGroup">
				<GButton
					icon="fa-clone"
					color="link"
					className="text-secondary"
					onClick={clickClone}
					title={t('editor.action.clone')}
					disabled={!elementsAreSelected}
				/>
			</div>
			<div className="toolsGroup">
				<GButton
					icon="fa-eraser"
					color="link"
					className="text-secondary"
					onClick={clickEraseAll}
					confirmTitle={t('editor.action.clear')}
					confirmMessage={editorState.new ? undefined : t('editor.action.clear.confirmation')}
					title={t('editor.action.clear')}
					disabled={false}
				/>
				<GButton
					icon="fa-trash"
					color="link"
					className="text-secondary"
					onClick={clickDelete}
					title={`${t('editor.action.delete.selected')} (${t('editor.shortcut.delete')})`}
					disabled={!canDelete}
				/>
			</div>
		</div>
	)
}

export default GeneralTools
