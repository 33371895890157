import {
	SEGMENTS_CLEAR_STATE,
	SEGMENTS_CREATE_SEGMENT,
	SEGMENTS_CREATE_SEGMENT_FAILURE,
	SEGMENTS_CREATE_SEGMENT_SUCCESS,
	SEGMENTS_DELETE_SEGMENT,
	SEGMENTS_DELETE_SEGMENT_FAILURE,
	SEGMENTS_DELETE_SEGMENT_SUCCESS,
	SEGMENTS_GET_GENDERS,
	SEGMENTS_GET_GENDERS_FAILURE,
	SEGMENTS_GET_GENDERS_SUCCESS,
	SEGMENTS_GET_CLOTHES,
	SEGMENTS_GET_CLOTHES_FAILURE,
	SEGMENTS_GET_CLOTHES_SUCCESS,
	SEGMENTS_GET_SEGMENT,
	SEGMENTS_GET_SEGMENT_FAILURE,
	SEGMENTS_GET_SEGMENT_SUCCESS,
	SEGMENTS_GET_SEGMENTS,
	SEGMENTS_GET_SEGMENTS_FAILURE,
	SEGMENTS_GET_SEGMENTS_SUCCESS,
	SEGMENTS_UPDATE_SEGMENT,
	SEGMENTS_UPDATE_SEGMENT_FAILURE,
	SEGMENTS_UPDATE_SEGMENT_SUCCESS,
} from './segmentsTypes'

const initialState = {
	pending: false,
	list: [],
	genders: [],
	clothes: [],
	segment: null,
	newId: null,
	status: null,
}

const segmentsReducer = (state = initialState, action) => {
	switch (action.type) {
		case SEGMENTS_GET_SEGMENTS:
		case SEGMENTS_GET_SEGMENT:
		case SEGMENTS_CREATE_SEGMENT:
		case SEGMENTS_UPDATE_SEGMENT:
		case SEGMENTS_DELETE_SEGMENT:
		case SEGMENTS_GET_GENDERS:
		case SEGMENTS_GET_CLOTHES:
			return {
				...state,
				pending: true,
				status: null,
			}
		case SEGMENTS_GET_SEGMENTS_SUCCESS:
			return {
				pending: false,
				list: action.list,
				genders: state.genders,
			}
		case SEGMENTS_GET_SEGMENT_SUCCESS:
			return {
				pending: false,
				genders: state.genders,
				segment: action.segment,
			}
		case SEGMENTS_CREATE_SEGMENT_SUCCESS:
			return {
				pending: false,
				genders: state.genders,
				newId: action.newId,
				status: 'created',
			}
		case SEGMENTS_UPDATE_SEGMENT_SUCCESS:
			return {
				...state,
				pending: false,
				status: 'updated',
			}
		case SEGMENTS_DELETE_SEGMENT_SUCCESS:
			return {
				...state,
				pending: false,
				status: 'deleted',
			}
		case SEGMENTS_GET_GENDERS_SUCCESS:
			return {
				...state,
				pending: false,
				genders: action.genders,
			}
		case SEGMENTS_GET_CLOTHES_SUCCESS:
			return {
				...state,
				pending: false,
				clothes: action.clothes,
			}
		case SEGMENTS_GET_SEGMENTS_FAILURE:
		case SEGMENTS_GET_SEGMENT_FAILURE:
		case SEGMENTS_CREATE_SEGMENT_FAILURE:
		case SEGMENTS_UPDATE_SEGMENT_FAILURE:
		case SEGMENTS_DELETE_SEGMENT_FAILURE:
		case SEGMENTS_GET_GENDERS_FAILURE:
		case SEGMENTS_GET_CLOTHES_FAILURE:
			return {
				...state,
				pending: false,
			}
		case SEGMENTS_CLEAR_STATE:
			return {
				...initialState,
				genders: state.genders,
			}
		default:
			return state
	}
}

export default segmentsReducer
