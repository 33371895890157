import {
	VISUALS_CLEAR_STATE,
	VISUALS_CREATE_VISUAL,
	VISUALS_CREATE_VISUAL_FAILURE,
	VISUALS_CREATE_VISUAL_SUCCESS,
	VISUALS_DELETE_VISUAL,
	VISUALS_DELETE_VISUAL_FAILURE,
	VISUALS_DELETE_VISUAL_SUCCESS,
	VISUALS_GET_VISUAL,
	VISUALS_GET_VISUAL_FAILURE,
	VISUALS_GET_VISUAL_SUCCESS,
	VISUALS_GET_VISUALS,
	VISUALS_GET_VISUALS_FAILURE,
	VISUALS_GET_VISUALS_SUCCESS,
	VISUALS_UPDATE_VISUAL,
	VISUALS_UPDATE_VISUAL_FAILURE,
	VISUALS_UPDATE_VISUAL_SUCCESS,
	VISUALS_SET_SVG,
	VISUALS_GET_TEMPLATES,
	VISUALS_GET_TEMPLATES_FAILURE,
	VISUALS_GET_TEMPLATES_SUCCESS,
} from './visualsTypes'

const initialState = {
	pending: false,
	list: [],
	templates: [],
	visual: null,
	newId: null,
	status: null,
	svg: null,
}

const visualsReducer = (state = initialState, action) => {
	switch (action.type) {
		case VISUALS_GET_VISUALS:
		case VISUALS_GET_TEMPLATES:
		case VISUALS_GET_VISUAL:
		case VISUALS_CREATE_VISUAL:
		case VISUALS_UPDATE_VISUAL:
		case VISUALS_DELETE_VISUAL:
			return {
				...state,
				pending: true,
				status: null,
			}
		case VISUALS_GET_VISUALS_SUCCESS:
			return {
				pending: false,
				list: action.list,
			}
		case VISUALS_GET_TEMPLATES_SUCCESS:
			return {
				pending: false,
				templates: action.list,
			}
		case VISUALS_GET_VISUAL_SUCCESS:
			return {
				pending: false,
				visual: action.visual,
			}
		case VISUALS_CREATE_VISUAL_SUCCESS:
			return {
				pending: false,
				newId: action.newId,
				status: 'created',
			}
		case VISUALS_UPDATE_VISUAL_SUCCESS:
			return {
				...state,
				pending: false,
				status: 'updated',
			}
		case VISUALS_DELETE_VISUAL_SUCCESS:
			return {
				...state,
				pending: false,
				status: 'deleted',
			}
		case VISUALS_GET_VISUALS_FAILURE:
		case VISUALS_GET_TEMPLATES_FAILURE:
		case VISUALS_GET_VISUAL_FAILURE:
		case VISUALS_CREATE_VISUAL_FAILURE:
		case VISUALS_UPDATE_VISUAL_FAILURE:
		case VISUALS_DELETE_VISUAL_FAILURE:
			return {
				...state,
				pending: false,
			}
		case VISUALS_SET_SVG:
			return {
				pending: false,
				svg: action.svg,
			}
		case VISUALS_CLEAR_STATE:
			return initialState
		default:
			return state
	}
}

export default visualsReducer
