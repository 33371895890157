/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types'
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { ResponsivePie } from '@nivo/pie'
import { darkModeTheme, fillPatternDefs, fillPatterns } from '../../../utils/ChartUtils'
import { GCard, isEmpty } from '@alatimier/genesis-uic'
import { useSelector } from 'react-redux'

const PieCenteredMetric = ({ dataWithArc, centerX, centerY }) => {
	let total = 0
	dataWithArc.forEach((d) => {
		total += d.value
	})
	return (
		<text x={centerX} y={centerY} textAnchor="middle" dominantBaseline="central" style={{ fontSize: 'max(1.2vw, 30px)', fontWeight: 600 }}>
			{total}
		</text>
	)
}

const PieTitle =
	(title) =>
	({ centerX }) => (
		<text
			x={centerX}
			y={-24}
			textAnchor="middle"
			style={{
				fontSize: 'max(1vw, 20px)',
				fontWeight: 600,
			}}
		>
			{title}
		</text>
	)

const PieTooltip =
	(total) =>
	({ datum: { label, value, color } }) => (
		<div style={{ padding: 12, color, background: '#222222', borderRadius: '8px' }}>
			{_.capitalize(label)}: {value} ({Math.round((value / total) * 100)} %)
		</div>
	)

const SegmentedViewsChart = memo((props) => {
	const { t } = useTranslation()

	const isDarkMode = useSelector((store) => store.g_display.darkMode)

	const addPieData = (array, id, value) => {
		const dataObject = array.find((g) => g.id === id)
		if (dataObject) {
			dataObject.value += value
		} else {
			array.push({
				id,
				label: props.dataKey === 'genders' ? t(`gender.${id}.plural`) : id,
				value,
			})
		}
	}

	const computedData = { targeted: [], nonTargeted: [], all: [] }
	const totals = { targeted: 0, nonTargeted: 0, all: 0 }
	if (!isEmpty(props.data)) {
		for (const { visualData } of props.data) {
			if (visualData.global.views) {
				if (visualData.global.views.targeted && visualData.global.views.targeted[props.dataKey]) {
					for (const [id, value] of Object.entries(visualData.global.views.targeted[props.dataKey])) {
						if (value) {
							addPieData(computedData.targeted, id, value)
							addPieData(computedData.all, id, value)
							totals.targeted += value
							totals.all += value
						}
					}
				}
				if (visualData.global.views.nonTargeted && visualData.global.views.nonTargeted[props.dataKey]) {
					for (const [id, value] of Object.entries(visualData.global.views.nonTargeted[props.dataKey])) {
						if (value) {
							addPieData(computedData.nonTargeted, id, value)
							addPieData(computedData.all, id, value)
							totals.nonTargeted += value
							totals.all += value
						}
					}
				}
			}
		}
		const segmentIdSorter = (a, b) => (a.id > b.id ? 1 : -1)
		computedData.targeted.sort(segmentIdSorter)
		computedData.nonTargeted.sort(segmentIdSorter)
		computedData.all.sort(segmentIdSorter)
	}

	const pieProperties = {
		defs: fillPatternDefs,
		fill: fillPatterns,
		innerRadius: 0.4,
		padAngle: 2.0,
		cornerRadius: 3,
		borderWidth: 1,
		activeOuterRadiusOffset: 5,
		arcLabelsSkipAngle: 10,
		arcLinkLabelsDiagonalLength: 10,
		arcLinkLabelsStraightLength: 8,
		arcLinkLabelsSkipAngle: 10,
		arcLinkLabelsTextOffset: 2,
		arcLinkLabelsThickness: 2,
		arcLinkLabelsTextColor: { from: 'color', modifiers: [['darker', 1]] },
		arcLinkLabelsColor: { from: 'color', modifiers: [['darker', 1]] },
		theme: isDarkMode ? darkModeTheme : undefined,
		layers: ['arcs', 'arcLabels', 'arcLinkLabels', PieCenteredMetric],
	}

	return isEmpty(computedData.all) ? null : (
		<GCard row type="collapsable" title={props.title} className="overflow-hidden">
			<div
				className="row"
				style={{ minHeight: '270px', fill: isDarkMode ? 'rgba(255, 255, 255, 0.8)' : 'inherit' }}
				css={{ svg: { overflow: 'visible' } }}
				data-testid="SegmentedViews"
			>
				<div className="col-md-4 mb-3 mb-md-0 p-0">
					<ResponsivePie
						key={totals.targeted}
						data={computedData.targeted}
						{...pieProperties}
						layers={[...pieProperties.layers, PieTitle(t('dashboard.targeted'))]}
						tooltip={PieTooltip(totals.targeted)}
						{...props.pieProperties}
					/>
				</div>
				<div className="col-md-4 mb-3 mb-md-0 p-0">
					<ResponsivePie
						key={totals.nonTargeted}
						data={computedData.nonTargeted}
						{...pieProperties}
						layers={[...pieProperties.layers, PieTitle(t('dashboard.nonTargeted'))]}
						tooltip={PieTooltip(totals.nonTargeted)}
						{...props.pieProperties}
					/>
				</div>
				<div className="col-md-4 mb-3 mb-md-0 p-0">
					<ResponsivePie
						key={totals.all}
						data={computedData.all}
						{...pieProperties}
						layers={[...pieProperties.layers, PieTitle(t('dashboard.allTargets'))]}
						tooltip={PieTooltip(totals.all)}
						{...props.pieProperties}
					/>
				</div>
			</div>
		</GCard>
	)
})

SegmentedViewsChart.propTypes = {
	title: PropTypes.string.isRequired,
	data: PropTypes.array,
	dataKey: PropTypes.string.isRequired,
	pieProperties: PropTypes.object,
}

export default SegmentedViewsChart
