import last from 'lodash/last'
import { Duration, DateTime } from 'luxon'
import { objectiveColors } from '../../utils/ChartUtils'
import DateUtils from '../../utils/DateUtils'

export const objectiveGraphContext = { LIST: 'list', EDIT: 'edit' }

export const periodKeys = {
	samePeriodBeforeCampaign: 'samePeriodBeforeCampaign',
	lastMonthBeforeCampaign: 'lastMonthBeforeCampaign',
	lastYearBeforeCampaign: 'lastYearBeforeCampaign',
	samePeriodLastYear: 'samePeriodLastYear',
	custom: 'custom',
}

export const objectiveTypes = {
	IMPROVE_SALES: {
		value: 'IMPROVE_SALES',
		type: 'METRIC',
		metric: 'SALES',
		selectors: { campaign: 'required', visual: 'required', ean: 'required', baseline: 'required', target: 'required' },
	},
	IMPROVE_ATTENTION_TIME: {
		value: 'IMPROVE_ATTENTION_TIME',
		type: 'METRIC',
		metric: 'ATTENTION_TIME',
		selectors: { campaign: 'required', visual: 'optional', baselineOrCampaign: 'required', target: 'required' },
	},
	ROI_SALES: {
		value: 'ROI_SALES',
		type: 'ROI_SALES',
		selectors: { campaign: 'required', visual: 'required', ean: 'required', visualCost: 'required' },
	},
	ROI: {
		value: 'ROI',
		type: 'ROI',
		selectors: { solutionUseInfos: 'required', target: 'required' },
	},
	REACH: {
		value: 'REACH',
		type: 'REACH',
		selectors: { campaign: 'required', visual: 'optional', target: 'required' },
	},
}

export const getDefaultObjectiveName = (type, campaign, visual) => {
	return `${type.label}${type.selectors.campaign && campaign ? ` ${type.selectors.visual && visual ? visual.name : campaign.name}` : ''}`
}

export const isType = (objective, type) => {
	return objective.type === type.type && objective.metric === type.metric
}

export const getObjectiveType = (type, metric) => {
	return Object.values(objectiveTypes).find((t) => isType({ type, metric }, t))
}

export const getAverageAttentionTime = (data) => {
	let viewsTotal = 0
	const attentionTimexViews = data.reduce((average, { attentionTime, views }) => {
		if (views) {
			viewsTotal += views
			if (average) return average + views * attentionTime
			else return views * attentionTime
		} else {
			return average
		}
	}, undefined)
	return viewsTotal ? attentionTimexViews / viewsTotal : null
}

export const getObjectiveProgression = (data, { target, visualCost, hardwareCost, monthlySoftwareCost }) => {
	if (isType(data, objectiveTypes.IMPROVE_SALES)) {
		if (!target) return undefined
		const measuredCumulatedValues = Object.values(data.measuredCumulated)
		const referenceCumulatedValues = Object.values(data.referenceCumulated)
		if (!measuredCumulatedValues.length || !referenceCumulatedValues.length) return undefined
		const measuredCumulatedTurnover = last(measuredCumulatedValues).turnover
		const referenceCumulatedTurnover = last(referenceCumulatedValues).turnover
		return Math.max((100 * (measuredCumulatedTurnover - referenceCumulatedTurnover)) / target, 0)
	} else if (isType(data, objectiveTypes.IMPROVE_ATTENTION_TIME)) {
		if (!target) return undefined
		const measuredAverageAttentionTime = getAverageAttentionTime(data.measured)
		const referenceAverageAttentionTime = getAverageAttentionTime(data.reference)
		return measuredAverageAttentionTime && referenceAverageAttentionTime
			? Math.max((100 * (measuredAverageAttentionTime - referenceAverageAttentionTime)) / target, 0)
			: undefined
	} else if (isType(data, objectiveTypes.ROI)) {
		if (!target) return undefined
		const cumulatedDataValues = Object.entries(data.cumulatedData)
		if (!cumulatedDataValues.length || !hardwareCost || !monthlySoftwareCost) return undefined
		const monthNumber = Math.floor(DateTime.fromISO(last(cumulatedDataValues)[0]).diff(DateTime.fromISO(data.startDate), 'month').months)
		const totalBenefit = last(cumulatedDataValues)[1].benefit
		const totalBenefitTarget = target + hardwareCost + monthlySoftwareCost * monthNumber
		return 100 * (totalBenefit / totalBenefitTarget)
	} else if (isType(data, objectiveTypes.ROI_SALES)) {
		const cumulatedDataValues = Object.values(data.cumulatedData)
		if (!cumulatedDataValues.length || !visualCost) return undefined
		return 100 * (last(cumulatedDataValues).turnover / visualCost)
	} else if (isType(data, objectiveTypes.REACH)) {
		if (!target) return undefined
		return 100 * (data.views / target)
	}
}

export const getDateUnitAndFormat = (startDate, endDate, firstDate, lastDate) => {
	const timeRange = DateUtils.toInterval(startDate, endDate)

	let dateUnit
	if (timeRange.length('minute') < 200) dateUnit = 'minute'
	else if (timeRange.length('hour') < 100) dateUnit = 'hour'
	else if (timeRange.length('day') < 100) dateUnit = 'day'
	else if (timeRange.length('week') < 100) dateUnit = 'week'
	else dateUnit = 'month'

	let dateFormat
	if (dateUnit === 'month') dateFormat = { month: 'short', year: '2-digit' }
	else if (dateUnit === 'minute' || dateUnit === 'hour') dateFormat = DateTime.DATETIME_SHORT
	else {
		if (DateTime.fromISO(firstDate).year !== DateTime.fromISO(lastDate).year) dateFormat = DateTime.DATE_SHORT
		else dateFormat = { day: 'numeric', month: 'numeric' }
	}

	return { dateUnit, dateFormat }
}

export const completeDatesForComparison = (dates, referenceDates, dateUnit, campaignStartDate) => {
	const dateStep = Duration.fromObject({ [dateUnit]: 1 })

	// Add dates backwards from measured data's first date until campaign start date
	// to show if first dates didn't generate any revenue (and still compare those 0s to reference data)
	const startDate = DateTime.fromISO(campaignStartDate)
	let firstDate = DateTime.fromISO(dates[0])
	while (firstDate.diff(startDate).as(dateUnit) >= 1) {
		firstDate = firstDate.minus(dateStep)
		dates.unshift(firstDate.toUTC().toISO({ suppressMilliseconds: true }))
	}

	// Add dates to show reference data
	let lastDate = DateTime.fromISO(last(dates))
	while (dates.length < referenceDates.length) {
		lastDate = lastDate.plus(dateStep)
		dates.push(lastDate.toUTC().toISO({ suppressMilliseconds: true }))
	}
}

export const formatDate = (dateFormat) => (date) => DateTime.fromISO(date).toLocaleString(dateFormat)

export const getMarker = (value, legend, isDarkMode) => ({
	axis: 'y',
	value: value,
	legend: legend,
	legendPosition: 'bottom-left',
	lineStyle: { stroke: objectiveColors.target[isDarkMode ? 'darkMode' : 'lightMode'], strokeDasharray: '4' },
	textStyle: { fill: objectiveColors.target[isDarkMode ? 'darkMode' : 'lightMode'] },
})
