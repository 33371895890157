import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { GCard, GCrudFormActions, GIcon, GInput, GOrganizationSelect, GSelect, GTitleBar, isEmpty } from '@alatimier/genesis-uic'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import DateUtils from '../../utils/DateUtils'
import { clearState, createCampaign, deleteCampaigns, getCampaign, getStatuses, updateCampaign } from '../../store/campaigns/campaignsActions'
import { getWeathers } from '../../store/weathers/weathersActions'
import TagSelect from '../../components/tags/TagSelect'
import OrganizationDeviceSelect from '../../components/devices/select/OrganizationDeviceSelect'
import OrganizationSpacesSelect from '../../components/spaces/OrganizationSpacesSelect'
import OrganizationVisualsSelect from '../../components/visuals/OrganizationVisualsSelect'
import OrganizationSegmentSelect from '../../components/segments/OrganizationSegmentSelect'
import TimeSlotsEdit from '../../components/campaigns/timeSlots/TimeSlotsEdit'

const CampaignEdit = () => {
	const { t } = useTranslation()
	const params = useParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const weekDaysOptions = [
		{ value: 'MONDAY', label: t('weekDays.monday') },
		{ value: 'TUESDAY', label: t('weekDays.tuesday') },
		{ value: 'WEDNESDAY', label: t('weekDays.wednesday') },
		{ value: 'THURSDAY', label: t('weekDays.thursday') },
		{ value: 'FRIDAY', label: t('weekDays.friday') },
		{ value: 'SATURDAY', label: t('weekDays.saturday') },
		{ value: 'SUNDAY', label: t('weekDays.sunday') },
	]

	const [loading, setLoading] = useState(true)
	const [organizationId, setOrganizationId] = useState()
	const [targetOrganizationId, setTargetOrganizationId] = useState()
	const [name, setName] = useState()
	const [status, setStatus] = useState()
	const [startDate, setStartDate] = useState()
	const [endDate, setEndDate] = useState()
	const [weekDays, setWeekDays] = useState(weekDaysOptions)
	const [segmentId, setSegmentId] = useState()
	const [visualIds, setVisualIds] = useState([])
	const [spaceIds, setSpaceIds] = useState([])
	const [deviceIds, setDeviceIds] = useState([])
	const [tags, setTags] = useState([])
	const [comment, setComment] = useState()
	const [threshold, setThreshold] = useState()
	const [weatherSensitive, setWeatherSensitive] = useState(false)
	const [weathers, setWeathers] = useState([])
	const [minTemperature, setMinTemperature] = useState()
	const [maxTemperature, setMaxTemperature] = useState()
	const [dailyTimeSlots, setDailyTimeSlots] = useState([])

	const [campaignStatusOptions, setCampaignStatusOptions] = useState([])
	const [weatherOptions, setWeatherOptions] = useState([])
	const [close, setClose] = useState(false)

	const organizationsByPermission = useSelector((state) => state.g_organizations.listByPermission)

	const weathersEnum = useSelector((state) => state.weathers.list)
	const campaignStatusesEnum = useSelector((state) => state.campaigns.statuses)

	const campaign = useSelector((state) => state.campaigns.campaign)
	const pending = useSelector((state) => state.campaigns.pending)
	const workflowStatus = useSelector((state) => state.campaigns.status)
	const newCampaignId = useSelector((state) => state.campaigns.newId)

	useEffect(() => fetchWeathers(), [])
	useEffect(() => buildWeathersOptions(), [weathersEnum])

	useEffect(() => fetchCampaignStatuses(), [])
	useEffect(() => buildCampaignStatusesOptions(), [campaignStatusesEnum])

	useEffect(() => fetchCampaign(), [params.id])
	useEffect(() => prefillForm(), [campaign, campaignStatusOptions, weatherOptions])
	useEffect(() => handleWorkflowStatusChange(), [workflowStatus])

	const organizations = organizationsByPermission['CAMPAIGN_EDIT'] || []

	const getFlattenOrganizations = (organizations) => organizations.flatMap((o) => [o, ...getFlattenOrganizations(o.children || [])])

	const getOrganizationAttribute = (attributeName) =>
		getFlattenOrganizations(organizations)
			.filter((o) => o.id === organizationId)
			.filter((o) => o.attributes)
			.map((o) => o.attributes[attributeName])?.[0] || undefined

	const isAdvertiserCampaign = getOrganizationAttribute('organization-type') === 'ad-department'
	const advertiserCampaignCurrency = getOrganizationAttribute('organization-currency')
	const advertiserCampaignImageDisplayPrice = getOrganizationAttribute('image-display-price')
	const advertiserCampaignTargetedImageDisplayPrice = getOrganizationAttribute('image-targeted-display-price')
	const advertiserCampaignVideoDisplayPrice = getOrganizationAttribute('video-display-price')
	const advertiserCampaignTargetedVideoDisplayPrice = getOrganizationAttribute('video-targeted-display-price')

	const isTargeted = segmentId || weatherSensitive
	const imagePrice = isTargeted ? advertiserCampaignTargetedImageDisplayPrice : advertiserCampaignImageDisplayPrice
	const imagePriceText = imagePrice !== undefined ? `${t('campaigns.price.image')} : ${imagePrice} ${advertiserCampaignCurrency}. ` : ''
	const videoPrice = isTargeted ? advertiserCampaignTargetedVideoDisplayPrice : advertiserCampaignVideoDisplayPrice
	const videoPriceText = videoPrice !== undefined ? `${t('campaigns.price.video')} : ${videoPrice} ${advertiserCampaignCurrency}` : ''

	const isValid = () =>
		!isEmpty(organizationId) &&
		(isAdvertiserCampaign || !isEmpty(targetOrganizationId)) &&
		!isEmpty(name) &&
		!isEmpty(status) &&
		!isEmpty(startDate) &&
		!isEmpty(visualIds) &&
		(!isAdvertiserCampaign || !isEmpty(threshold)) &&
		!isEmpty(weekDays)

	const fetchWeathers = () => {
		dispatch(getWeathers())
	}

	const fetchCampaignStatuses = () => {
		dispatch(getStatuses())
	}

	const buildWeathersOptions = () => {
		if (weathersEnum && weathersEnum.length > 0) {
			setWeatherOptions(
				weathersEnum.map((w) => ({
					value: w,
					label: t(`weather.${w}`),
				}))
			)
		}
	}

	const buildCampaignStatusesOptions = () => {
		if (campaignStatusesEnum && campaignStatusesEnum.length > 0) {
			setCampaignStatusOptions(campaignStatusesEnum.map((s) => ({ value: s, label: t(`campaigns.status.${s}`) })))
		}
	}

	const fetchCampaign = () => {
		if (params.id && params.id !== 'new') {
			setLoading(true)
			dispatch(getCampaign(params.id))
		} else {
			setLoading(false)
		}
	}

	function prefillForm() {
		if (params.id !== 'new' && campaign && campaignStatusOptions && weatherOptions) {
			setOrganizationId(campaign.organizationId)
			setTargetOrganizationId(campaign.targetOrganizationId)
			setName(campaign.name)
			setStatus(campaignStatusOptions.filter((s) => s.value === campaign.status)[0])
			setStartDate(DateUtils.toLocaleDateTime(campaign.startDate))
			setEndDate(DateUtils.toLocaleDateTime(campaign.endDate))
			if (!isEmpty(campaign.weekDays)) setWeekDays(weekDaysOptions.filter((d) => campaign.weekDays && campaign.weekDays.some((cd) => d.value === cd)))
			setSegmentId(campaign.segmentId)
			setVisualIds(campaign.visualIds)
			setSpaceIds(campaign.spaceIds)
			setDeviceIds(campaign.deviceIds)
			setTags(campaign.tags)
			setComment(campaign.comment ?? '')
			setThreshold(campaign.advertiserCampaignThreshold)
			setWeatherSensitive(campaign.weatherSensitive)
			setWeathers(weatherOptions.filter((w) => campaign.weathers && campaign.weathers.some((cw) => w.value === cw)))
			setMinTemperature(campaign.weatherMinTemperature)
			setMaxTemperature(campaign.weatherMaxTemperature)
			setDailyTimeSlots(campaign.dailyTimeSlots ?? [])
			setLoading(false)
		}
	}

	const handleWorkflowStatusChange = () => {
		switch (workflowStatus) {
			case 'created':
				close ? doClose() : navigate(`/campaigns/${newCampaignId}`)
				dispatch(clearState())
				break
			case 'updated':
				close ? doClose() : dispatch(getCampaign(params.id))
				break
			case 'deleted':
				doClose()
				break
		}
	}

	const handleSave = () => {
		setClose(true)
		apply()
	}

	const handleDelete = () => {
		dispatch(deleteCampaigns([campaign.id]))
	}

	const doClose = () => {
		navigate('/campaigns')
	}

	const apply = () => {
		if (campaign) {
			dispatch(
				updateCampaign(
					campaign.id,
					organizationId,
					targetOrganizationId,
					name,
					status.value,
					DateUtils.toZonedDateTime(startDate),
					DateUtils.toZonedDateTime(endDate),
					weekDays.map((d) => d.value),
					segmentId,
					visualIds,
					spaceIds,
					deviceIds,
					tags,
					comment,
					isAdvertiserCampaign,
					threshold,
					weatherSensitive,
					weathers.map((w) => w.value),
					minTemperature,
					maxTemperature,
					dailyTimeSlots
				)
			)
		} else {
			dispatch(
				createCampaign(
					organizationId,
					targetOrganizationId,
					name,
					status.value,
					DateUtils.toZonedDateTime(startDate),
					DateUtils.toZonedDateTime(endDate),
					weekDays.map((d) => d.value),
					segmentId,
					visualIds,
					spaceIds,
					deviceIds,
					tags,
					comment,
					isAdvertiserCampaign,
					threshold,
					weatherSensitive,
					weathers.map((w) => w.value),
					minTemperature,
					maxTemperature,
					dailyTimeSlots
				)
			)
		}
	}

	const handleEndDateFocus = () => {
		if (startDate && !endDate) setEndDate(startDate)
	}

	const renderPending = () => (
		<GCard title={t('campaigns.properties.title')} row>
			<div className="text-center">
				<GIcon name="fa-spinner" spin />
			</div>
		</GCard>
	)

	const renderForm = () => (
		<>
			<GCard title={t('campaigns.properties.title')} row>
				<GOrganizationSelect id="organizationId" value={organizationId} onChange={setOrganizationId} maxLevel={1} permission="CAMPAIGN_EDIT" />
				{organizationId && !isAdvertiserCampaign && (
					<GOrganizationSelect
						id="targetOrganizationId"
						topOrganizationId={organizationId}
						label={t('forms.targetOrganization')}
						value={targetOrganizationId}
						onChange={setTargetOrganizationId}
						permission="CAMPAIGN_EDIT"
					/>
				)}
				<GInput id="name" label={t('campaigns.form.name')} value={name} onChange={setName} required />
				<GSelect id="status" label={t('campaigns.form.status')} options={campaignStatusOptions} value={status} onChange={setStatus} required />
				<GInput id="startDate" label={t('forms.date.start')} value={startDate} onChange={setStartDate} type="datetime-local" required />
				<GInput id="endDate" label={t('forms.date.end')} value={endDate} onChange={setEndDate} onFocus={handleEndDateFocus} type="datetime-local" />

				<OrganizationSegmentSelect id="segmentId" organizationId={organizationId} value={segmentId} onChange={setSegmentId} autoHide />
				<OrganizationVisualsSelect id="visualIds" organizationId={organizationId} value={visualIds} onChange={setVisualIds} multiple required />
				{!isAdvertiserCampaign && (
					<>
						<OrganizationSpacesSelect
							id="spaceIds"
							organizationId={targetOrganizationId}
							value={spaceIds}
							onChange={setSpaceIds}
							multiple
							autoHide
						/>
						<OrganizationDeviceSelect
							id="deviceIds"
							organizationId={targetOrganizationId}
							strictOrganization
							spaceIds={spaceIds}
							value={deviceIds}
							onChange={setDeviceIds}
							autoHide
						/>
					</>
				)}
				<TagSelect id="tags" value={tags} onChange={setTags} />

				<GInput id="comment" label={t('campaigns.form.comment')} value={comment} onChange={setComment} type="textarea" />

				{isAdvertiserCampaign && (
					<GInput
						id="threshold"
						suffix={advertiserCampaignCurrency}
						label={t('campaigns.form.threshold')}
						value={threshold}
						onChange={setThreshold}
						type="number"
						required
						help={imagePriceText + videoPriceText}
					/>
				)}
			</GCard>

			<GCard title={t('campaigns.weather.title')} row>
				<GInput
					id="weatherSensitive"
					label={t('campaigns.form.weatherSensitive')}
					type="checkbox"
					value={weatherSensitive}
					onChange={setWeatherSensitive}
				/>
				{weatherSensitive && (
					<>
						<GSelect id="weathers" label={t('campaigns.form.weathers')} options={weatherOptions} value={weathers} onChange={setWeathers} multiple />
						<GInput
							id="weatherMinTemperature"
							label={t('campaigns.form.temperature.min')}
							value={minTemperature}
							onChange={setMinTemperature}
							type="number"
						/>
						<GInput
							id="weatherMaxTemperature"
							label={t('campaigns.form.temperature.max')}
							value={maxTemperature}
							onChange={setMaxTemperature}
							type="number"
						/>
					</>
				)}
			</GCard>

			<GCard title={t('campaigns.timeSlots.title')} row>
				<GSelect
					id="weekDays"
					label={t('forms.weekDays')}
					required
					closeMenuOnSelect={false}
					multiple
					options={weekDaysOptions}
					value={weekDays}
					onChange={setWeekDays}
				/>
				<hr />
				<TimeSlotsEdit timeSlots={dailyTimeSlots} onChange={setDailyTimeSlots} />
			</GCard>
		</>
	)

	return (
		<>
			<GTitleBar>{t('campaigns.title')}</GTitleBar>
			{loading ? renderPending() : renderForm()}
			<GCrudFormActions
				canSave={isValid()}
				onApply={apply}
				onSave={handleSave}
				canCancel
				onCancel={doClose}
				canDelete={!!campaign}
				onDelete={handleDelete}
				busy={pending}
				location={['page', 'page-top']}
			/>
		</>
	)
}

export default CampaignEdit
