import { useContext, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { GButton, GInput, GSelect } from '@alatimier/genesis-uic'
import { editorContext, SET_TEXT_PROPERTIES, TAB_TEXT } from '../../editorContext'
import { cancelUndoStacking } from '../../utils/cancelUndoStacking'
import { setMode } from '../../utils/actions'
import { fontFamilies, textPresets } from '../../utils/constants'

const TextPanel = () => {
	const { t } = useTranslation()
	const isDarkMode = useSelector((state) => state.g_display.darkMode)
	const [editorState, dispatchEditorState] = useContext(editorContext)

	const fontFamilyOptions = fontFamilies.sort().map((font) => ({ value: font, label: font }))
	const presets = textPresets.map((preset) => ({ ...preset, name: t(`editor.text.preset.${preset.id}`) }))

	const getElementFontFamily = () => editorState.selectedElement.getAttribute('font-family')
	const getCanvasFontFamily = () => editorState.canvas.getFontFamily()
	const getElementFontSize = () => editorState.selectedElement.getAttribute('font-size')
	const getCanvasFontSize = () => editorState.canvas.getFontSize()
	const getCanvasBold = () => editorState.canvas.getBold()
	const getCanvasItalic = () => editorState.canvas.getItalic()
	const getElementUnderline = () => editorState.selectedElement.getAttribute('text-decoration')?.includes('underline')
	const getElementStrike = () => editorState.selectedElement.getAttribute('text-decoration')?.includes('line-through')

	const getCanvasTextProperties = () => ({
		fontFamily: getElementFontFamily() ?? getCanvasFontFamily(),
		fontSize: getElementFontSize() ?? getCanvasFontSize(),
		bold: getCanvasBold(),
		italic: getCanvasItalic(),
		underline: getElementUnderline() ?? false,
		strike: getElementStrike() ?? false,
	})

	const textIsNew = useRef(false)

	const computeTextDecoration = (underline, strike) => {
		const properties = []
		if (underline) properties.push('underline')
		if (strike) properties.push('line-through')
		return properties.join(' ')
	}

	// Update new text with pre-chosen properties
	// and update buttons when (un)selecting the text
	useEffect(() => {
		if (textIsNew.current) {
			if (editorState.mode === 'textedit') {
				// Updating new text according to pre-selected properties
				editorState.canvas.setBold(editorState.text.bold)
				editorState.canvas.setItalic(editorState.text.italic)
				editorState.selectedElement.setAttribute('text-decoration', computeTextDecoration(editorState.text.underline, editorState.text.strike))
				// Those changes shouldn't be undoable : cancelling setBold and setItalic
				cancelUndoStacking(editorState.canvas)
				cancelUndoStacking(editorState.canvas)
				textIsNew.current = false
			} else if (editorState.mode !== 'text') {
				// Text creation was cancelled
				textIsNew.current = false
			}
		} else if (editorState.selectedElement && editorState.selectedElement.tagName === 'text') {
			dispatchEditorState({ type: SET_TEXT_PROPERTIES, textProperties: getCanvasTextProperties() })
		}
	}, [editorState.selectedElement, editorState.mode])

	const addText = () => {
		textIsNew.current = true
		setMode(editorState, dispatchEditorState, 'text')
	}

	const setFontFamily = (fontFamilyOption) => {
		editorState.canvas.setFontFamily(fontFamilyOption.value)
		editorState.canvas.call('changed')
		dispatchEditorState({ type: SET_TEXT_PROPERTIES, textProperties: { fontFamily: fontFamilyOption.value } })
	}

	const setFontSize = (size) => {
		editorState.canvas.setFontSize(size)
		editorState.canvas.call('changed')
		dispatchEditorState({ type: SET_TEXT_PROPERTIES, textProperties: { fontSize: size } })
	}

	const setBold = (value) => {
		try {
			editorState.canvas.setBold(value)
		} catch (e) {
			// Catching code error in svgedit in case of no selected element
		}
		editorState.canvas.call('changed')
		dispatchEditorState({ type: SET_TEXT_PROPERTIES, textProperties: { bold: value } })
	}
	const toggleBold = () => setBold(!editorState.text.bold)

	const setItalic = (value) => {
		try {
			editorState.canvas.setItalic(value)
		} catch (e) {
			// Catching code error in svgedit in case of no selected element
		}
		editorState.canvas.call('changed')
		dispatchEditorState({ type: SET_TEXT_PROPERTIES, textProperties: { italic: value } })
	}
	const toggleItalic = () => setItalic(!editorState.text.italic)

	const setDecoration = (underline, strike) => {
		editorState.selectedElement?.setAttribute('text-decoration', computeTextDecoration(underline, strike))
		editorState.canvas.call('changed')
		dispatchEditorState({ type: SET_TEXT_PROPERTIES, textProperties: { underline, strike } })
	}
	const toggleUnderline = () => setDecoration(!editorState.text.underline, editorState.text.strike)
	const toggleStrike = () => setDecoration(editorState.text.underline, !editorState.text.strike)

	const usePreset = (preset) => () => {
		setFontSize(preset.size)
		setBold(preset.bold ?? false)
		setItalic(preset.italic ?? false)
		setDecoration(preset.underline ?? false, preset.strike ?? false)
		const elem = editorState.selectedElement
		if (!(elem && elem.tagName === 'text')) addText()
	}

	const modeIsText = editorState.mode === 'text' || editorState.mode === 'textedit'
	const buttonColor = isDarkMode ? 'dark' : 'light'

	return (
		<>
			<h1 className="h5 ms-2 mb-4">{t(`editor.tab.${TAB_TEXT}`)}</h1>
			<div className="textPanel">
				<GButton
					icon="fa-font"
					color={buttonColor}
					className="text-info mb-3"
					active={modeIsText}
					onClick={addText}
					tooltip={t('editor.action.text.new')}
					tooltipPosition="right"
				/>
				<div className="d-lg-flex align-items-center mb-3">
					<GSelect
						id="fontFamily"
						options={fontFamilyOptions}
						value={fontFamilyOptions.find((o) => o.value === editorState.text.fontFamily)}
						onChange={setFontFamily}
						simple
						selectClassName="me-0 me-lg-3 mb-3 mb-lg-0"
						maxMenuHeight={250}
					/>
					<GInput
						id="fontSize"
						type="number"
						min={10}
						max={200}
						value={editorState.text.fontSize}
						onChange={setFontSize}
						simple
						inputClassName="d-inline-block me-1"
					/>
					px
				</div>
				<div className="d-lg-flex align-items-center mb-3">
					<GButton
						icon="fa-bold"
						color={buttonColor}
						className="text-info me-2"
						active={editorState.text.bold}
						onClick={toggleBold}
						title={t('editor.action.bold')}
					/>
					<GButton
						icon="fa-italic"
						color={buttonColor}
						className="text-info me-2"
						active={editorState.text.italic}
						onClick={toggleItalic}
						title={t('editor.action.italic')}
					/>
					<GButton
						icon="fa-underline"
						color={buttonColor}
						className="text-info me-2"
						active={editorState.text.underline}
						onClick={toggleUnderline}
						title={t('editor.action.underline')}
					/>
					<GButton
						icon="fa-strikethrough"
						color={buttonColor}
						className="text-info"
						active={editorState.text.strike}
						onClick={toggleStrike}
						title={t('editor.action.strike')}
					/>
				</div>
				{presets.map((preset) => (
					<button
						key={preset.name}
						className={`mt-3 w-100 btn btn-${buttonColor} text-start`}
						style={{
							fontFamily: editorState.text.fontFamily,
							fontSize: preset.size / 4,
							fontWeight: preset.bold ? 'bold' : 'normal',
							textDecoration: computeTextDecoration(preset.underline, preset.strike),
						}}
						onClick={usePreset(preset)}
					>
						{preset.name}
					</button>
				))}
			</div>
		</>
	)
}

export default TextPanel
