import { WEATHERS_GET_WEATHERS, WEATHERS_GET_WEATHERS_FAILURE, WEATHERS_GET_WEATHERS_SUCCESS } from './weathersTypes'

const initialState = {
	pending: false,
	list: [],
}

const weathersReducer = (state = initialState, action) => {
	switch (action.type) {
		case WEATHERS_GET_WEATHERS:
			return {
				...state,
				pending: true,
			}
		case WEATHERS_GET_WEATHERS_SUCCESS:
			return {
				pending: false,
				list: action.list,
			}
		case WEATHERS_GET_WEATHERS_FAILURE:
			return {
				...state,
				pending: false,
			}
		default:
			return state
	}
}

export default weathersReducer
