import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { GButton, GCard, GInput, GOrganizationSelect, GSecured, GTitleBar, isEmpty } from '@alatimier/genesis-uic'
import { exportSales } from '../../store/sales/salesActions'

const Sales = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const pending = useSelector((state) => state.sales.pending)

	const [organizationId, setOrganizationId] = useState()
	const [file, setFile] = useState(null)

	const handleFileUpload = () => {
		dispatch(exportSales(organizationId, file))
	}

	const getHelp = () => {
		return <span dangerouslySetInnerHTML={{ __html: t('sales.form.file.help', { target: 'sales_example.csv' }) }} />
	}

	return (
		<GSecured permission="SALES_EDIT">
			<GTitleBar>{t('sales.title')}</GTitleBar>
			<GCard title={t('sales.title')} row>
				<GOrganizationSelect id="organizationId" value={organizationId} onChange={setOrganizationId} maxLevel={1} permission="SALES_EDIT" />
				<GInput id="file" type="file" fileAccept=".csv" label={t('sales.form.file')} help={getHelp()} onChange={setFile} required />
			</GCard>
			<GButton
				label={t('action.upload')}
				onClick={handleFileUpload}
				location={'page'}
				busy={pending}
				disabled={isEmpty(file) || isEmpty(organizationId)}
			/>
		</GSecured>
	)
}

export default Sales
