import { apiClientService as client, setErrors, setSuccess } from '@alatimier/genesis-uic'
import {
	CAMPAIGNS_CLEAR_STATE,
	CAMPAIGNS_CREATE_CAMPAIGN,
	CAMPAIGNS_CREATE_CAMPAIGN_FAILURE,
	CAMPAIGNS_CREATE_CAMPAIGN_SUCCESS,
	CAMPAIGNS_DELETE_CAMPAIGN,
	CAMPAIGNS_DELETE_CAMPAIGN_FAILURE,
	CAMPAIGNS_DELETE_CAMPAIGN_SUCCESS,
	CAMPAIGNS_GET_CAMPAIGN,
	CAMPAIGNS_GET_CAMPAIGN_FAILURE,
	CAMPAIGNS_GET_CAMPAIGN_SUCCESS,
	CAMPAIGNS_GET_CAMPAIGNS,
	CAMPAIGNS_GET_CAMPAIGNS_FAILURE,
	CAMPAIGNS_GET_CAMPAIGNS_SUCCESS,
	CAMPAIGNS_GET_STATUSES,
	CAMPAIGNS_GET_STATUSES_FAILURE,
	CAMPAIGNS_GET_STATUSES_SUCCESS,
	CAMPAIGNS_UPDATE_CAMPAIGN,
	CAMPAIGNS_UPDATE_CAMPAIGN_FAILURE,
	CAMPAIGNS_UPDATE_CAMPAIGN_STATUS,
	CAMPAIGNS_UPDATE_CAMPAIGN_STATUS_FAILURE,
	CAMPAIGNS_UPDATE_CAMPAIGN_STATUS_SUCCESS,
	CAMPAIGNS_UPDATE_CAMPAIGN_SUCCESS,
} from './campaignsTypes'

const campaignsEndPoint = '/api/admin/v1/campaigns'

export const getCampaigns = () => {
	return (dispatch) => {
		dispatch({ type: CAMPAIGNS_GET_CAMPAIGNS })
		return client.get(campaignsEndPoint).then(async (res) => {
			if (res.ok) {
				const data = await res.json()
				dispatch({
					type: CAMPAIGNS_GET_CAMPAIGNS_SUCCESS,
					list: data,
				})
			} else {
				dispatch({
					type: CAMPAIGNS_GET_CAMPAIGNS_FAILURE,
				})
			}
		})
	}
}

export const getCampaign = (id) => {
	return (dispatch) => {
		dispatch({ type: CAMPAIGNS_GET_CAMPAIGN })
		return client.get(`${campaignsEndPoint}/${id}`).then(async (res) => {
			if (res.ok) {
				const data = await res.json()
				dispatch({
					type: CAMPAIGNS_GET_CAMPAIGN_SUCCESS,
					campaign: data,
				})
			} else {
				dispatch({
					type: CAMPAIGNS_GET_CAMPAIGN_FAILURE,
				})
			}
		})
	}
}

export const createCampaign = (
	organizationId,
	targetOrganizationId,
	name,
	status,
	startDate,
	endDate,
	weekDays,
	segmentId,
	visualIds,
	spaceIds,
	deviceIds,
	tags,
	comment,
	advertiserCampaign,
	advertiserCampaignThreshold,
	weatherSensitive,
	weathers,
	weatherMinTemperature,
	weatherMaxTemperature,
	dailyTimeSlots
) => {
	return (dispatch) => {
		dispatch({ type: CAMPAIGNS_CREATE_CAMPAIGN })
		return client
			.post(campaignsEndPoint, {
				organizationId,
				targetOrganizationId,
				name,
				segmentId,
				status,
				startDate,
				endDate,
				weekDays,
				tags,
				comment,
				advertiserCampaign,
				advertiserCampaignThreshold,
				weatherSensitive,
				weathers,
				weatherMinTemperature,
				weatherMaxTemperature,
				deviceIds,
				visualIds,
				spaceIds,
				dailyTimeSlots,
			})
			.then(async (res) => {
				const data = await res.json()
				if (res.ok) {
					dispatch(setSuccess('action.save.success'))
					dispatch({
						type: CAMPAIGNS_CREATE_CAMPAIGN_SUCCESS,
						newId: data.id,
					})
				} else {
					dispatch(setErrors(data.errors, data.validationErrors))
					dispatch({
						type: CAMPAIGNS_CREATE_CAMPAIGN_FAILURE,
					})
				}
			})
	}
}

export const updateCampaign = (
	id,
	organizationId,
	targetOrganizationId,
	name,
	status,
	startDate,
	endDate,
	weekDays,
	segmentId,
	visualIds,
	spaceIds,
	deviceIds,
	tags,
	comment,
	advertiserCampaign,
	advertiserCampaignThreshold,
	weatherSensitive,
	weathers,
	weatherMinTemperature,
	weatherMaxTemperature,
	dailyTimeSlots
) => {
	return (dispatch) => {
		dispatch({ type: CAMPAIGNS_UPDATE_CAMPAIGN })
		return client
			.put(`${campaignsEndPoint}/${id}`, {
				organizationId,
				targetOrganizationId,
				name,
				segmentId,
				status,
				startDate,
				endDate,
				weekDays,
				tags,
				comment,
				advertiserCampaign,
				advertiserCampaignThreshold,
				weatherSensitive,
				weathers,
				weatherMinTemperature,
				weatherMaxTemperature,
				deviceIds,
				visualIds,
				spaceIds,
				dailyTimeSlots,
			})
			.then(async (res) => {
				if (res.ok) {
					dispatch(setSuccess('action.save.success'))
					dispatch({
						type: CAMPAIGNS_UPDATE_CAMPAIGN_SUCCESS,
					})
				} else {
					const data = await res.json()
					dispatch(setErrors(data.errors, data.validationErrors))
					dispatch({
						type: CAMPAIGNS_UPDATE_CAMPAIGN_FAILURE,
					})
				}
			})
	}
}

export const updateCampaignStatus = (id, status) => {
	return (dispatch) => {
		dispatch({ type: CAMPAIGNS_UPDATE_CAMPAIGN_STATUS })
		return client.patch(`${campaignsEndPoint}/${id}`, { status }).then(async (res) => {
			if (res.ok) {
				dispatch(setSuccess('campaigns.action.updateStatus.success'))
				dispatch({
					type: CAMPAIGNS_UPDATE_CAMPAIGN_STATUS_SUCCESS,
				})
				dispatch(getCampaigns())
			} else {
				const data = await res.json()
				dispatch(setErrors(data.errors, data.validationErrors))
				dispatch({
					type: CAMPAIGNS_UPDATE_CAMPAIGN_STATUS_FAILURE,
				})
			}
		})
	}
}

export const deleteCampaigns = (ids) => {
	return (dispatch) => {
		dispatch({ type: CAMPAIGNS_DELETE_CAMPAIGN })
		const promises = ids.map((id) => client.del(`${campaignsEndPoint}/${id}`))
		return Promise.all(promises).then(async (results) => {
			if (results.every((r) => r.ok)) {
				dispatch(setSuccess('action.delete.success'))
			} else {
				const data = await results.find((r) => !r.ok).json()
				dispatch(setErrors(data.errors, data.validationErrors))
				dispatch({ type: CAMPAIGNS_DELETE_CAMPAIGN_FAILURE })
			}
			if (results.some((r) => r.ok)) dispatch({ type: CAMPAIGNS_DELETE_CAMPAIGN_SUCCESS })
		})
	}
}

export const getStatuses = () => {
	return (dispatch) => {
		dispatch({ type: CAMPAIGNS_GET_STATUSES })
		return client.get(`${campaignsEndPoint}/statuses`).then(async (res) => {
			if (res.ok) {
				const data = await res.json()
				dispatch({
					type: CAMPAIGNS_GET_STATUSES_SUCCESS,
					statuses: data,
				})
			} else {
				dispatch({
					type: CAMPAIGNS_GET_STATUSES_FAILURE,
				})
			}
		})
	}
}

export const clearState = () => {
	return {
		type: CAMPAIGNS_CLEAR_STATE,
	}
}
