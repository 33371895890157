import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { GBadge, GButton, GCard, GIcon, GInput, GMessage, GOrganizationSelect, GSecured, GTable, GTitleBar, isEmpty } from '@alatimier/genesis-uic'
import { deleteCampaigns, getCampaigns, updateCampaignStatus } from '../../store/campaigns/campaignsActions'

const CampaignList = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const organizations = useSelector((state) => state.g_organizations.list)
	const campaigns = useSelector((state) => state.campaigns.list)
	const pending = useSelector((state) => state.campaigns.pending)
	const status = useSelector((state) => state.campaigns.status)

	useEffect(() => fetchCampaigns(), [])
	useEffect(() => handleStatusChange(), [status])

	const fetchCampaigns = () => {
		dispatch(getCampaigns())
	}

	const handleStatusChange = () => {
		if (status === 'deleted') {
			dispatch(getCampaigns())
		}
	}

	const handleEdit = (id) => {
		navigate(`/campaigns/${id}`)
	}

	const handleDelete = (ids) => {
		dispatch(deleteCampaigns(ids))
	}

	const handleUpdateCampaignStatus = (value, id) => {
		dispatch(updateCampaignStatus(id, value ? 'ACTIVE' : 'INACTIVE'))
	}

	const columns = [
		{ itemProp: 'name', label: t('campaigns.table.name'), width: '15%' },
		{ itemProp: 'targetOrganization', label: t('campaigns.table.targetOrganization'), width: '15%' },
		{ itemProp: 'startDateFormatted', label: t('campaigns.table.startDate'), width: '15%' },
		{ itemProp: 'endDateFormatted', label: t('campaigns.table.endDate'), width: '15%' },
		{ itemProp: 'tags', label: t('tags.table.label'), width: '15%' },
	]

	const now = new Date()
	const rowActions = [
		{
			title: t('action.campaign.activation'),
			customElementSupplier: (index, item) => {
				const isInProgress = new Date(item.startDate) <= now && (!item.endDate || new Date(item.endDate) > now)
				return (
					<GInput
						key={`campaign_activation_${index}`}
						id={`campaign_activation_${index}`}
						title={t('campaigns.action.updateStatus')}
						type="checkbox"
						simple
						value={isInProgress && item.status === 'ACTIVE'}
						disabled={!isInProgress}
						inputClassName="align-middle m-1"
						onChange={(value) => handleUpdateCampaignStatus(value, item.id)}
					/>
				)
			},
			permission: 'CAMPAIGN_EDIT',
		},
		{
			title: t('action.edit'),
			icon: 'fa-edit',
			fn: (item) => handleEdit(item.id),
			permission: 'CAMPAIGN_EDIT',
		},
		{
			title: t('action.delete'),
			icon: 'fa-trash',
			fn: (item) => handleDelete([item.id]),
			confirmMessage: t('action.delete.confirm'),
			permission: 'CAMPAIGN_EDIT',
		},
	]

	const globalActions = [
		{
			icon: 'fa-trash',
			title: t('action.delete'),
			fn: (selected) => handleDelete(selected.map((item) => item.id)),
			confirmMessage: t('action.delete.confirm'),
			permission: 'CAMPAIGN_EDIT',
		},
	]

	const getFlattenOrganizations = (organizations) => organizations.flatMap((o) => [o, ...getFlattenOrganizations(o.children || [])])

	// Mapper to human-readable values which will also be used for filtering
	const campaignRawValueMapper = (campaign) => ({
		...campaign,
		targetOrganization: getFlattenOrganizations(organizations).find((o) => o.id === campaign.targetOrganizationId)?.name || t('not_applicable'),
		startDateFormatted: DateTime.fromISO(campaign.startDate).toFormat(t('format.date')),
		endDateFormatted: campaign.endDate ? DateTime.fromISO(campaign.endDate).toFormat(t('format.date')) : '',
	})

	// Enriched campaign attributes for display purpose, original value will still be used for filtering
	const richCellContent = {
		tags: (item) => (
			<td>
				{item.tags.map((tag, index) => (
					<GBadge key={index} label={tag} className="me-1" />
				))}
			</td>
		),
	}

	const renderPending = () => (
		<div className="text-center">
			<GIcon name="fa-spinner" spin />
		</div>
	)

	const renderCampaigns = () => {
		if (isEmpty(campaigns)) {
			return <GMessage value={t('campaigns.list.empty')} />
		}
		return (
			<GTable
				id="campaignList"
				items={campaigns.map((c) => campaignRawValueMapper(c))}
				columns={columns}
				hover
				columnSorter
				columnFilter
				tableFilter
				sortOn={{ column: 'name', state: 'asc' }}
				rowActions={rowActions}
				globalActions={globalActions}
				pagination
				itemsPerPage={15}
				richCellContent={richCellContent}
			/>
		)
	}

	return (
		<>
			<GOrganizationSelect id="organizations" maxLevel={-1} />
			<GTitleBar>{t('campaigns.title')}</GTitleBar>
			<GCard title={t('campaigns.list.title')} row>
				{pending && isEmpty(campaigns) ? renderPending() : renderCampaigns()}
				<GSecured permission="CAMPAIGN_EDIT">
					<Link to="/campaigns/new">
						<GButton label={t('campaigns.action.create')} location={['page', 'page-top']} />
					</Link>
				</GSecured>
			</GCard>
		</>
	)
}

export default CampaignList
