import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { editorContext } from '../../../editorContext'
import { copySelected, cutSelected, deleteSelected, pasteOnClick } from '../../../utils/actions'

const ContextMenu = (props) => {
	const { t } = useTranslation()
	const [editorState, dispatchEditorState] = useContext(editorContext)

	const clickCopy = () => copySelected(editorState)
	const clickCut = () => cutSelected(editorState)
	const clickPaste = () => pasteOnClick(editorState)
	const clickDelete = () => deleteSelected(editorState, dispatchEditorState)
	const clickGroup = () => editorState.canvas.groupSelectedElements()
	const clickUngroup = () => editorState.canvas.ungroupSelectedElement()
	const clickMoveFront = () => editorState.canvas.moveToTopSelectedElement()
	const clickMoveBack = () => editorState.canvas.moveToBottomSelectedElement()

	const elementsAreSelected = editorState.selectedElement || editorState.multiSelected
	const canUngroup = editorState.selectedElement?.tagName === 'g'
	const svgeditClipboard = sessionStorage.getItem('svgedit_clipboard')

	return (
		<div className="contextMenu" style={{ top: props.top, left: props.left }}>
			<button onClick={clickCopy} disabled={!elementsAreSelected}>
				<span>{t('editor.action.copy')}</span>Ctrl+C
			</button>
			<button onClick={clickCut} disabled={!elementsAreSelected}>
				<span>{t('editor.action.cut')}</span>Ctrl+X
			</button>
			<button onClick={clickPaste} disabled={!svgeditClipboard}>
				<span>{t('editor.action.paste')}</span>Ctrl+V
			</button>
			<hr className="m-1" />
			<button onClick={clickDelete} disabled={!elementsAreSelected}>
				<span>{t('editor.action.delete')}</span>
				{t('editor.shortcut.delete')}
			</button>
			<hr className="m-1" />
			<button onClick={clickGroup} disabled={!editorState.multiSelected}>
				<span>{t('editor.action.group')}</span>G
			</button>
			<button onClick={clickUngroup} disabled={!canUngroup}>
				<span>{t('editor.action.ungroup')}</span>G
			</button>
			<hr className="m-1" />
			<button onClick={clickMoveFront} disabled={!elementsAreSelected}>
				<span>{t('editor.action.move.front')}</span>
			</button>
			<button onClick={clickMoveBack} disabled={!elementsAreSelected}>
				<span>{t('editor.action.move.back')}</span>
			</button>
		</div>
	)
}

ContextMenu.propTypes = {
	top: PropTypes.number,
	left: PropTypes.number,
}

export default ContextMenu
