import { BatchCommand, InsertElementCommand } from 'svgedit/src/svgcanvas/history'
import { SAVE_IMAGE } from '../editorContext'
import { centerSelectedElements } from './actions'

const addSvgImageToCanvas = (canvas, svgString) => {
	const newElement = canvas.importSvgString(svgString)
	canvas.selectOnly([newElement])
	newElement.setAttribute('transform', 'scale(1)') // keep real dimensions of svg image
	centerSelectedElements(canvas)
}

const addNonSvgImageToCanvas = (canvas, data) => {
	const img = new Image()
	let imgWidth, imgHeight
	img.style.opacity = 0
	img.addEventListener('load', () => {
		imgWidth = img.offsetWidth || img.naturalWidth || img.width
		imgHeight = img.offsetHeight || img.naturalHeight || img.height
		const newImage = canvas.addSVGElementsFromJson({
			element: 'image',
			attr: {
				x: 0,
				y: 0,
				width: imgWidth,
				height: imgHeight,
				id: canvas.getNextId(),
				style: 'pointer-events:inherit',
			},
		})
		canvas.setHref(newImage, data)
		canvas.selectOnly([newImage])
		centerSelectedElements(canvas)

		const batchCmd = new BatchCommand('Import Image')
		batchCmd.addSubCommand(new InsertElementCommand(newImage))
		canvas.undoMgr.addCommandToHistory(batchCmd)
	})
	img.src = data
}

export const useImage = (canvas, data, isSvg) => {
	if (isSvg) {
		addSvgImageToCanvas(canvas, data)
	} else {
		addNonSvgImageToCanvas(canvas, data)
	}
}

export const sanitizeSvg = (data) => {
	// Remove xml tag to only keep svg
	const regex = RegExp('<\\?xml.*\\?>')
	const xmlTag = regex.exec(data)
	if (xmlTag) {
		data = data.slice(xmlTag[0].length)
	}
	// fix viewbox typo (output of some svg websites)
	data = data.replace('viewbox', 'viewBox')

	return data
}

export const importImage = (file, dispatchEditorState, canvas) => {
	if (!file?.type.includes('image')) return
	const fileName = file.name.replace(/\.[^/.]+$/, '')
	const reader = new FileReader()
	if (file.type.includes('svg')) {
		reader.onloadend = ({ target: { result } }) => {
			let data = sanitizeSvg(result)
			useImage(canvas, data, true)
			dispatchEditorState({ type: SAVE_IMAGE, data: data, isSvg: true, name: fileName })
		}
		reader.readAsText(file)
	} else {
		reader.onloadend = ({ target: { result } }) => {
			useImage(canvas, result, false)
			dispatchEditorState({ type: SAVE_IMAGE, data: result, isSvg: false, name: fileName })
		}
		reader.readAsDataURL(file)
	}
}
