import { useContext, useRef } from 'react'
import { GButton, GIcon } from '@alatimier/genesis-uic'
import { DELETE_IMAGE, editorContext, TAB_IMAGES } from '../../editorContext'
import { useTranslation } from 'react-i18next'
import FigureList from '../FigureList/FigureList'
import { importImage, useImage } from '../../utils/image'

const ImagesPanel = () => {
	const { t } = useTranslation()

	const [editorState, dispatchEditorState] = useContext(editorContext)

	const fileInputRef = useRef(null)

	const handleUploadClick = () => {
		fileInputRef.current.click()
	}

	const importFile = (e) => {
		e.preventDefault()
		e.currentTarget.classList.remove('draggedOver')
		const file = e.type === 'drop' ? e.dataTransfer.files[0] : e.currentTarget.files[0]
		importImage(file, dispatchEditorState, editorState.canvas)
		// Be able to select same file again (in case it is deleted)
		fileInputRef.current.value = null
	}

	const handleDragOver = (e) => {
		e.preventDefault()
		e.stopPropagation()
		e.currentTarget.classList.add('draggedOver')
	}
	const handleDragLeave = (e) => {
		e.currentTarget.classList.remove('draggedOver')
	}

	const useFigure = (figure) => useImage(editorState.canvas, figure.data, figure.isSvg)

	const menuActions = [
		{
			label: t('editor.action.use'),
			icon: 'fa-file-import',
			fn: (figure) => () => useFigure(figure),
		},
		{
			label: t('editor.action.delete'),
			icon: 'fa-trash',
			fn: () => () => dispatchEditorState({ type: DELETE_IMAGE }),
		},
	]

	return (
		<>
			<h1 className="h5 ms-2 mb-4">{t(`editor.tab.${TAB_IMAGES}`)}</h1>
			<div className="upload p-4 mb-3" onDrop={importFile} onDragOver={handleDragOver} onDragLeave={handleDragLeave} role="input">
				<p>{t('editor.legend.upload')}</p>
				<GButton
					color="success"
					label={
						<>
							{t('editor.action.upload').toUpperCase()} <GIcon name="fa-upload" className="ms-3" />
						</>
					}
					onClick={handleUploadClick}
					className="px-3"
				/>
				<input ref={fileInputRef} type="file" className="d-none" onChange={importFile} />
			</div>
			<FigureList figureKey="images" onClick={useFigure} menuActions={menuActions} />
		</>
	)
}

export default ImagesPanel
