import { isEmpty } from '@alatimier/genesis-uic'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import TimeSlotForm from './TimeSlotForm'
import { timeSlotShape } from './TimeSlotTypes'

const TimeSlotsEdit = (props) => {
	const { t } = useTranslation()

	const handleAddTimeSlot = (newTimeSlot) => {
		props.onChange([newTimeSlot, ...props.timeSlots])
	}

	const handleChangeTimeSlot = (changedTimeSlot, changedIndex) => {
		props.onChange(props.timeSlots.map((timeSlot, index) => (index === changedIndex ? changedTimeSlot : timeSlot)))
	}

	const handleRemoveTimeSlot = (removedIndex) => () => {
		props.onChange(props.timeSlots.filter((_, index) => index !== removedIndex))
	}

	return (
		<>
			<TimeSlotForm onAdd={handleAddTimeSlot} />
			<hr />
			{isEmpty(props.timeSlots)
				? t('campaigns.timeSlots.allDay')
				: props.timeSlots?.map((timeSlot, index) => (
						<TimeSlotForm key={index} timeSlot={timeSlot} index={index} onChange={handleChangeTimeSlot} onRemove={handleRemoveTimeSlot(index)} />
				  ))}
		</>
	)
}

TimeSlotsEdit.propTypes = {
	timeSlots: PropTypes.arrayOf(timeSlotShape).isRequired,
	onChange: PropTypes.func.isRequired,
}

export default TimeSlotsEdit
