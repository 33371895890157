import { apiClientService as client, setErrors, setSuccess, userService } from '@alatimier/genesis-uic'
import {
	DEVICES_CLEAR_STATE,
	DEVICES_CREATE_DEVICE,
	DEVICES_CREATE_DEVICE_FAILURE,
	DEVICES_CREATE_DEVICE_SUCCESS,
	DEVICES_DELETE_DEVICE,
	DEVICES_DELETE_DEVICE_FAILURE,
	DEVICES_DELETE_DEVICE_SUCCESS,
	DEVICES_GET_DEVICE,
	DEVICES_GET_DEVICE_FAILURE,
	DEVICES_GET_DEVICE_SUCCESS,
	DEVICES_GET_DEVICE_TYPES,
	DEVICES_GET_DEVICE_TYPES_FAILURE,
	DEVICES_GET_DEVICE_TYPES_SUCCESS,
	DEVICES_GET_DEVICES,
	DEVICES_GET_DEVICES_FAILURE,
	DEVICES_GET_DEVICES_SUCCESS,
	DEVICES_GET_INFERENCE_TYPES,
	DEVICES_GET_INFERENCE_TYPES_FAILURE,
	DEVICES_GET_INFERENCE_TYPES_SUCCESS,
	DEVICES_UPDATE_DEVICE,
	DEVICES_UPDATE_DEVICE_FAILURE,
	DEVICES_UPDATE_DEVICE_SUCCESS,
} from './devicesTypes'

const devicesEndPoint = '/api/admin/v1/devices'

const canReadVariants = () => userService.hasPermission('DVM_READ')
const canEditVariants = () => userService.hasPermission('DVM_EDIT')

export const getInferenceTypes = () => {
	return (dispatch) => {
		dispatch({ type: DEVICES_GET_INFERENCE_TYPES })
		return client.get(`${devicesEndPoint}/inferenceTypes`).then(async (res) => {
			const data = await res.json()
			if (res.ok) {
				dispatch({
					type: DEVICES_GET_INFERENCE_TYPES_SUCCESS,
					list: data,
				})
			} else {
				dispatch({
					type: DEVICES_GET_INFERENCE_TYPES_FAILURE,
				})
			}
		})
	}
}

export const getDeviceTypes = () => {
	return (dispatch) => {
		dispatch({ type: DEVICES_GET_DEVICE_TYPES })
		return client.get(`${devicesEndPoint}/types`).then(async (res) => {
			const data = await res.json()
			if (res.ok) {
				dispatch({
					type: DEVICES_GET_DEVICE_TYPES_SUCCESS,
					list: data,
				})
			} else {
				dispatch({
					type: DEVICES_GET_DEVICE_TYPES_FAILURE,
				})
			}
		})
	}
}

export const getDevices = () => {
	return (dispatch) => {
		dispatch({ type: DEVICES_GET_DEVICES })
		return client.get(devicesEndPoint).then(async (res) => {
			if (res.ok) {
				const data = await res.json()
				dispatch({
					type: DEVICES_GET_DEVICES_SUCCESS,
					list: data,
				})
			} else {
				dispatch({
					type: DEVICES_GET_DEVICES_FAILURE,
				})
			}
		})
	}
}

export const getDevice = (id) => {
	return (dispatch) => {
		dispatch({ type: DEVICES_GET_DEVICE })
		const promises = []
		promises.push(client.get(`${devicesEndPoint}/${id}`))
		if (canReadVariants()) promises.push(client.get(`${devicesEndPoint}/${id}/variants`))
		return Promise.all(promises).then(async (res) => {
			if (res.every((r) => r.ok)) {
				const deviceData = await res[0].json()
				const variantsData = canReadVariants() ? await res[1].json() : []
				dispatch({
					type: DEVICES_GET_DEVICE_SUCCESS,
					device: deviceData,
					variants: variantsData,
				})
			} else {
				dispatch({
					type: DEVICES_GET_DEVICE_FAILURE,
				})
			}
		})
	}
}

export const createDevice = (
	organizationId,
	name,
	ip,
	address,
	city,
	zipCode,
	country,
	targeting,
	aspectRatioId,
	resolutionId,
	spaceId,
	deviceVariants,
	vpnKey,
	deviceTypeId,
	inferenceTypeId,
	monitoring
) => {
	return (dispatch) => {
		dispatch({ type: DEVICES_CREATE_DEVICE })
		return client
			.post(devicesEndPoint, {
				organizationId,
				name,
				ip,
				address,
				city,
				zipCode,
				country,
				targeting,
				aspectRatioId,
				resolutionId,
				spaceId,
				vpnKey,
				deviceTypeId,
				inferenceTypeId,
				monitoring,
			})
			.then(async (res) => {
				const deviceData = await res.json()
				if (res.ok) {
					if (!canEditVariants() || (await createDeviceVariants(dispatch, deviceData.id, deviceVariants))) {
						dispatch(setSuccess('action.save.success'))
						dispatch({
							type: DEVICES_CREATE_DEVICE_SUCCESS,
							newId: deviceData.id,
						})
					}
				} else {
					dispatch(setErrors(deviceData.errors, deviceData.validationErrors))
					dispatch({ type: DEVICES_CREATE_DEVICE_FAILURE })
				}
			})
	}
}

const createDeviceVariants = async (dispatch, deviceId, deviceVariants) => {
	const res = await client.post(`${devicesEndPoint}/${deviceId}/variants`, { deviceVariants })
	if (res.ok) {
		return true
	} else {
		const variantsData = await res.json()
		dispatch(setErrors([...variantsData.errors, variantsData.validationErrors?.deviceVariants], []))
		dispatch({ type: DEVICES_CREATE_DEVICE_FAILURE })
		return false
	}
}

export const updateDevice = (
	id,
	organizationId,
	name,
	ip,
	address,
	city,
	zipCode,
	country,
	targeting,
	aspectRatioId,
	resolutionId,
	spaceId,
	deviceVariants,
	vpnKey,
	deviceTypeId,
	inferenceTypeId,
	monitoring
) => {
	return (dispatch) => {
		dispatch({ type: DEVICES_UPDATE_DEVICE })
		const promises = []
		promises.push(
			client.put(`${devicesEndPoint}/${id}`, {
				organizationId,
				name,
				ip,
				address,
				city,
				zipCode,
				country,
				targeting,
				aspectRatioId,
				resolutionId,
				spaceId,
				vpnKey,
				deviceTypeId,
				inferenceTypeId,
				monitoring,
			})
		)
		if (canEditVariants()) promises.push(client.post(`${devicesEndPoint}/${id}/variants`, { deviceVariants }))
		return Promise.all(promises).then(async (res) => {
			if (res.every((r) => r.ok)) {
				dispatch(setSuccess('action.save.success'))
				dispatch({
					type: DEVICES_UPDATE_DEVICE_SUCCESS,
				})
			} else {
				const deviceData = res[0].json ? await res[0].json() : {}
				const variantsData = canEditVariants() && res[1].json ? await res[1].json() : {}

				const actionErrors = [...(deviceData.errors || []), ...(variantsData.errors || [])]
				const deviceVariantsValidationError = variantsData.validationErrors?.deviceVariants
				if (deviceVariantsValidationError) {
					actionErrors.push(deviceVariantsValidationError)
				}

				dispatch(setErrors(actionErrors, deviceData.validationErrors))
				dispatch({ type: DEVICES_UPDATE_DEVICE_FAILURE })
			}
		})
	}
}

export const deleteDevices = (ids) => {
	return (dispatch) => {
		dispatch({ type: DEVICES_DELETE_DEVICE })
		const promises = ids.map((id) => client.del(`${devicesEndPoint}/${id}`))
		return Promise.all(promises).then(async (results) => {
			if (results.every((r) => r.ok)) {
				dispatch(setSuccess('action.delete.success'))
			} else {
				const data = await results.find((r) => !r.ok).json()
				dispatch(setErrors(data.errors, data.validationErrors))
				dispatch({ type: DEVICES_DELETE_DEVICE_FAILURE })
			}
			if (results.some((r) => r.ok)) dispatch({ type: DEVICES_DELETE_DEVICE_SUCCESS })
		})
	}
}

export const clearState = () => {
	return {
		type: DEVICES_CLEAR_STATE,
	}
}
