import {
	CAMPAIGNS_CLEAR_STATE,
	CAMPAIGNS_CREATE_CAMPAIGN,
	CAMPAIGNS_CREATE_CAMPAIGN_FAILURE,
	CAMPAIGNS_CREATE_CAMPAIGN_SUCCESS,
	CAMPAIGNS_DELETE_CAMPAIGN,
	CAMPAIGNS_DELETE_CAMPAIGN_FAILURE,
	CAMPAIGNS_DELETE_CAMPAIGN_SUCCESS,
	CAMPAIGNS_GET_CAMPAIGN,
	CAMPAIGNS_GET_CAMPAIGN_FAILURE,
	CAMPAIGNS_GET_CAMPAIGN_SUCCESS,
	CAMPAIGNS_GET_CAMPAIGNS,
	CAMPAIGNS_GET_CAMPAIGNS_FAILURE,
	CAMPAIGNS_GET_CAMPAIGNS_SUCCESS,
	CAMPAIGNS_GET_STATUSES,
	CAMPAIGNS_GET_STATUSES_FAILURE,
	CAMPAIGNS_GET_STATUSES_SUCCESS,
	CAMPAIGNS_UPDATE_CAMPAIGN,
	CAMPAIGNS_UPDATE_CAMPAIGN_FAILURE,
	CAMPAIGNS_UPDATE_CAMPAIGN_STATUS,
	CAMPAIGNS_UPDATE_CAMPAIGN_STATUS_FAILURE,
	CAMPAIGNS_UPDATE_CAMPAIGN_STATUS_SUCCESS,
	CAMPAIGNS_UPDATE_CAMPAIGN_SUCCESS,
} from './campaignsTypes'

const initialState = {
	pending: false,
	list: [],
	campaign: null,
	newId: null,
	statuses: [],
	status: null,
}

const campaignsReducer = (state = initialState, action) => {
	switch (action.type) {
		case CAMPAIGNS_GET_CAMPAIGNS:
		case CAMPAIGNS_GET_CAMPAIGN:
		case CAMPAIGNS_CREATE_CAMPAIGN:
		case CAMPAIGNS_UPDATE_CAMPAIGN:
		case CAMPAIGNS_UPDATE_CAMPAIGN_STATUS:
		case CAMPAIGNS_DELETE_CAMPAIGN:
		case CAMPAIGNS_GET_STATUSES:
			return {
				...state,
				pending: true,
				status: null,
			}
		case CAMPAIGNS_GET_CAMPAIGNS_SUCCESS:
			return {
				pending: false,
				list: action.list,
			}
		case CAMPAIGNS_GET_CAMPAIGN_SUCCESS:
			return {
				pending: false,
				campaign: action.campaign,
			}
		case CAMPAIGNS_CREATE_CAMPAIGN_SUCCESS:
			return {
				pending: false,
				newId: action.newId,
				status: 'created',
			}
		case CAMPAIGNS_UPDATE_CAMPAIGN_SUCCESS:
		case CAMPAIGNS_UPDATE_CAMPAIGN_STATUS_SUCCESS:
			return {
				...state,
				pending: false,
				status: 'updated',
			}
		case CAMPAIGNS_DELETE_CAMPAIGN_SUCCESS:
			return {
				...state,
				pending: false,
				status: 'deleted',
			}
		case CAMPAIGNS_GET_STATUSES_SUCCESS:
			return {
				...state,
				pending: false,
				statuses: action.statuses,
			}
		case CAMPAIGNS_GET_CAMPAIGNS_FAILURE:
		case CAMPAIGNS_GET_CAMPAIGN_FAILURE:
		case CAMPAIGNS_CREATE_CAMPAIGN_FAILURE:
		case CAMPAIGNS_UPDATE_CAMPAIGN_FAILURE:
		case CAMPAIGNS_UPDATE_CAMPAIGN_STATUS_FAILURE:
		case CAMPAIGNS_DELETE_CAMPAIGN_FAILURE:
		case CAMPAIGNS_GET_STATUSES_FAILURE:
			return {
				...state,
				pending: false,
			}
		case CAMPAIGNS_CLEAR_STATE:
			return {
				...initialState,
			}
		default:
			return state
	}
}

export default campaignsReducer
