import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GBadge, GButton, GCard, GDocument, GIcon, GMessage, GModal, GSecured, GTable, GTitleBar, GTooltip, isEmpty } from '@alatimier/genesis-uic'
import { deleteDevices, getDevice, getDevices } from '../../store/devices/devicesActions'
import { clearState, getAdvertisements } from '../../store/advertisements/advertisementsActions'

const DeviceList = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [showPreviewModal, setShowPreviewModal] = useState(false)
	const [readyAdvertisement, setReadyAdvertisement] = useState(null)
	const [readyAdvertisementCount, setReadyAdvertisementCount] = useState(0)
	const [previewCurrentIndex, setPreviewCurrentIndex] = useState(null)

	const devices = useSelector((state) => state.devices.list)
	const devicesPending = useSelector((state) => state.devices.pending)
	const status = useSelector((state) => state.devices.status)

	const device = useSelector((state) => state.devices.device)
	const advertisements = useSelector((state) => state.advertisements.list)
	const advertisementsPending = useSelector((state) => state.advertisements.pending)

	useEffect(() => fetchDevices(), [])
	useEffect(() => handleStatusChange(), [status])

	useEffect(() => {
		readyAdvertisement && setReadyAdvertisementCount(readyAdvertisementCount + 1)
	}, [readyAdvertisement])

	useEffect(() => {
		if (advertisements.length > 0 && readyAdvertisementCount === advertisements.length) {
			setPreviewCurrentIndex(0)
		}
	}, [readyAdvertisementCount])

	const fetchDevices = () => {
		dispatch(getDevices())
	}

	const handleStatusChange = () => {
		if (status === 'deleted') {
			dispatch(getDevices())
		}
	}

	const handleEdit = (id) => {
		navigate(`/devices/${id}`)
	}

	const handleDuplicate = (id) => {
		dispatch(getDevice(id))
		navigate(`/devices/clone`)
	}

	const handleDelete = (ids) => {
		dispatch(deleteDevices(ids))
	}

	const handlePreview = (id) => {
		dispatch(getAdvertisements(id))
		dispatch(getDevice(id))
		setReadyAdvertisement(null)
		setReadyAdvertisementCount(0)
		setPreviewCurrentIndex(null)
		setShowPreviewModal(true)
	}

	const handleClosePreview = () => {
		dispatch(clearState())
		setShowPreviewModal(false)
	}

	const columns = [
		{ itemProp: 'health', label: ' ', width: '3%', filter: false, sorter: false, permission: 'DEVICE_HEALTH_READ' },
		{ itemProp: 'name', label: t('devices.table.name') },
		{ itemProp: 'address', label: t('devices.table.address') },
		{ itemProp: 'city', label: t('devices.table.city') },
		{ itemProp: 'country', label: t('devices.table.country') },
	]

	const rowActions = [
		{
			title: t('action.edit'),
			icon: 'fa-edit',
			fn: (item) => handleEdit(item.id),
			permission: 'DEVICE_EDIT',
		},
		{
			title: t('action.duplicate'),
			icon: 'fa-copy',
			fn: (item) => handleDuplicate(item.id),
			permission: 'DEVICE_EDIT',
		},
		{
			title: t('action.delete'),
			icon: 'fa-trash',
			fn: (item) => handleDelete([item.id]),
			confirmMessage: t('action.delete.confirm'),
			permission: 'DEVICE_EDIT',
		},
		{
			title: t('action.preview'),
			icon: 'fa-eye',
			fn: (item) => handlePreview(item.id),
			permission: 'ADVERTISEMENT_READ',
		},
	]

	const globalActions = [
		{
			icon: 'fa-trash',
			title: t('action.delete'),
			fn: (selected) => handleDelete(selected.map((item) => item.id)),
			confirmMessage: t('action.delete.confirm'),
			permission: 'DEVICE_EDIT',
		},
	]

	const renderPending = () => (
		<div className="text-center">
			<GIcon name="fa-spinner" spin />
		</div>
	)

	const renderDeviceAdvertisementsPreview = () => {
		if (device && advertisements && !advertisementsPending) {
			const aspectRatioArray = device.aspectRatioId.split('_')
			const widthRatio = aspectRatioArray[0]
			const heightRatio = aspectRatioArray[1]
			const large = widthRatio > 18
			const ratioMultiplier = 25

			const spinner = previewCurrentIndex === null ? renderPending() : <></>
			const carrousel = advertisements.map((advertisement, index) => (
				<GDocument
					key={advertisement.shortcut}
					src={advertisement.fileUri}
					autoPlay
					show={index === previewCurrentIndex}
					imgDisplayTime="8000"
					onReady={() => setReadyAdvertisement(advertisement.shortcut)}
					onEnded={() => (advertisements[previewCurrentIndex + 1] ? setPreviewCurrentIndex(previewCurrentIndex + 1) : handleClosePreview())}
					width={widthRatio * ratioMultiplier}
					height={heightRatio * ratioMultiplier}
					style={{ display: 'block', margin: 'auto', border: '5px solid black', borderRadius: '10px' }}
				/>
			))

			return (
				<GSecured permission="ADVERTISEMENT_READ">
					<GModal
						show={showPreviewModal}
						color="primary"
						title={t('devices.preview.title')}
						onClick={handleClosePreview}
						onCancel={handleClosePreview}
						size={large ? 'xl' : 'md'}
					>
						{advertisements.length > 0 ? (
							<>
								{spinner}
								{carrousel}
							</>
						) : (
							t('devices.preview.empty')
						)}
					</GModal>
				</GSecured>
			)
		}
		return <></>
	}

	const renderDevices = () => {
		if (isEmpty(devices)) {
			return <GMessage value={t('devices.list.empty')} />
		}
		return (
			<>
				<GTable
					id="devicesList"
					items={devices}
					columns={columns}
					hover
					columnSorter
					columnFilter
					rowActions={rowActions}
					globalActions={globalActions}
					pagination
					itemsPerPage={15}
					richCellContent={richCellContent}
				/>
				{renderDeviceAdvertisementsPreview()}
			</>
		)
	}

	const richCellContent = {
		health: (item) => {
			if (item.monitoring && item.health) {
				let color
				if (item.health.status === 'OFF') {
					color = 'secondary'
				}
				if (item.health.status === 'RED') {
					color = 'danger'
				}
				if (item.health.status === 'ORANGE') {
					color = 'warning'
				}
				if (item.health.status === 'GREEN') {
					color = 'success'
				}
				return (
					<td>
						<GTooltip content={t(`devices.health.${item.health.status}`)} placement="top" color={color}>
							<span>
								<GBadge color={color} label={' '} className="mb-1" />
							</span>
						</GTooltip>
					</td>
				)
			}
			return <td></td>
		},
	}

	return (
		<>
			<GTitleBar>{t('devices.title')}</GTitleBar>
			<GCard title={t('devices.list.title')} row>
				{devicesPending && isEmpty(devices) ? renderPending() : renderDevices()}
				<GSecured permission="DEVICE_EDIT">
					<Link to="/devices/new">
						<GButton label={t('devices.action.create')} location={['page', 'page-top']} />
					</Link>
				</GSecured>
			</GCard>
		</>
	)
}

export default DeviceList
