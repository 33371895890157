import { apiClientService as client } from '@alatimier/genesis-uic'
import { RESOLUTIONS_GET_RESOLUTIONS, RESOLUTIONS_GET_RESOLUTIONS_FAILURE, RESOLUTIONS_GET_RESOLUTIONS_SUCCESS } from './resolutionsTypes'

const resolutionsEndPoint = '/api/admin/v1/resolutions'

export const getResolutions = () => {
	return (dispatch) => {
		dispatch({ type: RESOLUTIONS_GET_RESOLUTIONS })
		return client.get(resolutionsEndPoint).then(async (res) => {
			const data = await res.json()
			if (res.ok) {
				dispatch({
					type: RESOLUTIONS_GET_RESOLUTIONS_SUCCESS,
					list: data,
				})
			} else {
				dispatch({
					type: RESOLUTIONS_GET_RESOLUTIONS_FAILURE,
				})
			}
		})
	}
}
