import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GCard, GCrudFormActions, GInput, GOrganizationSelect, GSecured, GSelect, GTitleBar, isEmpty } from '@alatimier/genesis-uic'
import { clearState, createSegment, deleteSegments, getClothes, getGenders, getSegment, updateSegment } from '../../store/segments/segmentsActions'

const SegmentEdit = () => {
	const minAgeAllowed = 0
	const maxAgeAllowed = 100

	const { t } = useTranslation()
	const params = useParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const segment = useSelector((state) => state.segments.segment)
	const pending = useSelector((state) => state.segments.pending)
	const status = useSelector((state) => state.segments.status)
	const createdSegmentId = useSelector((state) => state.segments.newId)
	const availableGenders = useSelector((state) => state.segments.genders)
	const availableClothes = useSelector((state) => state.segments.clothes)

	const [organizationId, setOrganizationId] = useState()
	const [name, setName] = useState()
	const [minAge, setMinAge] = useState(minAgeAllowed)
	const [maxAge, setMaxAge] = useState(maxAgeAllowed)
	const [genders, setGenders] = useState([])
	const [genderSelectOptions, setGenderSelectOptions] = useState([])
	const [clothes, setClothes] = useState([])
	const [clothesSelectOptions, setClothesSelectOptions] = useState([])
	const [close, setClose] = useState(false)

	useEffect(() => fetchGendersAndClothes(), [])
	useEffect(() => buildGenderSelectOptions(), [availableGenders])
	useEffect(() => buildClothesSelectOptions(), [availableClothes])
	useEffect(() => fetchSegment(), [params.id])
	useEffect(() => prefillForm(), [segment, genderSelectOptions, clothesSelectOptions])
	useEffect(() => handleWorkflowStatusChange(), [status])

	const isValid = () => !isEmpty(organizationId) && !isEmpty(name)

	const fetchGendersAndClothes = () => {
		dispatch(getGenders())
		dispatch(getClothes())
	}

	const fetchSegment = () => {
		if (params.id && params.id !== 'new') {
			dispatch(getSegment(params.id))
		}
	}

	const buildGenderSelectOptions = () => {
		if (availableGenders) {
			setGenderSelectOptions(
				availableGenders.map((r) => ({
					value: r,
					label: t(`gender.${r}`),
				}))
			)
		}
	}

	const buildClothesSelectOptions = () => {
		if (availableClothes) {
			setClothesSelectOptions(
				availableClothes.map((clothing) => ({
					value: clothing,
					label: t(`clothes.${clothing}`),
				}))
			)
		}
	}

	function prefillForm() {
		if (params.id !== 'new' && segment && genderSelectOptions && clothesSelectOptions.length > 0) {
			setOrganizationId(segment.organizationId)
			setName(segment.name)
			setGenders(genderSelectOptions.filter((genderOption) => segment.genders?.includes(genderOption.value)))
			setMinAge(segment.minAge ? segment.minAge : minAgeAllowed)
			setMaxAge(segment.maxAge ? segment.maxAge : maxAgeAllowed)
			setClothes(clothesSelectOptions.filter((clothOption) => segment.clothes?.includes(clothOption.value)))
		}
	}

	const handleWorkflowStatusChange = () => {
		switch (status) {
			case 'created':
				close ? doClose() : navigate(`/segments/${createdSegmentId}`)
				dispatch(clearState())
				break
			case 'updated':
				close ? doClose() : dispatch(getSegment(params.id))
				break
			case 'deleted':
				doClose()
				break
		}
	}

	const handleSave = () => {
		setClose(true)
		apply()
	}

	const handleDelete = () => {
		dispatch(deleteSegments([segment.id]))
	}

	const doClose = () => {
		navigate('/segments')
	}

	function apply() {
		const parameters = [organizationId, name, genders.map((gender) => gender.value), minAge, maxAge, clothes.map((cloth) => cloth.value)]
		if (segment) {
			dispatch(updateSegment(segment.id, ...parameters))
		} else {
			dispatch(createSegment(...parameters))
		}
	}

	return (
		<>
			<GTitleBar>{t('segments.title')}</GTitleBar>
			<GCard title={t('segments.properties.title')} row>
				<GOrganizationSelect id="organizationId" value={organizationId} onChange={setOrganizationId} maxLevel={1} permission="SEGMENT_EDIT" />
				<GInput id="name" label={t('segments.form.name')} value={name} onChange={setName} required />
				<GSelect
					id="genders"
					label={t('segments.form.genders')}
					options={genderSelectOptions}
					value={genders}
					onChange={setGenders}
					closeMenuOnSelect={false}
					multiple
					clearable
				/>
				<GInput
					id="minAge"
					label={t('segments.form.minAge')}
					value={minAge}
					onChange={setMinAge}
					type="range"
					rangeMin={minAgeAllowed}
					rangeMax={maxAgeAllowed}
				/>
				<GInput
					id="maxAge"
					label={t('segments.form.maxAge')}
					value={maxAge}
					onChange={setMaxAge}
					type="range"
					rangeMin={minAgeAllowed}
					rangeMax={maxAgeAllowed}
				/>
				<GSecured permission="SEGMENT_EDIT_CLOTHES">
					<GSelect
						id="clothes"
						label={t('segments.form.clothes')}
						options={clothesSelectOptions}
						value={clothes}
						onChange={setClothes}
						closeMenuOnSelect={false}
						multiple
						clearable
					/>
				</GSecured>
				<GCrudFormActions
					canSave={isValid()}
					onApply={apply}
					onSave={handleSave}
					canCancel
					onCancel={doClose}
					canDelete={!!segment}
					onDelete={handleDelete}
					busy={pending}
					location={['page', 'page-top']}
				/>
			</GCard>
		</>
	)
}

export default SegmentEdit
