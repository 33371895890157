/** @jsxImportSource @emotion/react */
import { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import head from 'lodash/head'
import last from 'lodash/last'
import get from 'lodash/get'
import { ResponsiveBar } from '@nivo/bar'
import { ResponsiveLine } from '@nivo/line'
import { GInput, GMessage, isEmpty } from '@alatimier/genesis-uic'
import { completeDatesForComparison, formatDate, getDateUnitAndFormat, getMarker, objectiveGraphContext } from '../utils'
import { darkerBackgroundTheme, darkModeTheme, objectiveColors } from '../../../utils/ChartUtils'
import { localStorageKeys } from '../../../utils/LocalStorageUtils'

const SalesChart = memo((props) => {
	const { t } = useTranslation()

	const isDarkMode = useSelector((store) => store.g_display.darkMode)

	const cumulatedMeasuredValues = Object.values(props.data.measuredCumulated)
	const totalMeasured = cumulatedMeasuredValues.length > 0 && last(cumulatedMeasuredValues)
	const cumulatedReferenceValues = Object.values(props.data.referenceCumulated)
	const totalReference = cumulatedReferenceValues.length > 0 && last(cumulatedReferenceValues)
	const noBenefit = !totalMeasured?.benefit && !totalReference?.benefit

	// 'turnover' or 'benefit'
	const [valueType, setValueType] = useState((!noBenefit && localStorage.getItem(localStorageKeys.salesChartValueType)) || 'turnover')

	const { campaign, visual, measureEndDate, referenceStartDate, referenceEndDate } = props.data

	const dates = Object.keys(props.data.measured).sort()
	const referenceDates = Object.keys(props.data.reference).sort()

	// Have at least one date, for reference dates to grow on
	if (!dates.length) dates.push(campaign.startDate)

	const { dateUnit, dateFormat } = getDateUnitAndFormat(campaign.startDate, measureEndDate, head(referenceDates) ?? head(dates), last(dates))
	const { dateFormat: referenceDateFormat } = getDateUnitAndFormat(referenceStartDate, referenceEndDate, head(referenceDates) ?? head(dates), last(dates))

	completeDatesForComparison(dates, referenceDates, dateUnit, campaign.startDate)

	const referenceValues = Object.values(props.data.reference)
	const barData = dates.map((date, index) => ({
		date,
		measured: get(props.data.measured, [date, valueType]),
		reference: get(referenceValues, [index, valueType]),
	}))

	const referenceCumulatedValues = Object.values(props.data.referenceCumulated)
	const lineData = [
		{
			id: 'reference',
			data: dates.map((date, index) => ({
				x: date,
				y: get(referenceCumulatedValues, [index, valueType], null),
			})),
		},
		{
			id: 'measured',
			data: dates.map((date) => ({
				x: date,
				y: get(props.data.measuredCumulated, [date, valueType], null),
			})),
		},
	]

	const emptyReferenceData = isEmpty(lineData[0].data.filter(({ y }) => y))
	const emptyMeasuredData = isEmpty(lineData[1].data.filter(({ y }) => y))
	if (emptyReferenceData && emptyMeasuredData) {
		return <GMessage value={t('objectives.chart.noData')} className="mb-0" />
	}

	const dateTickStep = Math.ceil(dates.length / 18)

	const getLabel = (id) => (id === 'measured' ? visual.name : t('objectives.chart.reference'))
	const getAndFormatReferenceDate = (baseDate) => {
		const referenceDate = referenceDates[dates.indexOf(baseDate)]
		return DateTime.fromISO(referenceDate).toLocaleString(referenceDateFormat)
	}
	const formatValue = (value) => `${Number(value).toLocaleString(undefined, { maximumFractionDigits: 2 })}${t('objectives.currency')}`

	const handleValueTypeChange = (value) => {
		const newValueType = value ? 'benefit' : 'turnover'
		setValueType(newValueType)
		localStorage.setItem(localStorageKeys.salesChartValueType, newValueType)
	}

	const commonChartProperties = {
		margin: { top: 20, right: 30, bottom: 25, left: 60 },
		axisLeft: {
			legend: `${t(`objectives.chart.${valueType}`)} (${t('objectives.currency')})`,
			legendPosition: 'middle',
			legendOffset: -55,
		},
		axisBottom: {
			format: (date) => dates.indexOf(date) % dateTickStep === 0 && formatDate(dateFormat)(date),
		},
		theme: isDarkMode ? darkModeTheme : props.context === objectiveGraphContext.LIST ? darkerBackgroundTheme : undefined,
	}

	const getErrorMessage = () => {
		if (props.context === objectiveGraphContext.EDIT) {
			if (emptyReferenceData) return <GMessage value={t('objectives.chart.noReferenceData')} />
			if (emptyMeasuredData) return <GMessage value={t('objectives.chart.noMeasuredData')} />
		}
	}

	return (
		<div data-testid="sales-chart" className="w-100">
			{getErrorMessage()}
			<div
				className={`d-flex justify-content-center ${noBenefit ? 'text-muted' : ''}`}
				css={{
					'.g-form-switch': {
						'input + span::after, input:checked + span::after': { content: '""' },
					},
				}}
			>
				<label className="me-3">{t('objectives.chart.turnover')}</label>
				<GInput id="value_type" type="checkbox" value={valueType === 'benefit'} onChange={handleValueTypeChange} simple disabled={noBenefit} />
				<label className="ms-3">{t('objectives.chart.benefit')}</label>
			</div>
			<div style={{ height: props.context === objectiveGraphContext.LIST ? '200px' : '300px', width: '100%' }}>
				<ResponsiveBar
					data={barData}
					indexBy="date"
					keys={['reference', 'measured']}
					groupMode="grouped"
					valueFormat={formatValue}
					colors={({ id }) => objectiveColors[id]}
					labelSkipWidth={40}
					labelSkipHeight={15}
					tooltip={({ indexValue, id, formattedValue, color, index }) => (
						<div
							style={{
								padding: 12,
								color,
								background: '#222222',
								borderRadius: '8px',
								position: 'absolute',
								width: 'max-content',
								transform: index < barData.length / 2 ? 'translate(0,0)' : 'translate(-100%, 0)',
							}}
						>
							{id === 'reference' ? getAndFormatReferenceDate(indexValue) : formatDate(dateFormat)(indexValue)} - {getLabel(id)}: {formattedValue}
						</div>
					)}
					{...commonChartProperties}
				/>
			</div>
			<div style={{ height: props.context === objectiveGraphContext.LIST ? '200px' : '300px', width: '100%' }}>
				<ResponsiveLine
					data={emptyReferenceData ? [lineData[1]] : emptyMeasuredData ? [lineData[0]] : lineData}
					xFormat={formatDate(dateFormat)}
					yFormat={formatValue}
					yScale={{
						type: 'linear',
						min: 'auto',
						max:
							props.target && !emptyReferenceData && !emptyMeasuredData && valueType === 'turnover'
								? Math.max(props.target + totalReference.turnover, totalMeasured.turnover)
								: 'auto',
					}}
					colors={({ id }) => objectiveColors[id]}
					tooltip={({ point: { color, data, serieId } }) => (
						<div
							style={{
								padding: 12,
								color,
								background: '#222222',
								borderRadius: '8px',
								position: 'absolute',
								transform: dates.indexOf(data.x) < dates.length / 2 ? 'translate(0,0)' : 'translate(-100%, 0)',
								width: 'max-content',
							}}
						>
							{serieId === 'reference' ? getAndFormatReferenceDate(data.x) : data.xFormatted} - {getLabel(serieId)}: {data.yFormatted}
						</div>
					)}
					useMesh
					enablePoints={false}
					animate={false} // prevent display bug on different data with similar dates
					markers={
						props.target && !emptyReferenceData && valueType === 'turnover'
							? [getMarker(props.target + totalReference.turnover, t('objectives.chart.target'), isDarkMode)]
							: []
					}
					{...commonChartProperties}
				/>
			</div>
		</div>
	)
})

SalesChart.propTypes = {
	data: PropTypes.object.isRequired,
	target: PropTypes.number,
	context: PropTypes.oneOf(Object.values(objectiveGraphContext)).isRequired,
}

export default SalesChart
