export default {
	triangle: 'm1,280.375l149,-260.75l149,260.75z',
	umbrella:
		'm138.31999,1l0,29.565c-75.13835,4.16908 -134.31995,48.17229 -134.31999,101.835c0,-9.67107 16.35202,-17.51999 36.5,-17.51999c20.14798,0 36.5,7.84892 36.5,17.51999c0,-9.67107 16.35202,-17.51999 36.5,-17.51999c9.59525,0 18.30284,1.83565 24.81999,4.745l0,144.17499c0,6.44742 -5.23262,11.68002 -11.67999,11.68002c-6.44734,0 -11.68,-5.2326 -11.68,-11.68002l0,-5.84l-23.36,0l0,5.84c0,19.34207 15.6979,35.04001 35.04,35.04001c19.34206,0 35.04001,-15.69794 35.04001,-35.04001l0,-144.17499c6.51714,-2.90935 15.22475,-4.745 24.81999,-4.745c20.14798,0 36.5,7.84892 36.5,17.51999c0,-9.67107 16.35202,-17.51999 36.5,-17.51999c20.14798,0 36.5,7.84892 36.5,17.51999c0,-53.66274 -59.18159,-97.66592 -134.31999,-101.835l0,-29.565l-23.36002,0z',
	star: 'm1,116.58409l113.82668,0l35.17332,-108.13487l35.17334,108.13487l113.82666,0l-92.08755,66.83026l35.17514,108.13487l-92.08759,-66.83208l-92.08757,66.83208l35.17515,-108.13487l-92.08758,-66.83026z',
	pentagon: 'm1.00035,116.97758l148.99963,-108.4053l148.99998,108.4053l-56.91267,175.4042l-184.1741,0l-56.91284,-175.4042z',
	dialog_balloon:
		'm3.88165,296.34811l58.64952,-105.30074l0,0c-62.13446,-31.76456 -79.86445,-91.6022 -40.96117,-138.24044c38.90255,-46.63797 121.70818,-64.81269 191.29914,-41.98796c69.59094,22.8246 103.19446,79.17835 77.63046,130.19172c-25.56265,51.01335 -101.92546,79.99094 -176.41714,66.94487l-110.20081,88.39255z',
	smiley: 'm68.49886,214.78838q81.06408,55.67332 161.93891,0m-144.36983,-109.9558c0,-8.60432 6.97517,-15.57949 15.57948,-15.57949c8.60431,0 15.57948,6.97517 15.57948,15.57949c0,8.60431 -6.97517,15.57947 -15.57948,15.57947c-8.60431,0 -15.57948,-6.97516 -15.57948,-15.57947m95.83109,0c0,-8.60432 6.97517,-15.57949 15.57948,-15.57949c8.60431,0 15.57947,6.97517 15.57947,15.57949c0,8.60431 -6.97516,15.57947 -15.57947,15.57947c-8.60429,0 -15.57948,-6.97516 -15.57948,-15.57947m-181.89903,44.73038l0,0c0,-82.60133 66.96162,-149.56296 149.56296,-149.56296c82.60135,0 149.56296,66.96162 149.56296,149.56296c0,82.60135 -66.96161,149.56296 -149.56296,149.56296c-82.60133,0 -149.56296,-66.96161 -149.56296,-149.56296zm0,0l0,0c0,-82.60133 66.96162,-149.56296 149.56296,-149.56296c82.60135,0 149.56296,66.96162 149.56296,149.56296c0,82.60135 -66.96161,149.56296 -149.56296,149.56296c-82.60133,0 -149.56296,-66.96161 -149.56296,-149.56296z',
	arrow_turn:
		'm187.66985,234.28424l2.06375,-22.20483l-24.28615,-3.86421c-61.48712,-9.78288 -121.75832,-51.26649 -155.31676,-106.90179c-6.02069,-9.98148 -10.05047,-19.59818 -8.95503,-21.37048c2.51272,-4.06578 63.74106,-36.43469 68.91894,-36.43469c2.11224,0 7.18627,5.95309 11.27556,13.22911c17.44035,31.03078 62.57552,63.39609 94.35383,67.65826l12.88387,1.7281l-2.21523,-19.19039c-2.29968,-19.92216 -1.65292,-24.10554 3.72659,-24.10554c3.43987,0 106.12749,76.50481 109.06303,81.25475c2.22696,3.60321 -11.89679,16.9705 -62.46501,59.11911c-21.96555,18.30804 -42.4514,33.28745 -45.52422,33.28745c-4.91821,0 -5.33987,-2.65765 -3.52318,-22.20483z',
}
