import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { GSecured, isEmpty } from '@alatimier/genesis-uic'
import { DateTime, Interval } from 'luxon'
import DashboardForm from './DashboardForm'
import ViewsDashboard from './ViewsDashboard'
import AudienceDashboard from './AudienceDashboard'

const Dashboard = () => {
	const { dashboard: dashboardName = 'views' } = useParams()

	const [from, setFrom] = useState()
	const [to, setTo] = useState()

	const data = useSelector((state) => state.statistics.data[dashboardName])

	const getTimeRange = () => {
		// dateFormat depends on from/to and not on dates because the API does so
		if (isEmpty(from)) return Interval.before(DateTime.now(), { month: 1 })
		else if (isEmpty(to)) return Interval.fromDateTimes(DateTime.now(), DateTime.fromISO(to))
		else return Interval.fromDateTimes(DateTime.fromISO(from), DateTime.fromISO(to))
	}
	const getDates = () => {
		if (isEmpty(data)) return []
		return [
			...new Set(
				data
					.reduce((dates, { visualData }) => {
						return dates.concat(Object.keys(visualData.histogram ?? {}))
					}, [])
					.sort()
			),
		]
	}

	// This variable is memoized to secure the memoization of the chart components
	const timeData = useMemo(() => {
		const timeRange = getTimeRange()
		const dateFormat =
			timeRange.length('hours') < 100
				? DateTime.TIME_SIMPLE
				: timeRange.length('weeks') < 100
				? { day: 'numeric', month: 'numeric' }
				: { month: 'short', year: '2-digit' }

		const dates = getDates()

		if (dates.length > 0) {
			// Add one more date to have the last step in stream charts
			const lastDate = DateTime.fromISO(dates.slice(-1)[0]).toUTC()
			if (timeRange.length('minutes') < 200) dates.push(lastDate.plus({ minute: 1 }).toISO())
			else if (timeRange.length('hours') < 100) dates.push(lastDate.plus({ hour: 1 }).toISO())
			else if (timeRange.length('days') < 100) dates.push(lastDate.plus({ day: 1 }).toISO())
			else if (timeRange.length('weeks') < 100) dates.push(lastDate.plus({ week: 1 }).toISO())
			else dates.push(lastDate.plus({ month: 1 }).toISO())

			// Add an ellipsis from 'from' date to first data filled date
			const fromHasNoData =
				(timeRange.length('hours') < 100 && dates[0].slice(0, -1) > from) ||
				(timeRange.length('hours') >= 100 && dates[0].slice(0, 10) > from?.slice(0, 10))
			if (fromHasNoData) dates.unshift(DateTime.fromISO(from).toUTC().toISO())
		}

		return { dates, dateFormat }
	}, [data])

	const handleDateChange = (date, value) => {
		date === 'from' ? setFrom(value) : setTo(value)
	}

	return (
		<GSecured permission={`STATISTICS_${dashboardName.toUpperCase()}`}>
			<DashboardForm
				dashboardName={dashboardName}
				from={from}
				to={to}
				onDateChange={handleDateChange}
				emptySelection={dashboardName === 'views' && Array.isArray(data) && data.length === 0}
			/>
			{dashboardName === 'views' && <ViewsDashboard data={data} dates={timeData.dates} dateFormat={timeData.dateFormat} />}
			{dashboardName === 'audience' && <AudienceDashboard data={data} dates={timeData.dates} dateFormat={timeData.dateFormat} />}
		</GSecured>
	)
}

export default Dashboard
