import { apiClientService as client, setSuccess, setErrors } from '@alatimier/genesis-uic'
import { SALES_UPLOAD, SALES_UPLOAD_FAILURE, SALES_UPLOAD_SUCCESS } from './salesTypes'

const salesEndPoint = '/api/admin/v1/sales'

export const exportSales = (organizationId, file) => {
	return (dispatch) => {
		dispatch({ type: SALES_UPLOAD })
		return client.postMultipart(salesEndPoint, { organizationId, file }, true).then(async (res) => {
			const data = await res.json()
			if (res.ok) {
				dispatch(setSuccess('action.save.success'))
				dispatch({ type: SALES_UPLOAD_SUCCESS })
			} else {
				dispatch(setErrors([...(data.errors || []), ...Object.values(data.validationErrors || [])]))
				dispatch({ type: SALES_UPLOAD_FAILURE })
			}
		})
	}
}
