import {
	ADVERTISEMENTS_CLEAR_STATE,
	ADVERTISEMENTS_GET_ADVERTISEMENTS,
	ADVERTISEMENTS_GET_ADVERTISEMENTS_FAILURE,
	ADVERTISEMENTS_GET_ADVERTISEMENTS_SUCCESS,
} from './advertisementsTypes'

const initialState = {
	pending: false,
	list: [],
}

const advertisementsReducer = (state = initialState, action) => {
	switch (action.type) {
		case ADVERTISEMENTS_GET_ADVERTISEMENTS:
			return {
				...state,
				pending: true,
			}
		case ADVERTISEMENTS_GET_ADVERTISEMENTS_SUCCESS:
			return {
				pending: false,
				list: action.list,
			}
		case ADVERTISEMENTS_GET_ADVERTISEMENTS_FAILURE:
			return {
				...state,
				pending: false,
			}
		case ADVERTISEMENTS_CLEAR_STATE:
			return {
				...initialState,
			}
		default:
			return state
	}
}

export default advertisementsReducer
