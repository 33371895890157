import { EXPORT_DOWNLOAD, EXPORT_DOWNLOAD_FAILURE, EXPORT_DOWNLOAD_SUCCESS } from './exportTypes'

const initialState = {
	pending: false,
}

const exportReducer = (state = initialState, action) => {
	switch (action.type) {
		case EXPORT_DOWNLOAD:
			return {
				...state,
				pending: true,
			}
		case EXPORT_DOWNLOAD_SUCCESS:
			return {
				...state,
				pending: false,
			}
		case EXPORT_DOWNLOAD_FAILURE:
			return {
				...state,
				pending: false,
			}
		default:
			return state
	}
}

export default exportReducer
