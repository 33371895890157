import {
	VISUAL_WALLS_CLEAR_STATE,
	VISUAL_WALLS_CREATE_VISUAL_WALL,
	VISUAL_WALLS_CREATE_VISUAL_WALL_FAILURE,
	VISUAL_WALLS_CREATE_VISUAL_WALL_SUCCESS,
	VISUAL_WALLS_DELETE_VISUAL_WALL,
	VISUAL_WALLS_DELETE_VISUAL_WALL_FAILURE,
	VISUAL_WALLS_DELETE_VISUAL_WALL_SUCCESS,
	VISUAL_WALLS_GET_VISUAL_WALL,
	VISUAL_WALLS_GET_VISUAL_WALL_FAILURE,
	VISUAL_WALLS_GET_VISUAL_WALL_SUCCESS,
	VISUAL_WALLS_GET_VISUAL_WALLS,
	VISUAL_WALLS_GET_VISUAL_WALLS_FAILURE,
	VISUAL_WALLS_GET_VISUAL_WALLS_SUCCESS,
	VISUAL_WALLS_UPDATE_VISUAL_WALL,
	VISUAL_WALLS_UPDATE_VISUAL_WALL_FAILURE,
	VISUAL_WALLS_UPDATE_VISUAL_WALL_SUCCESS,
} from './visualWallsTypes'

const initialState = {
	pending: false,
	list: [],
	visualWall: null,
	newId: null,
	status: null,
}

const visualWallsReducer = (state = initialState, action) => {
	switch (action.type) {
		case VISUAL_WALLS_GET_VISUAL_WALLS:
		case VISUAL_WALLS_GET_VISUAL_WALL:
		case VISUAL_WALLS_CREATE_VISUAL_WALL:
		case VISUAL_WALLS_UPDATE_VISUAL_WALL:
		case VISUAL_WALLS_DELETE_VISUAL_WALL:
			return {
				...state,
				pending: true,
				status: null,
			}
		case VISUAL_WALLS_GET_VISUAL_WALLS_SUCCESS:
			return {
				pending: false,
				list: action.list,
			}
		case VISUAL_WALLS_GET_VISUAL_WALL_SUCCESS:
			return {
				pending: false,
				visualWall: action.visualWall,
			}
		case VISUAL_WALLS_CREATE_VISUAL_WALL_SUCCESS:
			return {
				pending: false,
				newId: action.newId,
				status: 'created',
			}
		case VISUAL_WALLS_UPDATE_VISUAL_WALL_SUCCESS:
			return {
				...state,
				pending: false,
				status: 'updated',
			}
		case VISUAL_WALLS_DELETE_VISUAL_WALL_SUCCESS:
			return {
				...state,
				pending: false,
				status: 'deleted',
			}
		case VISUAL_WALLS_GET_VISUAL_WALLS_FAILURE:
		case VISUAL_WALLS_GET_VISUAL_WALL_FAILURE:
		case VISUAL_WALLS_CREATE_VISUAL_WALL_FAILURE:
		case VISUAL_WALLS_UPDATE_VISUAL_WALL_FAILURE:
		case VISUAL_WALLS_DELETE_VISUAL_WALL_FAILURE:
			return {
				...state,
				pending: false,
			}
		case VISUAL_WALLS_CLEAR_STATE:
			return {
				...initialState,
			}
		default:
			return state
	}
}

export default visualWallsReducer
