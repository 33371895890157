import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GButton, GCard, GIcon, GMessage, GSecured, GTable, GTitleBar, isEmpty } from '@alatimier/genesis-uic'
import { deleteSegments, getSegments } from '../../store/segments/segmentsActions'
import { GBadge } from '@alatimier/genesis-uic/dist/genesis-uic.min'

const SegmentList = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const segments = useSelector((state) => state.segments.list)
	const pending = useSelector((state) => state.segments.pending)
	const status = useSelector((state) => state.segments.status)

	useEffect(() => fetchSegments(), [])
	useEffect(() => handleStatusChange(), [status])

	const fetchSegments = () => {
		dispatch(getSegments())
	}

	const handleStatusChange = () => {
		if (status === 'deleted') {
			dispatch(getSegments())
		}
	}

	const handleEdit = (id) => {
		navigate(`/segments/${id}`)
	}

	const handleDelete = (ids) => {
		dispatch(deleteSegments(ids))
	}

	const columns = [
		{ itemProp: 'name', label: t('segments.table.name') },
		{ itemProp: 'gender', label: t('segments.table.gender') },
		{ itemProp: 'minAge', label: t('segments.table.minAge') },
		{ itemProp: 'maxAge', label: t('segments.table.maxAge') },
		{ itemProp: 'clothes', label: t('segments.table.clothes'), width: '15%', permission: 'SEGMENT_EDIT_CLOTHES' },
	]

	const rowActions = [
		{
			title: t('action.edit'),
			icon: 'fa-edit',
			fn: (item) => handleEdit(item.id),
			permission: 'SEGMENT_EDIT',
		},
		{
			title: t('action.delete'),
			icon: 'fa-trash',
			fn: (item) => handleDelete([item.id]),
			confirmMessage: t('action.delete.confirm'),
			permission: 'SEGMENT_EDIT',
		},
	]

	const globalActions = [
		{
			icon: 'fa-trash',
			title: t('action.delete'),
			fn: (selected) => handleDelete(selected.map((item) => item.id)),
			confirmMessage: t('action.delete.confirm'),
			permission: 'SEGMENT_EDIT',
		},
	]

	const richCellContent = {
		gender: (item) => (
			<td>
				{item.genders?.map((gender, index) => (
					<GBadge key={index} label={t(`gender.${gender}`)} className="me-1" />
				))}
			</td>
		),
		minAge: (item) => <td>{item.minAge ?? '-'}</td>,
		maxAge: (item) => <td>{item.maxAge ?? '-'}</td>,
		clothes: (item) => (
			<td>
				{item.clothes?.map((clothing, index) => (
					<GBadge key={index} label={t(`clothes.${clothing}`)} className="me-1" />
				))}
			</td>
		),
	}

	const renderPending = () => (
		<div className="text-center">
			<GIcon name="fa-spinner" spin />
		</div>
	)

	const renderSegments = () => {
		if (isEmpty(segments)) {
			return <GMessage value={t('segments.list.empty')} />
		}
		return (
			<GTable
				id="segmentsList"
				items={segments}
				columns={columns}
				hover
				columnSorter
				columnFilter
				rowActions={rowActions}
				globalActions={globalActions}
				pagination
				itemsPerPage={15}
				richCellContent={richCellContent}
			/>
		)
	}

	return (
		<>
			<GTitleBar>{t('segments.title')}</GTitleBar>
			<GCard title={t('segments.list.title')} row>
				{pending && isEmpty(segments) ? renderPending() : renderSegments()}
				<GSecured permission="SEGMENT_EDIT">
					<Link to="/segments/new">
						<GButton label={t('segments.action.create')} location={['page', 'page-top']} />
					</Link>
				</GSecured>
			</GCard>
		</>
	)
}

export default SegmentList
