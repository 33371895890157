import { apiClientService as client, setErrors, setSuccess } from '@alatimier/genesis-uic'
import {
	VISUAL_WALLS_CLEAR_STATE,
	VISUAL_WALLS_CREATE_VISUAL_WALL,
	VISUAL_WALLS_CREATE_VISUAL_WALL_FAILURE,
	VISUAL_WALLS_CREATE_VISUAL_WALL_SUCCESS,
	VISUAL_WALLS_DELETE_VISUAL_WALL,
	VISUAL_WALLS_DELETE_VISUAL_WALL_FAILURE,
	VISUAL_WALLS_DELETE_VISUAL_WALL_SUCCESS,
	VISUAL_WALLS_GET_VISUAL_WALL,
	VISUAL_WALLS_GET_VISUAL_WALL_FAILURE,
	VISUAL_WALLS_GET_VISUAL_WALL_SUCCESS,
	VISUAL_WALLS_GET_VISUAL_WALLS,
	VISUAL_WALLS_GET_VISUAL_WALLS_FAILURE,
	VISUAL_WALLS_GET_VISUAL_WALLS_SUCCESS,
	VISUAL_WALLS_UPDATE_VISUAL_WALL,
	VISUAL_WALLS_UPDATE_VISUAL_WALL_FAILURE,
	VISUAL_WALLS_UPDATE_VISUAL_WALL_SUCCESS,
} from './visualWallsTypes'

const visualWallsEndPoint = '/api/admin/v1/visualwalls'

export const getVisualWalls = () => {
	return (dispatch) => {
		dispatch({ type: VISUAL_WALLS_GET_VISUAL_WALLS })
		return client.get(visualWallsEndPoint).then(async (res) => {
			if (res.ok) {
				const data = await res.json()
				dispatch({
					type: VISUAL_WALLS_GET_VISUAL_WALLS_SUCCESS,
					list: data,
				})
			} else {
				dispatch({
					type: VISUAL_WALLS_GET_VISUAL_WALLS_FAILURE,
				})
			}
		})
	}
}

export const getVisualWall = (id) => {
	return (dispatch) => {
		dispatch({ type: VISUAL_WALLS_GET_VISUAL_WALL })
		return client.get(`${visualWallsEndPoint}/${id}`).then(async (res) => {
			if (res.ok) {
				const data = await res.json()
				dispatch({
					type: VISUAL_WALLS_GET_VISUAL_WALL_SUCCESS,
					visualWall: data,
				})
			} else {
				dispatch({
					type: VISUAL_WALLS_GET_VISUAL_WALL_FAILURE,
				})
			}
		})
	}
}

export const createVisualWall = (organizationId, name, visualIdLines, tags) => {
	return (dispatch) => {
		dispatch({ type: VISUAL_WALLS_CREATE_VISUAL_WALL })
		return client
			.post(visualWallsEndPoint, {
				organizationId,
				name,
				visualIdLines,
				tags,
			})
			.then(async (res) => {
				const data = await res.json()
				if (res.ok) {
					dispatch(setSuccess('action.save.success'))
					dispatch({
						type: VISUAL_WALLS_CREATE_VISUAL_WALL_SUCCESS,
						newId: data.id,
					})
				} else {
					dispatch(setErrors(data.errors, data.validationErrors))
					dispatch({
						type: VISUAL_WALLS_CREATE_VISUAL_WALL_FAILURE,
					})
				}
			})
	}
}

export const updateVisualWall = (id, organizationId, name, visualIdLines, tags) => {
	return (dispatch) => {
		dispatch({ type: VISUAL_WALLS_UPDATE_VISUAL_WALL })
		return client
			.put(`${visualWallsEndPoint}/${id}`, {
				organizationId,
				name,
				visualIdLines,
				tags,
			})
			.then(async (res) => {
				if (res.ok) {
					dispatch(setSuccess('action.save.success'))
					dispatch({ type: VISUAL_WALLS_UPDATE_VISUAL_WALL_SUCCESS })
				} else {
					const data = await res.json()
					dispatch(setErrors(data.errors, data.validationErrors))
					dispatch({ type: VISUAL_WALLS_UPDATE_VISUAL_WALL_FAILURE })
				}
			})
	}
}

export const deleteVisualWalls = (ids) => {
	return (dispatch) => {
		dispatch({ type: VISUAL_WALLS_DELETE_VISUAL_WALL })
		const promises = ids.map((id) => client.del(`${visualWallsEndPoint}/${id}`))
		return Promise.all(promises).then(async (results) => {
			if (results.every((r) => r.ok)) {
				dispatch(setSuccess('action.delete.success'))
			} else {
				const data = await results.find((r) => !r.ok).json()
				dispatch(setErrors(data.errors, data.validationErrors))
				dispatch({ type: VISUAL_WALLS_DELETE_VISUAL_WALL_FAILURE })
			}
			if (results.some((r) => r.ok)) dispatch({ type: VISUAL_WALLS_DELETE_VISUAL_WALL_SUCCESS })
		})
	}
}

export const clearState = () => {
	return {
		type: VISUAL_WALLS_CLEAR_STATE,
	}
}
