export const CAMPAIGNS_GET_CAMPAIGNS = '@@campaigns/GET_CAMPAIGNS'
export const CAMPAIGNS_GET_CAMPAIGNS_SUCCESS = '@@campaigns/GET_CAMPAIGNS_SUCCESS'
export const CAMPAIGNS_GET_CAMPAIGNS_FAILURE = '@@campaigns/GET_CAMPAIGNS_FAILURE'

export const CAMPAIGNS_GET_CAMPAIGN = '@@campaigns/GET_CAMPAIGN'
export const CAMPAIGNS_GET_CAMPAIGN_SUCCESS = '@@campaigns/GET_CAMPAIGN_SUCCESS'
export const CAMPAIGNS_GET_CAMPAIGN_FAILURE = '@@campaigns/GET_CAMPAIGN_FAILURE'

export const CAMPAIGNS_CREATE_CAMPAIGN = '@@campaigns/CREATE_CAMPAIGN'
export const CAMPAIGNS_CREATE_CAMPAIGN_SUCCESS = '@@campaigns/CREATE_CAMPAIGN_SUCCESS'
export const CAMPAIGNS_CREATE_CAMPAIGN_FAILURE = '@@campaigns/CREATE_CAMPAIGN_FAILURE'

export const CAMPAIGNS_UPDATE_CAMPAIGN = '@@campaigns/UPDATE_CAMPAIGN'
export const CAMPAIGNS_UPDATE_CAMPAIGN_SUCCESS = '@@campaigns/UPDATE_CAMPAIGN_SUCCESS'
export const CAMPAIGNS_UPDATE_CAMPAIGN_FAILURE = '@@campaigns/UPDATE_CAMPAIGN_FAILURE'

export const CAMPAIGNS_UPDATE_CAMPAIGN_STATUS = '@@campaigns/UPDATE_CAMPAIGN_STATUS'
export const CAMPAIGNS_UPDATE_CAMPAIGN_STATUS_SUCCESS = '@@campaigns/UPDATE_CAMPAIGN_STATUS_SUCCESS'
export const CAMPAIGNS_UPDATE_CAMPAIGN_STATUS_FAILURE = '@@campaigns/UPDATE_CAMPAIGN_STATUS_FAILURE'

export const CAMPAIGNS_DELETE_CAMPAIGN = '@@campaigns/DELETE_CAMPAIGN'
export const CAMPAIGNS_DELETE_CAMPAIGN_SUCCESS = '@@campaigns/DELETE_CAMPAIGN_SUCCESS'
export const CAMPAIGNS_DELETE_CAMPAIGN_FAILURE = '@@campaigns/DELETE_CAMPAIGN_FAILURE'

export const CAMPAIGNS_GET_STATUSES = '@@campaigns/GET_STATUSES'
export const CAMPAIGNS_GET_STATUSES_SUCCESS = '@@campaigns/GET_STATUSES_SUCCESS'
export const CAMPAIGNS_GET_STATUSES_FAILURE = '@@campaigns/GET_STATUSES_FAILURE'

export const CAMPAIGNS_CLEAR_STATE = '@@campaigns/CLEAR_STATE'
