import { apiClientService as client } from '@alatimier/genesis-uic'
import {
	ADVERTISEMENTS_CLEAR_STATE,
	ADVERTISEMENTS_GET_ADVERTISEMENTS,
	ADVERTISEMENTS_GET_ADVERTISEMENTS_FAILURE,
	ADVERTISEMENTS_GET_ADVERTISEMENTS_SUCCESS,
} from './advertisementsTypes'

const devicesEndPoint = '/api/admin/v1/devices'

export const getAdvertisements = (deviceId) => {
	return (dispatch) => {
		dispatch({ type: ADVERTISEMENTS_GET_ADVERTISEMENTS })
		return client.get(`${devicesEndPoint}/${deviceId}/advertisements`).then(async (res) => {
			if (res.ok) {
				const data = await res.json()
				dispatch({
					type: ADVERTISEMENTS_GET_ADVERTISEMENTS_SUCCESS,
					list: data,
				})
			} else {
				dispatch({
					type: ADVERTISEMENTS_GET_ADVERTISEMENTS_FAILURE,
				})
			}
		})
	}
}

export const clearState = () => {
	return {
		type: ADVERTISEMENTS_CLEAR_STATE,
	}
}
