import {
	ORGANIZATION_GET_CAMPAIGNS,
	ORGANIZATION_GET_CAMPAIGNS_FAILURE,
	ORGANIZATION_GET_CAMPAIGNS_SUCCESS,
	ORGANIZATION_GET_DEVICES,
	ORGANIZATION_GET_DEVICES_FAILURE,
	ORGANIZATION_GET_DEVICES_SUCCESS,
	ORGANIZATION_GET_SEGMENTS,
	ORGANIZATION_GET_SEGMENTS_FAILURE,
	ORGANIZATION_GET_SEGMENTS_SUCCESS,
	ORGANIZATION_GET_SPACES,
	ORGANIZATION_GET_SPACES_FAILURE,
	ORGANIZATION_GET_SPACES_SUCCESS,
	ORGANIZATION_GET_VISUALS,
	ORGANIZATION_GET_VISUALS_FAILURE,
	ORGANIZATION_GET_VISUALS_SUCCESS,
} from './organizationsTypes'

const initialState = {
	pending: false,
	spaces: {},
	devices: {},
	visuals: {},
	segments: {},
	campaigns: {},
}

const organizationsReducer = (state = initialState, action) => {
	switch (action.type) {
		case ORGANIZATION_GET_SPACES:
		case ORGANIZATION_GET_SEGMENTS:
		case ORGANIZATION_GET_VISUALS:
		case ORGANIZATION_GET_DEVICES:
		case ORGANIZATION_GET_CAMPAIGNS:
			return {
				...state,
				pending: true,
			}
		case ORGANIZATION_GET_SPACES_SUCCESS:
			return {
				...state,
				pending: false,
				spaces: {
					...state.spaces,
					[action.organizationId]: action.list,
				},
			}
		case ORGANIZATION_GET_SEGMENTS_SUCCESS:
			return {
				...state,
				pending: false,
				segments: {
					...state.segments,
					[action.organizationId]: action.list,
				},
			}
		case ORGANIZATION_GET_VISUALS_SUCCESS:
			return {
				...state,
				pending: false,
				visuals: {
					...state.visuals,
					[action.organizationId]: action.list,
				},
			}
		case ORGANIZATION_GET_DEVICES_SUCCESS:
			return {
				...state,
				pending: false,
				devices: {
					...state.devices,
					[action.organizationId]: action.list,
				},
			}
		case ORGANIZATION_GET_CAMPAIGNS_SUCCESS:
			return {
				...state,
				pending: false,
				campaigns: {
					...state.campaigns,
					[action.organizationId]: action.list,
				},
			}
		case ORGANIZATION_GET_SPACES_FAILURE:
		case ORGANIZATION_GET_SEGMENTS_FAILURE:
		case ORGANIZATION_GET_VISUALS_FAILURE:
		case ORGANIZATION_GET_DEVICES_FAILURE:
		case ORGANIZATION_GET_CAMPAIGNS_FAILURE:
			return {
				...state,
				pending: false,
			}
		default:
			return state
	}
}

export default organizationsReducer
