import { GButton, GInput, isEmpty } from '@alatimier/genesis-uic'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { timeSlotShape } from './TimeSlotTypes'

const TimeSlotForm = (props) => {
	const { t } = useTranslation()

	const isDarkMode = useSelector((store) => store.g_display.darkMode)

	const [start, setStart] = useState(props.timeSlot?.start)
	const [end, setEnd] = useState(props.timeSlot?.end)
	const [error, setError] = useState(null)

	const validate = () => {
		if (isEmpty(start) || isEmpty(end)) {
			setError(t('campaign.error.timeslots'))
			return false
		} else if (start >= end) {
			setError(t('campaign.error.timeslots.order'))
			return false
		} else {
			setError(null)
			return true
		}
	}

	useEffect(() => {
		props.onChange && props.onChange({ start, end }, props.index)
	}, [start, end])

	const handleSubmit = (e) => {
		e.preventDefault()
		if (validate()) {
			props.onAdd && props.onAdd({ start, end })
			setStart('')
			setEnd('')
			e.target.querySelector('input').focus()
		}
	}

	const isEdition = !!props.timeSlot

	return (
		<>
			<div className="text-danger mb-1">{error}</div>
			<form className="row mb-3" onSubmit={handleSubmit}>
				<div className="col my-auto me-3">
					<div className="row align-items-center">
						<label htmlFor={`startHour${props.index ?? ''}`} className="col-auto m-0 text-right">
							{t('forms.timeSlotFrom')}
						</label>
						<GInput
							id={`startHour${props.index ?? ''}`}
							type="time"
							value={props.timeSlot ? props.timeSlot.start : start}
							onChange={setStart}
							onBlur={isEdition ? validate : undefined}
							inputClassName="col"
							simple
							required={isEdition}
						/>
					</div>
				</div>
				<div className="col my-auto me-3">
					<div className="row align-items-center">
						<label htmlFor={`endHour${props.index ?? ''}`} className="col-auto m-0 text-right">
							{t('forms.timeSlotTo')}
						</label>
						<GInput
							id={`endHour${props.index ?? ''}`}
							type="time"
							value={props.timeSlot ? props.timeSlot.end : end}
							onChange={setEnd}
							onBlur={isEdition ? validate : undefined}
							inputClassName="col"
							simple
							required={isEdition}
						/>
					</div>
				</div>
				<div className="col-1 d-flex justify-content-center">
					{isEdition ? (
						<GButton icon="fa-minus" title={t('action.remove')} onClick={props.onRemove} color={isDarkMode ? 'dark' : 'light'} />
					) : (
						<GButton type="submit" icon="fa-plus" title={t('action.add')} color={isDarkMode ? 'dark' : 'light'} className="mx-1 my-1" />
					)}
				</div>
			</form>
		</>
	)
}

TimeSlotForm.propTypes = {
	timeSlot: timeSlotShape,
	index: PropTypes.number,
	onAdd: PropTypes.func,
	onChange: PropTypes.func,
	onRemove: PropTypes.func,
}

export default TimeSlotForm
