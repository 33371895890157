import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { editorContext } from '../../editorContext'
import { initCanvas } from '../../utils/init'
import ContextMenu from './ContextMenu/ContextMenu'

const Workarea = (props) => {
	const { t } = useTranslation()

	const [editorState, dispatchEditorState] = useContext(editorContext)

	const [menu, setMenu] = useState(null)
	const textRef = useRef(null)
	const panning = useRef({ panning: false, lastX: null, lastY: null })

	useLayoutEffect(() => {
		initCanvas(props.workareaRef.current, props.canvasRef.current, textRef.current, dispatchEditorState)
		props.canvasRef.current.querySelector('title').textContent = t('editor.hint.canvas')
	}, [])
	useEffect(() => {
		const hideMenu = () => setMenu(null)
		document.addEventListener('click', hideMenu)
		return () => document.removeEventListener('click', hideMenu)
	}, [])
	useEffect(() => stopPanningOutsideCanvas(), [])
	useEffect(() => updateTextInput(), [editorState.selectedElement])

	const displayContextMenu = (e) => {
		e.preventDefault()
		setMenu({ open: true, x: e.clientX, y: e.clientY })
	}

	const startPanning = (e) => {
		if (editorState.canvas.getMode() === 'panning') {
			panning.current = { panning: true, lastX: e.clientX, lastY: e.clientY }
			return false
		}
		return true
	}

	const panCanvas = (e) => {
		if (panning.current.panning === false) return true
		props.workareaRef.current.scrollLeft -= e.clientX - panning.current.lastX
		props.workareaRef.current.scrollTop -= e.clientY - panning.current.lastY
		panning.current = { panning: true, lastX: e.clientX, lastY: e.clientY }
		if (e.type === 'mouseup') panning.current.panning = false
		return false
	}

	const stopPanningOutsideCanvas = () => {
		window.addEventListener('mouseup', () => {
			panning.current.panning = false
		})
	}

	const setText = (e) => {
		editorState.canvas.setTextContent(e.target.value)
	}

	const updateTextInput = () => {
		let elem = editorState.selectedElement
		if (elem && elem.parentNode && elem.tagName === 'text') {
			textRef.current.value = elem.textContent
		}
	}

	return (
		<div id="workarea" ref={props.workareaRef}>
			<div
				id="svgcanvas"
				ref={props.canvasRef}
				onMouseDown={startPanning}
				onMouseUp={panCanvas}
				onMouseMove={panCanvas}
				onContextMenu={displayContextMenu}
				className={`mode-${editorState.canvas?.getMode()}`}
			/>
			{menu && <ContextMenu top={menu.y} left={menu.x} />}
			<input ref={textRef} id="text" type="text" size="35" style={{ position: 'absolute', left: '-9999px' }} onKeyUp={setText} onInput={setText} />
		</div>
	)
}

const refShape = PropTypes.shape({ current: PropTypes.object })
Workarea.propTypes = {
	workareaRef: refShape,
	canvasRef: refShape,
}

export default Workarea
