import React, { Suspense } from 'react'
import { render } from 'react-dom'
import store from './store/store'
import { Provider } from 'react-redux'
import App from './containers/App'
import { GLoading, i18n } from '@alatimier/genesis-uic'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'

const mainContainer = document.getElementById('root')

render(
	<Provider store={store}>
		<BrowserRouter>
			<Suspense fallback={<GLoading />}>
				<I18nextProvider i18n={i18n}>
					<App />
				</I18nextProvider>
			</Suspense>
		</BrowserRouter>
	</Provider>,
	mainContainer
)
