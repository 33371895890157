import { apiClientService as client, setErrors, setSuccess } from '@alatimier/genesis-uic'
import {
	LAB_CREATE_CAMPAIGN,
	LAB_CREATE_CAMPAIGN_SUCCESS,
	LAB_CREATE_CAMPAIGN_FAILURE,
	LAB_GET_CAMPAIGNS,
	LAB_GET_CAMPAIGNS_SUCCESS,
	LAB_GET_CAMPAIGNS_FAILURE,
} from './labTypes'

const labcampaignsEndPoint = '/api/admin/v1/lab/campaigns'

export const getLabCampaigns = () => {
	return (dispatch) => {
		dispatch({ type: LAB_GET_CAMPAIGNS })
		return client.get(labcampaignsEndPoint).then(async (res) => {
			if (res.ok) {
				const data = await res.json()
				dispatch({
					type: LAB_GET_CAMPAIGNS_SUCCESS,
					list: data,
				})
			} else {
				dispatch({
					type: LAB_GET_CAMPAIGNS_FAILURE,
				})
			}
		})
	}
}

export const createLabCampaign = (organizationId, name, aspectRatioId, resolutionId, offerId) => {
	return (dispatch) => {
		dispatch({ type: LAB_CREATE_CAMPAIGN })
		return client.post(labcampaignsEndPoint, { organizationId, name, aspectRatioId, resolutionId, offerId }).then(async (res) => {
			const data = await res.json()
			if (res.ok) {
				dispatch(setSuccess('action.save.success'))
				dispatch({
					type: LAB_CREATE_CAMPAIGN_SUCCESS,
					newId: data.id,
					url: data.dashboardUrl,
				})
			} else {
				dispatch(setErrors(data.errors, data.validationErrors))
				dispatch({
					type: LAB_CREATE_CAMPAIGN_FAILURE,
				})
			}
		})
	}
}
