import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GBadge, GButton, GCard, GIcon, GImage, GMessage, GSecured, GTable, GTitleBar, isEmpty } from '@alatimier/genesis-uic'
import { deleteVisuals, getVisuals } from '../../store/visuals/visualsActions'

const VisualList = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const visuals = useSelector((state) => state.visuals.list)
	const pending = useSelector((state) => state.visuals.pending)
	const status = useSelector((state) => state.visuals.status)

	useEffect(() => fetchVisuals(), [])
	useEffect(() => handleStatusChange(), [status])

	const fetchVisuals = () => {
		dispatch(getVisuals())
	}

	const handleStatusChange = () => {
		if (status === 'deleted') {
			dispatch(getVisuals())
		}
	}

	const handleEdit = (id) => {
		navigate(`/visuals/${id}`)
	}

	const handleDelete = (ids) => {
		dispatch(deleteVisuals(ids))
	}

	const columns = [
		{ itemProp: 'name', label: t('visuals.table.name'), width: '25%' },
		{ itemProp: 'tags', label: t('tags.table.label'), width: '25%' },
		{ itemProp: 'thumbnail', label: t('visuals.table.thumbnail'), width: '25%', filter: false, sorter: false },
	]

	const rowActions = [
		{
			title: t('action.edit'),
			icon: 'fa-edit',
			fn: (item) => handleEdit(item.id),
			permission: 'VISUALS_EDIT',
		},
		{
			title: t('action.delete'),
			icon: 'fa-trash',
			fn: (item) => handleDelete([item.id]),
			confirmMessage: t('action.delete.confirm'),
			permission: 'VISUALS_EDIT',
		},
	]

	const globalActions = [
		{
			icon: 'fa-trash',
			title: t('action.delete'),
			fn: (selected) => handleDelete(selected.map((item) => item.id)),
			confirmMessage: t('action.delete.confirm'),
			permission: 'VISUALS_EDIT',
		},
	]

	const richCellContent = {
		thumbnail: (item) => (
			<td>
				<GImage key={item.id} src={item.fileThumbnailUri} maxHeight="100px" maxWidth="100px" />
			</td>
		),
		tags: (item) => (
			<td>
				{item.tags.map((tag, index) => (
					<GBadge key={index} label={tag} className="me-1" />
				))}
			</td>
		),
	}

	const renderPending = () => (
		<div className="text-center">
			<GIcon name="fa-spinner" spin />
		</div>
	)

	const renderVisuals = () => {
		if (isEmpty(visuals)) {
			return <GMessage value={t('visuals.list.empty')} />
		}
		return (
			<GTable
				id="visuals"
				items={visuals}
				columns={columns}
				hover
				columnSorter
				columnFilter
				tableFilter
				rowActions={rowActions}
				globalActions={globalActions}
				pagination
				itemsPerPage={15}
				richCellContent={richCellContent}
			/>
		)
	}

	return (
		<>
			<GTitleBar>{t('visuals.title')}</GTitleBar>
			<GCard title={t('visuals.list.title')} row>
				{pending && isEmpty(visuals) ? renderPending() : renderVisuals()}
				<GSecured permission="LAB_CAMPAIGN_EDIT">
					<Link to="/lab/campaigns/new">
						<GButton label={t('lab.action.create')} location={['page', 'page-top']} />
					</Link>
				</GSecured>
				<GSecured permission="VISUALS_EDIT">
					<Link to="/visuals/new">
						<GButton label={t('visuals.action.create')} location={['page', 'page-top']} />
					</Link>
				</GSecured>
			</GCard>
		</>
	)
}

export default VisualList
