import React, { useEffect, useState } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import './App.scss'
import {
	GAccount,
	GCacheList,
	GHeader,
	GLanguageEdit,
	GLanguageList,
	GLayout,
	GLogin,
	GMenu,
	GMenuGroup,
	GMenuItem,
	GNotFound,
	GOrganizations,
	GOrganizationSelect,
	GRecoverPassword,
	GResetPassword,
	GRoleEdit,
	GRoleList,
	GUserEdit,
	GUserList,
} from '@alatimier/genesis-uic'
import CampaignList from './campaigns/CampaignList'
import CampaignEdit from './campaigns/CampaignEdit'
import VisualList from './visuals/VisualList'
import VisualEdit from './visuals/VisualEdit'
import SegmentList from './segmentation/SegmentList'
import SegmentEdit from './segmentation/SegmentEdit'
import DeviceList from './devices/DeviceList'
import DeviceEdit from './devices/DeviceEdit'
import Dashboard from './dashboard/Dashboard'
import Export from './export/Export'
import Editor from './editor/Editor'
import Sales from './sales/Sales'
import ObjectiveList from './objectives/ObjectiveList'
import ObjectiveEdit from './objectives/ObjectiveEdit'
import VisualWallList from './visualwalls/VisualWallList'
import VisualWallEdit from './visualwalls/VisualWallEdit'
import LabCampaignEdit from './lab/LabCampaignEdit'
import LabCampaignList from './lab/LabCampaignList'

function App() {
	const { t } = useTranslation()

	const [title, setTitle] = useState('')

	const organizations = useSelector((state) => state.g_organizations.list)

	useEffect(() => {
		if (organizations.length > 0) {
			const appTitle = organizations[0].attributes?.['app-title']
			setTitle(appTitle ? appTitle : t('synapse.title'))
		}
	}, [organizations])

	const header = <GHeader indexLink="/" userAccountLink="/account" />

	const menu = (
		<GMenu title={title} indexLink="/" minimizedIcon="fa-home">
			<GMenuGroup title={t('menu.metrics.title')}>
				<GMenuItem title={t('dashboard.views.title')} permission="STATISTICS_VIEWS" icon="fa-eye" to="/dashboard/views" />
				<GMenuItem title={t('dashboard.audience.title')} permission="STATISTICS_AUDIENCE" icon="fa-street-view" to="/dashboard/audience" />
				<GMenuItem title={t('export.title')} permission="STATISTICS_EXPORT" icon="fa-file-download" to="/export" />
				<GMenuItem title={t('sales.title')} permission="SALES_EDIT" icon="fa-cash-register" to="/sales" />
				<GMenuItem title={t('objectives.title')} permission="OBJECTIVES_READ" icon="fa-chart-line" to="/objectives" />
			</GMenuGroup>
			<GMenuGroup title={t('menu.settings.title')}>
				<GMenuItem title={t('visuals.title')} permission="VISUALS_READ" icon="fa-photo-video" to="/visuals" />
				<GMenuItem title={t('visualwalls.title')} permission="VISUAL_WALL_READ" icon="fa-tv" to="/visualwalls" />
				<GMenuItem title={t('editor.title')} permission="VISUALS_EDIT" icon="fa-edit" to="/editor" />
				<GMenuItem title={t('segments.title')} permission="SEGMENT_READ" icon="fa-bezier-curve" to="/segments" />
				<GMenuItem title={t('campaigns.title')} permission="CAMPAIGN_READ" icon="fa-bullhorn" to="/campaigns" />
				<GMenuItem title={t('devices.title')} permission="DEVICE_READ" icon="fa-mobile-alt" to="/devices" />
				<GMenuItem title={t('lab.title')} permission="LAB_CAMPAIGN_READ" icon="fa-video-camera" to="/lab/campaigns" />
			</GMenuGroup>
			<GMenuGroup title={t('menu.system.title')}>
				<GMenuItem title={t('organizations.title')} permission="ORGANIZATION_EDIT" icon="fa-building" to="/organizations" />
				<GMenuItem title={t('roles.title')} permission="ROLE_EDIT" icon="fa-key" to="/roles" />
				<GMenuItem title={t('users.title')} permission="USER_EDIT" icon="fa-users" to="/users" />
				<GMenuItem title={t('languages.title')} permission="LANGUAGE_EDIT" icon="fa-globe" to="/languages" />
				<GMenuItem title={t('caches.title')} permission="CACHE_READ" icon="fa-server" to="/caches" />
			</GMenuGroup>
		</GMenu>
	)

	const genesisLayout = (
		<GLayout header={header} menu={menu} authenticated>
			<GOrganizationSelect id="organizations" maxLevel={-1} />
			<Outlet />
		</GLayout>
	)

	return (
		<>
			<Routes>
				<Route path="/login" element={<GLogin successTarget="/" recoverPasswordTarget="/recover-password" motd={t('login.motd')} />} />
				<Route path="/recover-password" element={<GRecoverPassword />} />
				<Route path="/reset-password" element={<GResetPassword />} />
				<Route path="/" element={genesisLayout}>
					<Route index element={<Dashboard />} />
					<Route path="account" element={<GAccount />} />
					<Route path="caches" element={<GCacheList />} />
					<Route path="campaigns" element={<CampaignList />} />
					<Route path="campaigns/:id" element={<CampaignEdit />} />
					<Route path="dashboard" element={<Dashboard />} />
					<Route path="dashboard/:dashboard" element={<Dashboard />} />
					<Route path="devices" element={<DeviceList />} />
					<Route path="devices/:id" element={<DeviceEdit />} />
					<Route path="editor" element={<Editor />} />
					<Route path="export" element={<Export />} />
					<Route path="languages" element={<GLanguageList />} />
					<Route path="languages/:id" element={<GLanguageEdit />} />
					<Route path="objectives" element={<ObjectiveList />} />
					<Route path="objectives/:id" element={<ObjectiveEdit />} />
					<Route path="organizations" element={<GOrganizations />} />
					<Route path="roles" element={<GRoleList />} />
					<Route path="roles/:id" element={<GRoleEdit />} />
					<Route path="sales" element={<Sales />} />
					<Route path="segments" element={<SegmentList />} />
					<Route path="segments/:id" element={<SegmentEdit />} />
					<Route path="users" element={<GUserList />} />
					<Route path="users/:id" element={<GUserEdit />} />
					<Route path="visuals" element={<VisualList />} />
					<Route path="visuals/:id" element={<VisualEdit />} />
					<Route path="visualwalls" element={<VisualWallList />} />
					<Route path="visualwalls/:id" element={<VisualWallEdit />} />
					<Route path="lab/campaigns" element={<LabCampaignList />} />
					<Route path="lab/campaigns/:id" element={<LabCampaignEdit />} />
				</Route>
				<Route element={<GNotFound />} />
			</Routes>
		</>
	)
}

export default App
