import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GBadge, GButton, GCard, GIcon, GMessage, GOrganizationSelect, GSecured, GTable, GTitleBar, isEmpty } from '@alatimier/genesis-uic'
import { deleteVisualWalls, getVisualWalls } from '../../store/visualwalls/visualWallsActions'

const VisualWallList = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const visualWalls = useSelector((state) => state.visualWalls.list)
	const pending = useSelector((state) => state.visualWalls.pending)
	const status = useSelector((state) => state.visualWalls.status)

	useEffect(() => fetchVisualWalls(), [])
	useEffect(() => handleStatusChange(), [status])

	const fetchVisualWalls = () => {
		dispatch(getVisualWalls())
	}

	const handleStatusChange = () => {
		if (status === 'deleted') {
			dispatch(getVisualWalls())
		}
	}

	const handleEdit = (id) => {
		navigate(`/visualwalls/${id}`)
	}

	const handleDelete = (ids) => {
		dispatch(deleteVisualWalls(ids))
	}

	const columns = [
		{ itemProp: 'name', label: t('visualwalls.table.name') },
		{ itemProp: 'tags', label: t('tags.table.label'), width: '15%' },
	]

	const rowActions = [
		{
			title: t('action.edit'),
			icon: 'fa-edit',
			fn: (item) => handleEdit(item.id),
			permission: 'VISUAL_WALL_EDIT',
		},
		{
			title: t('action.delete'),
			icon: 'fa-trash',
			fn: (item) => handleDelete([item.id]),
			confirmMessage: t('action.delete.confirm'),
			permission: 'VISUAL_WALL_EDIT',
		},
	]

	const globalActions = [
		{
			icon: 'fa-trash',
			title: t('action.delete'),
			fn: (selected) => handleDelete(selected.map((item) => item.id)),
			confirmMessage: t('action.delete.confirm'),
			permission: 'VISUAL_WALL_EDIT',
		},
	]

	// Enriched visualWall attributes for display purpose, original value will still be used for filtering
	const richCellContent = {
		tags: (item) => (
			<td>
				{item.tags.map((tag, index) => (
					<GBadge key={index} label={tag} className="me-1" />
				))}
			</td>
		),
	}

	const renderPending = () => (
		<div className="text-center">
			<GIcon name="fa-spinner" spin />
		</div>
	)

	const renderVisualWalls = () => {
		if (isEmpty(visualWalls)) {
			return <GMessage value={t('visualwalls.list.empty')} />
		}
		return (
			<GTable
				id="visualWallList"
				items={visualWalls}
				columns={columns}
				hover
				columnSorter
				columnFilter
				tableFilter
				sortOn={{ column: 'name', state: 'asc' }}
				rowActions={rowActions}
				globalActions={globalActions}
				pagination
				itemsPerPage={15}
				richCellContent={richCellContent}
			/>
		)
	}

	return (
		<>
			<GOrganizationSelect id="organizations" maxLevel={-1} />
			<GTitleBar>{t('visualwalls.title')}</GTitleBar>
			<GCard title={t('visualwalls.list.title')} row>
				{pending && isEmpty(visualWalls) ? renderPending() : renderVisualWalls()}
				<GSecured permission="VISUAL_WALL_EDIT">
					<Link to="/visualwalls/new">
						<GButton label={t('visualwalls.action.create')} location={['page', 'page-top']} />
					</Link>
				</GSecured>
			</GCard>
		</>
	)
}

export default VisualWallList
