import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { GSelect } from '@alatimier/genesis-uic'
import { getAspectRatios } from '../../store/ratios/aspectRatiosActions'

const AspectRatioSelect = (props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [options, setOptions] = useState([])
	const [selectedOption, setSelectedOption] = useState()

	const aspectRatios = useSelector((state) => state.ratios.list)
	const aspectRatiosLoading = useSelector((state) => state.ratios.loading)

	useEffect(() => fetchAspectRatios(), [])
	useEffect(() => buildOptions(), [aspectRatios])
	useEffect(() => initSelected(), [props.value, options])

	const fetchAspectRatios = () => {
		dispatch(getAspectRatios())
	}

	const buildOptions = () => {
		if (aspectRatios) {
			setOptions(
				aspectRatios.map((r) => ({
					value: r.id,
					label: t(`aspectRatio.${r.id}`),
				}))
			)
		}
	}

	const initSelected = () => {
		if (props.value && options.length > 0) {
			setSelectedOption(options.filter((option) => props.value.toString() === option.value)[0])
		}
	}

	const handleChange = (selected) => {
		const initialValueNotYetComputed = props.value && !selectedOption
		if (selected && !initialValueNotYetComputed) {
			props.onChange(selected.value)
		}
	}

	return (
		<GSelect
			id={props.id}
			name={props.name}
			label={t('aspectRatio.form.label')}
			labelPosition={props.labelPosition}
			placeholder={props.placeholder}
			value={selectedOption}
			options={options}
			loading={aspectRatiosLoading}
			onChange={handleChange}
			onBlur={props.onBlur}
			error={props.error}
			required={props.required}
			disabled={props.disabled}
			className={props.className}
			selectClassName={props.selectClassName}
			simple={props.simple}
		/>
	)
}

AspectRatioSelect.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string,
	labelPosition: PropTypes.oneOf(['left', 'up']),
	placeholder: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func,
	error: PropTypes.string,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	selectClassName: PropTypes.string,
	simple: PropTypes.bool,
}

export default AspectRatioSelect
