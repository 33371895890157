export const getKeyHandlers = (shortcuts) => {
	const keyHandlers = {}
	shortcuts.forEach((shortcut) => {
		// Bind function to shortcut key
		if (shortcut.key) {
			if (Array.isArray(shortcut.key)) {
				shortcut.key.forEach((key) => {
					keyHandlers[key] = shortcut.fn
				})
			} else {
				keyHandlers[shortcut.key] = shortcut.fn
			}
		}
	})
	return keyHandlers
}
