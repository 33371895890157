import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import head from 'lodash/head'
import last from 'lodash/last'
import { ResponsiveLine } from '@nivo/line'
import { isEmpty, GMessage } from '@alatimier/genesis-uic'
import { darkerBackgroundTheme, darkModeTheme, objectiveColors } from '../../../utils/ChartUtils'
import { formatDate, getDateUnitAndFormat, getMarker, objectiveGraphContext } from '../utils'

const GeneralROIChart = memo((props) => {
	const { t } = useTranslation()

	const isDarkMode = useSelector((store) => store.g_display.darkMode)

	const { startDate, cumulatedData, measureEndDate } = props.data
	const initialCost = props.initialCost || 0
	const monthlyCost = props.monthlyCost || 0

	const dates = Object.keys(cumulatedData).sort()

	const { dateFormat } = getDateUnitAndFormat(startDate, measureEndDate, head(dates) ?? startDate, last(dates) ?? measureEndDate)

	const startDateObject = DateTime.fromISO(startDate)
	const lineData = [
		{
			id: 'measured',
			data: Object.entries(cumulatedData).map(([date, { benefit }]) => {
				const monthNumber = Math.floor(DateTime.fromISO(date).diff(startDateObject, 'month').months)
				return { x: date, y: benefit - (initialCost + monthlyCost * monthNumber) }
			}),
		},
	]

	const emptyData = isEmpty(lineData[0].data.filter(({ y }) => y))
	if (emptyData) {
		return <GMessage value={t('objectives.chart.noData')} className="mb-0" />
	}

	const dateTickStep = Math.ceil(dates.length / 18)

	const formatValue = (value) => `${Number(value).toLocaleString(undefined, { maximumFractionDigits: 2 })}${t('objectives.currency')}`

	return (
		<div data-testid="general-roi-chart" style={{ height: props.context === objectiveGraphContext.LIST ? '300px' : '400px', width: '100%' }}>
			<ResponsiveLine
				data={lineData}
				margin={{ top: 20, right: 30, bottom: 25, left: 70 }}
				xFormat={formatDate(dateFormat)}
				yFormat={formatValue}
				yScale={{
					type: 'linear',
					min: Math.min(0, lineData[0].data[0].y),
					max: props.target ? Math.max(props.target, last(lineData[0].data).y) : 'auto',
				}}
				axisLeft={{
					legend: `${t('objectives.chart.roi')} (€)`,
					legendPosition: 'middle',
					legendOffset: -65,
				}}
				axisBottom={{
					format: (date) => dates.indexOf(date) % dateTickStep === 0 && formatDate(dateFormat)(date),
				}}
				colors={({ id }) => objectiveColors[id]}
				tooltip={({ point: { color, data, index } }) => (
					<div
						style={{
							padding: 12,
							color,
							background: '#222222',
							borderRadius: '8px',
							position: 'absolute',
							transform: index < dates.length / 2 ? 'translate(0,0)' : 'translate(-100%, 0)',
							width: 'max-content',
						}}
					>
						{data.xFormatted}: {data.yFormatted}
					</div>
				)}
				useMesh
				enablePoints={false}
				animate={false}
				theme={isDarkMode ? darkModeTheme : props.context === objectiveGraphContext.LIST ? darkerBackgroundTheme : undefined}
				markers={props.target ? [getMarker(props.target, t('objectives.chart.target'), isDarkMode)] : []}
			/>
		</div>
	)
})

GeneralROIChart.propTypes = {
	data: PropTypes.object.isRequired,
	initialCost: PropTypes.number,
	monthlyCost: PropTypes.number,
	target: PropTypes.number,
	context: PropTypes.oneOf(Object.values(objectiveGraphContext)).isRequired,
}

export default GeneralROIChart
