import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { GImage, GSelect, isEmpty } from '@alatimier/genesis-uic'
import { getVisuals } from '../../store/organizations/organizationsActions'
import { getFileExtension } from '../../utils/FileUtils'

const OrganizationVisualsSelect = (props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [options, setOptions] = useState([])
	const [selectedOptions, setSelectedOptions] = useState([])
	const [loading, setLoading] = useState(true)

	const organizationVisuals = useSelector((state) => state.organizations.visuals[props.organizationId])
	const organizationPending = useSelector((state) => state.organizations.pending)
	const visuals = useMemo(
		() => organizationVisuals?.filter((v) => !props.filteredVisualIds || props.filteredVisualIds.includes(v.id)) ?? [],
		[organizationVisuals, props.filteredVisualIds]
	)

	useEffect(() => fetchOrganizationVisuals(), [props.organizationId])
	useEffect(() => buildOptions(), [visuals])
	useEffect(() => initSelectedOptions(), [props.value, options])

	const fetchOrganizationVisuals = () => {
		if (props.organizationId) {
			setLoading(true)
			setOptions([])
			setSelectedOptions([])
			dispatch(getVisuals(props.organizationId))
		}
	}

	const buildOptions = () => {
		if (organizationVisuals && !organizationPending) setLoading(false)
		setOptions(
			visuals.map((v) => ({
				value: v.id.toString(),
				searchLabel: v.name,
				label: (
					<>
						<GImage
							src={getFileExtension(v.fileUri) === 'svg' ? v.fileUri : v.fileThumbnailUri}
							maxWidth="100px"
							maxHeight="100px"
							className="me-2"
						/>
						{v.name}
					</>
				),
			}))
		)
	}

	const initSelectedOptions = () => {
		if (props.value && !loading) {
			const newSelectedOptions = props.multiple
				? props.value.map((visualId) => options.find((option) => visualId.toString() === option.value))
				: [options.find((option) => props.value.toString() === option.value)]
			const selectedOptionsChanged =
				newSelectedOptions.length !== selectedOptions.length ||
				newSelectedOptions.some((option, index) => option?.value !== selectedOptions[index]?.value)
			const organizationChanged = isEmpty(newSelectedOptions) && !isEmpty(props.value)
			if (selectedOptionsChanged || organizationChanged) {
				handleChange(props.multiple ? newSelectedOptions : newSelectedOptions[0])
				setSelectedOptions(newSelectedOptions)
			}
		}
	}

	const handleChange = (selectedVisuals) => {
		if (props.multiple) {
			props.onChange(selectedVisuals.map((s) => parseInt(s.value)))
		} else {
			props.onChange(selectedVisuals ? parseInt(selectedVisuals.value) : undefined)
		}
	}

	if (props.autoHide && isEmpty(options)) {
		return <></>
	}

	const { label, ...otherProps } = props
	return (
		<GSelect
			{...otherProps}
			label={`${label ? label : props.multiple ? t('forms.visuals') : t('forms.visual')}${props.semiRequired ? ' (*)' : ''}`}
			value={selectedOptions}
			options={options}
			loading={loading}
			clearable
			onChange={handleChange}
			onBlur={props.onBlur}
		/>
	)
}

OrganizationVisualsSelect.propTypes = {
	id: PropTypes.string.isRequired,
	organizationId: PropTypes.number,
	filteredVisualIds: PropTypes.arrayOf(PropTypes.number),
	name: PropTypes.string,
	label: PropTypes.string,
	labelPosition: PropTypes.oneOf(['left', 'up']),
	placeholder: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.number]),
	multiple: PropTypes.bool,
	closeMenuOnSelect: PropTypes.bool,
	maxMenuHeight: PropTypes.number,
	searchable: PropTypes.bool,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	onBlur: PropTypes.func,
	help: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	error: PropTypes.string,
	required: PropTypes.bool,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	className: PropTypes.string,
	selectClassName: PropTypes.string,
	simple: PropTypes.bool,
}

export default OrganizationVisualsSelect
