import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { GButton, GCard, GInput, GOrganizationSelect, GSecured, GTitleBar } from '@alatimier/genesis-uic'
import OrganizationSpacesSelect from '../../components/spaces/OrganizationSpacesSelect'
import OrganizationDeviceSelect from '../../components/devices/select/OrganizationDeviceSelect'
import { download } from '../../store/export/exportActions'
import DateUtils from '../../utils/DateUtils'

const Export = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [organizationId, setOrganizationId] = useState()
	const [from, setFrom] = useState()
	const [to, setTo] = useState()
	const [spaceIds, setSpaceIds] = useState([])
	const [deviceIds, setDeviceIds] = useState([])

	const pending = useSelector((state) => state.export.pending)

	const handleDownload = () => {
		dispatch(download(organizationId, DateUtils.toZonedDateTime(from), DateUtils.toZonedDateTime(to), spaceIds, deviceIds))
	}

	return (
		<GSecured permission="STATISTICS_EXPORT">
			<GTitleBar>{t('export.title')}</GTitleBar>
			<GCard title={t('export.filters.title')} row>
				<div className="row">
					<div className="col-6">
						<GOrganizationSelect
							id="organizationId"
							labelPosition="up"
							value={organizationId}
							onChange={setOrganizationId}
							permission="STATISTICS_EXPORT"
						/>
					</div>
				</div>
				<div className="row">
					<div className="col">
						<GInput id="startDate" label={t('forms.date.start')} labelPosition="up" value={from} onChange={setFrom} type="datetime-local" />
					</div>
					<div className="col">
						<GInput id="endDate" label={t('forms.date.end')} labelPosition="up" value={to} onChange={setTo} type="datetime-local" />
					</div>
				</div>
				<div className="row">
					<div className="col">
						<OrganizationSpacesSelect
							id="spaceIds"
							labelPosition="up"
							organizationId={organizationId}
							value={spaceIds}
							onChange={setSpaceIds}
							multiple
						/>
					</div>
					<div className="col">
						<OrganizationDeviceSelect
							id="deviceIds"
							labelPosition="up"
							organizationId={organizationId}
							spaceIds={spaceIds}
							value={deviceIds}
							onChange={setDeviceIds}
						/>
					</div>
				</div>
				<GButton label={t('action.download')} onClick={handleDownload} location={'card'} busy={pending} />
			</GCard>
		</GSecured>
	)
}

export default Export
