import { useEffect, useMemo, useState, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { DateTime } from 'luxon'
import { debounce, intersection } from 'lodash'
import { GCard, GCrudFormActions, GIcon, GInput, GSelect, GTitleBar, GOrganizationSelect, isEmpty } from '@alatimier/genesis-uic'
import OrganizationVisualsSelect from '../../components/visuals/OrganizationVisualsSelect'
import OrganizationCampaignSelect from '../../components/campaigns/select/OrganizationCampaignSelect'
import BaselineForm from '../../components/objectives/BaselineForm/BaselineForm'
import { clearState, createObjective, deleteObjectives, getObjective, getPreviewStatistics, updateObjective } from '../../store/objectives/objectivesActions'
import DateUtils from '../../utils/DateUtils'
import { getDefaultObjectiveName, objectiveTypes } from '../../components/objectives/utils'
import ObjectivePreview from '../../components/objectives/ObjectivePreview/ObjectivePreview'

const objectivesPath = '/objectives'

const ObjectiveEdit = () => {
	const { t } = useTranslation()
	const params = useParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const objective = useSelector((state) => state.objectives.objective)
	const pending = useSelector((state) => state.objectives.pending)
	const workflowStatus = useSelector((state) => state.objectives.status)
	const createdObjectiveId = useSelector((state) => state.objectives.newId)

	const typeOptions = useMemo(
		() =>
			Object.values(objectiveTypes).map((type) => ({
				...type,
				label: t(`objectives.type.${type.value}`),
			})),
		[]
	)

	const [organizationId, setOrganizationId] = useState()
	const [targetOrganizationId, setTargetOrganizationId] = useState()
	const [type, setType] = useState(typeOptions[0])
	const [campaignId, setCampaignId] = useState()
	const [visualId, setVisualId] = useState()
	const [referenceStartDate, setReferenceStartDate] = useState()
	const [referenceEndDate, setReferenceEndDate] = useState()
	const [referenceCampaignId, setReferenceCampaignId] = useState()
	const [referenceVisualId, setReferenceVisualId] = useState()
	const [visualCost, setVisualCost] = useState()
	const [solutionUseStartDate, setSolutionUseStartDate] = useState()
	const [hardwareCost, setHardwareCost] = useState()
	const [monthlySoftwareCost, setMonthlySoftwareCost] = useState()
	const [target, setTarget] = useState('')
	const [name, setName] = useState('')
	const [deadline, setDeadline] = useState(DateTime.now().plus({ month: 1 }).toISODate())

	const [close, setClose] = useState(false)
	const [objectiveLoading, setObjectiveLoading] = useState(true)
	const [allowAutoFill, setAllowAutoFill] = useState(false)

	const organizationCampaigns = useSelector((state) => state.organizations.campaigns[targetOrganizationId])
	const organizationVisuals = useSelector((state) => state.organizations.visuals[organizationId])

	const visualsWithEan = useMemo(() => {
		if (type.selectors.ean === 'required' && organizationVisuals) {
			return organizationVisuals.filter((visual) => visual.ean).map((visual) => visual.id)
		}
	}, [organizationVisuals, type])
	const campaign = useMemo(() => organizationCampaigns?.find((c) => c.id === campaignId), [campaignId, organizationCampaigns])
	const visual = useMemo(() => organizationVisuals?.find((v) => v.id === visualId), [visualId, organizationVisuals])
	const referenceCampaign = useMemo(() => organizationCampaigns?.find((c) => c.id === referenceCampaignId), [referenceCampaignId, organizationCampaigns])

	const isValidForPreview = useMemo(
		() =>
			!isEmpty(type) &&
			(type.selectors.campaign !== 'required' || !isEmpty(campaignId)) &&
			(type.selectors.visual !== 'required' || !isEmpty(visualId)) &&
			(type.selectors.ean !== 'required' || !!visual?.ean) &&
			(type.selectors.baseline !== 'required' || (!isEmpty(referenceStartDate) && !isEmpty(referenceEndDate))) &&
			(type.selectors.baselineOrCampaign !== 'required' ||
				(!isEmpty(referenceStartDate) && !isEmpty(referenceEndDate) && isEmpty(referenceCampaignId)) ||
				(isEmpty(referenceStartDate) && isEmpty(referenceEndDate) && !isEmpty(referenceCampaignId))) &&
			(type.selectors.solutionUseInfos !== 'required' || !isEmpty(solutionUseStartDate)),
		[type, campaignId, visualId, referenceStartDate, referenceEndDate, referenceCampaignId, solutionUseStartDate]
	)
	const isValid =
		isValidForPreview &&
		(type.selectors.target !== 'required' || !isEmpty(Number(target))) &&
		(type.selectors.visualCost !== 'required' || !isEmpty(Number(visualCost))) &&
		(type.selectors.solutionUseInfos !== 'required' || (!isEmpty(Number(hardwareCost)) && !isEmpty(Number(monthlySoftwareCost)))) &&
		!isEmpty(deadline) &&
		(name === '' || !isEmpty(name))

	useEffect(() => fetchObjective(), [params.id])
	useEffect(() => prefillForm(), [objective])
	useEffect(() => handleWorkflowStatusChange(), [workflowStatus])
	useEffect(() => {
		allowAutoFill && setTarget('')
	}, [type])
	useEffect(() => validateTarget(), [type, target])
	useEffect(
		() => fetchPreviewData(),
		[type, campaign, visual, referenceStartDate, referenceEndDate, referenceCampaignId, referenceVisualId, solutionUseStartDate, deadline]
	)
	useEffect(() => {
		// Allow autofill only when edited objective and campaign are loaded
		let timeoutId
		if ((objectiveLoading || objective) && !campaign) setAllowAutoFill(false)
		else if (!objectiveLoading)
			// Wait for hooks chain to be finished
			timeoutId = setTimeout(() => {
				setAllowAutoFill(true)
			}, 0)
		return () => {
			clearTimeout(timeoutId)
		}
	}, [objectiveLoading, campaign])

	// Autofills :
	useEffect(() => preselectVisual(), [campaign?.id])
	useEffect(() => prefillDeadlineToCampaignEnd(), [campaign?.id])
	useEffect(() => clearReferenceCampaign(), [referenceStartDate, referenceEndDate])
	useEffect(() => clearReferenceBaseline(), [referenceCampaignId, referenceVisualId])

	// Limit calls to stats API with debounce
	const getPreviewDataDebounce = useCallback(
		debounce((previewedObjective) => dispatch(getPreviewStatistics(previewedObjective)), 500),
		[dispatch]
	)
	// Cancel next debounce call when ObjectiveEdit is dismantled
	useEffect(() => getPreviewDataDebounce.cancel, [])
	const fetchPreviewData = () => {
		if (
			isValidForPreview &&
			(campaign || !campaignId || !type.selectors.campaign) &&
			((visual && campaign.visualIds.includes(visualId)) || !visualId || !type.selectors.visual)
		) {
			getPreviewDataDebounce({
				organizationId,
				targetOrganizationId,
				type: type.type,
				metric: type.metric,
				campaignId,
				visualId,
				referenceStartDate: DateUtils.toZonedDateTime(referenceStartDate),
				referenceEndDate: DateUtils.toZonedDateTime(referenceEndDate),
				referenceCampaignId,
				referenceVisualId,
				startDate: solutionUseStartDate,
				deadline: DateUtils.toZonedDateTime(deadline),
			})
		}
	}

	const validateTarget = () => {
		setTarget((target) => {
			return target === '' ? '' : type.type === 'REACH' ? Math.floor(Math.abs(target)) : Math.abs(target)
		})
	}

	const preselectVisual = () => {
		if (allowAutoFill && type?.selectors.visual === 'required' && campaign) {
			setVisualId(campaign.visualIds[0])
		}
	}

	const prefillDeadlineToCampaignEnd = () => {
		if (allowAutoFill && campaign?.endDate) {
			setDeadline(DateUtils.toLocaleDate(campaign.endDate))
		}
	}

	const clearReferenceCampaign = () => {
		if (referenceStartDate || referenceEndDate) {
			setReferenceVisualId(undefined)
			setReferenceCampaignId(undefined)
		}
	}

	const clearReferenceBaseline = () => {
		if (referenceCampaignId || referenceVisualId) {
			setReferenceStartDate(undefined)
			setReferenceEndDate(undefined)
		}
	}

	const fetchObjective = () => {
		if (params.id && params.id !== 'new') {
			setObjectiveLoading(true)
			setAllowAutoFill(false)
			dispatch(getObjective(params.id))
		} else {
			setObjectiveLoading(false)
			setAllowAutoFill(true)
		}
	}

	const prefillForm = () => {
		if (params.id !== 'new' && objective) {
			setOrganizationId(objective.organizationId)
			setTargetOrganizationId(objective.targetOrganizationId)
			setType(typeOptions.find((type) => type.type === objective.type && (!type.metric || type.metric === objective.metric)))
			setCampaignId(objective.campaignId)
			setVisualId(objective.visualId)
			setReferenceStartDate(DateUtils.toLocaleDateTime(objective.referenceStartDate))
			setReferenceEndDate(DateUtils.toLocaleDateTime(objective.referenceEndDate))
			setReferenceCampaignId(objective.referenceCampaignId)
			setReferenceVisualId(objective.referenceVisualId)
			setVisualCost(objective.cost)
			setSolutionUseStartDate(DateUtils.toLocaleDate(objective.startDate))
			setHardwareCost(objective.initialCost)
			setMonthlySoftwareCost(objective.monthlyCost)
			setTarget(objective.target || 0)
			setName(objective.name)
			setDeadline(DateUtils.toLocaleDate(objective.deadline))
			setObjectiveLoading(false)
		}
	}

	const handleWorkflowStatusChange = () => {
		switch (workflowStatus) {
			case 'created':
				close ? doClose() : navigate(`${objectivesPath}/${createdObjectiveId}`)
				dispatch(clearState())
				break
			case 'updated':
				close ? doClose() : dispatch(getObjective(params.id))
				break
			case 'deleted':
				doClose()
				break
		}
	}

	const handleSave = () => {
		setClose(true)
		apply()
	}

	const handleDelete = () => {
		dispatch(deleteObjectives([objective.id]))
	}

	const doClose = () => {
		navigate(objectivesPath)
	}

	const apply = () => {
		const properties = [
			organizationId,
			targetOrganizationId,
			type.type,
			type.metric,
			campaignId,
			visualId,
			referenceCampaignId,
			referenceVisualId,
			DateUtils.toZonedDateTime(referenceStartDate),
			DateUtils.toZonedDateTime(referenceEndDate),
			Number(visualCost),
			solutionUseStartDate ? DateTime.fromISO(solutionUseStartDate).toUTC().toISO() : undefined,
			Number(hardwareCost),
			Number(monthlySoftwareCost),
			Number(target),
			name || getDefaultObjectiveName(type, campaign, visual),
			DateUtils.toZonedDateTime(deadline),
		]
		if (objective) {
			dispatch(updateObjective(objective.id, ...properties))
		} else {
			dispatch(createObjective(...properties))
		}
	}

	const renderPending = () => (
		<GCard title={t('objectives.properties.title')} row>
			<div className="text-center">
				<GIcon name="fa-spinner" spin />
			</div>
		</GCard>
	)

	const filteredVisualIds = visualsWithEan ? (campaign ? intersection(visualsWithEan, campaign.visualIds) : visualsWithEan) : campaign?.visualIds

	const getCampaignDates = (campaign) => {
		const campaignStartDate = DateTime.fromISO(campaign?.startDate).toLocaleString(DateTime.DATE_SHORT)
		const campaignEndDate = DateTime.fromISO(campaign?.endDate).toLocaleString(DateTime.DATE_SHORT)
		return campaign
			? campaign.endDate
				? `${t('forms.date.start.short')} ${campaignStartDate} ${t('forms.date.end.short')} ${campaignEndDate}`
				: `${t('forms.date.start.alone')} ${campaignStartDate}`
			: ''
	}

	const renderForm = () => (
		<>
			<GCard title={t('objectives.properties.title')} row>
				<GOrganizationSelect id="organizationId" value={organizationId} onChange={setOrganizationId} maxLevel={1} />
				<GOrganizationSelect
					id="targetOrganizationId"
					topOrganizationId={organizationId}
					label={t('forms.targetOrganization')}
					value={targetOrganizationId}
					onChange={setTargetOrganizationId}
				/>
				<GSelect id="type" label={t('objectives.form.type')} options={typeOptions} value={type} onChange={setType} required />
				<hr />
				{type?.selectors.campaign && (
					<OrganizationCampaignSelect
						id="campaignId"
						organizationId={targetOrganizationId}
						organizationType="TARGET"
						value={campaignId}
						onChange={setCampaignId}
						onlyStarted
						required
						help={getCampaignDates(campaign)}
					/>
				)}
				{type?.selectors.visual && (
					<>
						<OrganizationVisualsSelect
							id="visualId"
							organizationId={organizationId}
							filteredVisualIds={filteredVisualIds}
							placeholder={campaign && filteredVisualIds.length === 0 ? t('objectives.form.visual.noean') : undefined}
							value={visualId}
							onChange={setVisualId}
							required={type.selectors.visual === 'required'}
							help={type.selectors.ean && visual && visual.ean ? `${t('visuals.form.ean')} : ${visual.ean}` : ''}
						/>
					</>
				)}
				{(type?.selectors.baseline || type?.selectors.baselineOrCampaign) && (
					<>
						<hr />
						<BaselineForm
							start={referenceStartDate}
							end={referenceEndDate}
							campaignStart={campaign?.startDate}
							campaignEnd={campaign?.endDate}
							onStartChange={setReferenceStartDate}
							onEndChange={setReferenceEndDate}
							required={!!type.selectors.baseline}
							semiRequired={!!type.selectors.baselineOrCampaign}
							allowAutoFill={allowAutoFill}
						/>
					</>
				)}
				{type?.selectors.baselineOrCampaign && (
					<>
						<div className="mb-3">{t('objectives.form.or')}</div>
						<OrganizationCampaignSelect
							id="referenceCampaignId"
							label={t('objectives.form.referenceCampaign')}
							organizationId={targetOrganizationId}
							organizationType="TARGET"
							value={referenceCampaignId}
							onChange={setReferenceCampaignId}
							autoSelect={false}
							onlyStarted
							startedBefore={campaign?.startDate}
							semiRequired
							help={getCampaignDates(referenceCampaign)}
						/>
						<OrganizationVisualsSelect
							id="referenceVisualId"
							label={t('objectives.form.referenceVisual')}
							organizationId={organizationId}
							filteredVisualIds={referenceCampaign?.visualIds}
							value={referenceVisualId}
							onChange={setReferenceVisualId}
						/>
					</>
				)}
				{type?.selectors.visualCost && (
					<GInput
						id="visual_cost"
						type="number"
						label={t('objectives.form.visualCost')}
						suffix={t('objectives.currency')}
						value={visualCost}
						onChange={setVisualCost}
						required
					/>
				)}
				{type?.selectors.solutionUseInfos && (
					<>
						<GInput
							id="solution_use_start_date"
							type="date"
							label={t('objectives.form.solutionUseStartDate')}
							value={solutionUseStartDate}
							onChange={setSolutionUseStartDate}
							required
						/>
						<GInput
							id="hardware_cost"
							type="number"
							label={t('objectives.form.hardwareCost')}
							suffix={t('objectives.currency')}
							value={hardwareCost}
							onChange={setHardwareCost}
							required
						/>
						<GInput
							id="monthly_software_cost"
							type="number"
							label={t('objectives.form.monthlySoftwareCost')}
							suffix={t('objectives.currency')}
							value={monthlySoftwareCost}
							onChange={setMonthlySoftwareCost}
							required
						/>
					</>
				)}
				<hr />
				{type?.selectors.target && (
					<GInput
						id="target"
						type="number"
						label={t('objectives.form.target')}
						suffix={t(`objectives.form.${type.value}.suffix`)}
						value={target}
						onChange={setTarget}
						required
					/>
				)}
				<GInput
					id="name"
					label={t('objectives.form.name')}
					placeholder={getDefaultObjectiveName(type, campaign, visual)}
					value={name}
					onChange={setName}
				/>
				<GInput id="deadline" type="date" label={t('objectives.form.deadline')} value={deadline} onChange={setDeadline} required />
			</GCard>
			<ObjectivePreview
				type={type}
				target={Number(target)}
				visualCost={Number(visualCost)}
				hardwareCost={Number(hardwareCost)}
				monthlySoftwareCost={Number(monthlySoftwareCost)}
			/>
		</>
	)

	return (
		<>
			<GTitleBar>{t('objectives.title')}</GTitleBar>
			{objectiveLoading ? renderPending() : renderForm()}
			<GCrudFormActions
				canSave={isValid}
				onApply={apply}
				onSave={handleSave}
				canCancel
				onCancel={doClose}
				canDelete={!!objective}
				onDelete={handleDelete}
				busy={pending}
				location={['page', 'page-top']}
			/>
		</>
	)
}

export default ObjectiveEdit
