import {
	DEVICES_CLEAR_STATE,
	DEVICES_CREATE_DEVICE,
	DEVICES_CREATE_DEVICE_FAILURE,
	DEVICES_CREATE_DEVICE_SUCCESS,
	DEVICES_DELETE_DEVICE,
	DEVICES_DELETE_DEVICE_FAILURE,
	DEVICES_DELETE_DEVICE_SUCCESS,
	DEVICES_GET_DEVICE,
	DEVICES_GET_DEVICE_FAILURE,
	DEVICES_GET_DEVICE_SUCCESS,
	DEVICES_GET_DEVICE_TYPES,
	DEVICES_GET_DEVICE_TYPES_FAILURE,
	DEVICES_GET_DEVICE_TYPES_SUCCESS,
	DEVICES_GET_DEVICES,
	DEVICES_GET_DEVICES_FAILURE,
	DEVICES_GET_DEVICES_SUCCESS,
	DEVICES_GET_INFERENCE_TYPES,
	DEVICES_GET_INFERENCE_TYPES_FAILURE,
	DEVICES_GET_INFERENCE_TYPES_SUCCESS,
	DEVICES_UPDATE_DEVICE,
	DEVICES_UPDATE_DEVICE_FAILURE,
	DEVICES_UPDATE_DEVICE_SUCCESS,
} from './devicesTypes'

const initialState = {
	pending: false,
	types: [],
	inferences: [],
	list: [],
	variants: [],
	device: null,
	newId: null,
	status: null,
}

const devicesReducer = (state = initialState, action) => {
	switch (action.type) {
		case DEVICES_GET_DEVICE_TYPES_SUCCESS:
			return {
				pending: false,
				types: action.list,
			}
		case DEVICES_GET_INFERENCE_TYPES_SUCCESS:
			return {
				pending: false,
				inferences: action.list,
			}
		case DEVICES_GET_DEVICE_TYPES:
		case DEVICES_GET_INFERENCE_TYPES:
		case DEVICES_GET_DEVICES:
		case DEVICES_GET_DEVICE:
		case DEVICES_CREATE_DEVICE:
		case DEVICES_UPDATE_DEVICE:
		case DEVICES_DELETE_DEVICE:
			return {
				...state,
				pending: true,
				status: null,
			}
		case DEVICES_GET_DEVICES_SUCCESS:
			return {
				...state,
				pending: false,
				list: action.list,
			}
		case DEVICES_GET_DEVICE_SUCCESS:
			return {
				...state,
				pending: false,
				device: action.device,
				variants: action.variants,
			}
		case DEVICES_CREATE_DEVICE_SUCCESS:
			return {
				...state,
				pending: false,
				newId: action.newId,
				status: 'created',
			}
		case DEVICES_UPDATE_DEVICE_SUCCESS:
			return {
				...state,
				pending: false,
				status: 'updated',
			}
		case DEVICES_DELETE_DEVICE_SUCCESS:
			return {
				...state,
				pending: false,
				status: 'deleted',
			}
		case DEVICES_GET_DEVICE_TYPES_FAILURE:
		case DEVICES_GET_INFERENCE_TYPES_FAILURE:
		case DEVICES_GET_DEVICES_FAILURE:
		case DEVICES_GET_DEVICE_FAILURE:
		case DEVICES_CREATE_DEVICE_FAILURE:
		case DEVICES_UPDATE_DEVICE_FAILURE:
		case DEVICES_DELETE_DEVICE_FAILURE:
			return {
				...state,
				pending: false,
			}
		case DEVICES_CLEAR_STATE:
			return {
				...initialState,
			}
		default:
			return state
	}
}

export default devicesReducer
