/** @jsxImportSource @emotion/react */
import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'
import head from 'lodash/head'
import last from 'lodash/last'
import { ResponsiveLine } from '@nivo/line'
import { GMessage, isEmpty } from '@alatimier/genesis-uic'
import { darkerBackgroundTheme, darkModeTheme, objectiveColors } from '../../../utils/ChartUtils'
import { completeDatesForComparison, formatDate, getAverageAttentionTime, getDateUnitAndFormat, getMarker, objectiveGraphContext } from '../utils'

const LinePoint =
	(isDarkMode) =>
	({ size, color, borderWidth, borderColor }) => {
		return (
			<g>
				<ellipse fill={isDarkMode ? 'var(--cui-card-bg)' : 'white'} rx={size / 2} ry={size / 3} strokeWidth={borderWidth} stroke={borderColor} />
				<circle r={size / 5} strokeWidth={borderWidth} stroke={borderColor} fill={color} fillOpacity={0.35} />
			</g>
		)
	}

const AttentionCompareChart = memo((props) => {
	const { t } = useTranslation()

	const isDarkMode = useSelector((store) => store.g_display.darkMode)

	const { campaign, visual, measureEndDate, referenceStartDate, referenceEndDate } = props.data

	const dates = props.data.measured.map((d) => d.date).sort()
	const referenceDates = props.data.reference.map((d) => d.date).sort()

	// Have at least one date, for reference dates to grow on
	if (!dates.length) dates.push(campaign.startDate)

	const { dateUnit, dateFormat } = getDateUnitAndFormat(campaign.startDate, measureEndDate, head(referenceDates) ?? head(dates), last(dates))
	const { dateFormat: referenceDateFormat } = getDateUnitAndFormat(referenceStartDate, referenceEndDate, head(referenceDates) ?? head(dates), last(dates))

	completeDatesForComparison(dates, referenceDates, dateUnit, campaign.startDate)

	const data = [
		{
			id: 'reference',
			data: dates.map((date, index) => ({
				x: date,
				y: props.data.reference[index]?.attentionTime ?? null,
				views: props.data.reference[index]?.views ?? null,
			})),
		},
		{
			id: 'measured',
			data: dates.map((date) => {
				const measuredDateData = props.data.measured.find((d) => d.date === date)
				return {
					x: date,
					y: measuredDateData?.attentionTime ?? null,
					views: measuredDateData?.views ?? null,
				}
			}),
		},
	]

	const emptyReferenceData = isEmpty(data[0].data.filter(({ y }) => y))
	const emptyMeasuredData = isEmpty(data[1].data.filter(({ y }) => y))
	if (emptyReferenceData && emptyMeasuredData) {
		return <GMessage value={t('objectives.chart.noData')} className="mb-0" />
	}

	const referenceAverageAttentionTime = getAverageAttentionTime(props.data.reference)

	const dateTickStep = Math.ceil(dates.length / 18)

	const getLabel = (id) => (id === 'measured' ? (visual ? visual.name : campaign.name) : t('objectives.chart.reference'))
	const getAndFormatReferenceDate = (baseDate) => {
		const referenceDate = referenceDates[dates.indexOf(baseDate)]
		return DateTime.fromISO(referenceDate).toLocaleString(referenceDateFormat)
	}
	const formatValue = (time) => `${time.toLocaleString()}${t('dashboard.attentionTime.unit.short')}`

	const getErrorMessage = () => {
		if (props.context === objectiveGraphContext.EDIT) {
			if (emptyReferenceData) return <GMessage value={t('objectives.chart.noReferenceData')} />
			if (emptyMeasuredData) return <GMessage value={t('objectives.chart.noMeasuredData')} />
		}
	}

	return (
		<>
			{getErrorMessage()}
			<div data-testid="attention-compare-chart" css={{ height: props.context === objectiveGraphContext.LIST ? '300px' : '400px', width: '100%' }}>
				<ResponsiveLine
					data={emptyReferenceData ? [data[1]] : emptyMeasuredData ? [data[0]] : data}
					lineWidth={4}
					margin={{ top: 20, right: 30, bottom: 25, left: 50 }}
					colors={({ id }) => objectiveColors[id]}
					xFormat={formatDate(dateFormat)}
					yFormat={formatValue}
					yScale={{ type: 'linear', min: 'auto' }}
					axisLeft={{
						legend: `${t('dashboard.attentionTime.title')} (${t('dashboard.attentionTime.unit')})`,
						legendPosition: 'middle',
						legendOffset: -45,
					}}
					axisBottom={{
						format: (date) => dates.indexOf(date) % dateTickStep === 0 && formatDate(dateFormat)(date),
					}}
					tooltip={({ point: { borderColor, data, serieId } }) => (
						<div
							style={{
								padding: 12,
								color: borderColor,
								background: '#222222',
								borderRadius: '8px',
								position: 'absolute',
								transform: dates.indexOf(data.x) < dates.length / 2 ? 'translate(0,0)' : 'translate(-100%, 0)',
								width: 'max-content',
							}}
						>
							{serieId === 'reference' ? getAndFormatReferenceDate(data.x) : data.xFormatted} - {getLabel(serieId)}: {data.yFormatted} (
							{data.views} {t('objectives.chart.views')})
						</div>
					)}
					useMesh
					theme={isDarkMode ? darkModeTheme : props.context === objectiveGraphContext.LIST ? darkerBackgroundTheme : undefined}
					pointSymbol={LinePoint(isDarkMode)}
					pointSize={16}
					pointBorderWidth={1}
					pointBorderColor={{ from: 'serieColor', modifiers: [['darker', 0.3]] }}
					animate={false} // prevent display bug on different data with similar dates
					markers={
						props.target && !emptyReferenceData
							? [getMarker(referenceAverageAttentionTime + props.target, t('objectives.chart.target'), isDarkMode)]
							: []
					}
				/>
			</div>
		</>
	)
})

AttentionCompareChart.propTypes = {
	data: PropTypes.object.isRequired,
	target: PropTypes.number,
	context: PropTypes.oneOf(Object.values(objectiveGraphContext)).isRequired,
}

export default AttentionCompareChart
