import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GCard, GCrudFormActions, GImage, GInput, GOrganizationSelect, GTitleBar, isEmpty } from '@alatimier/genesis-uic'
import { clearState, createVisual, deleteVisuals, getVisual, updateVisual } from '../../store/visuals/visualsActions'
import TagSelect from '../../components/tags/TagSelect'
import { svgToBlob } from '../../utils/FileUtils'

const VisualEdit = () => {
	const { t } = useTranslation()
	const params = useParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const visual = useSelector((state) => state.visuals.visual)
	const pending = useSelector((state) => state.visuals.pending)
	const editorSvg = useSelector((state) => state.visuals.svg)
	const status = useSelector((state) => state.visuals.status)
	const createdVisualId = useSelector((state) => state.visuals.newId)
	const progressions = useSelector((state) => state.g_progression.progressions)

	const [organizationId, setOrganizationId] = useState()
	const [name, setName] = useState()
	const [ean, setEan] = useState()
	const [tags, setTags] = useState([])
	const [file, setFile] = useState(() => {
		if (editorSvg) {
			const unicodeUnescape = (string) =>
				string.replace(/%u([\dA-Z]{4})|%([\dA-Z]{2})/gi, function (_, m1, m2) {
					return String.fromCharCode(parseInt('0x' + (m1 || m2)))
				})
			const blob = svgToBlob(unicodeUnescape(encodeURI(editorSvg.data)))
			return new File([blob], 'visualFromEditor.svg', { type: 'image/svg+xml' })
		}
		return null
	})
	const [close, setClose] = useState(false)

	useEffect(() => fetchVisual(), [params.id])
	useEffect(() => prefillForm(), [visual])
	useEffect(() => handleWorkflowStatusChange(), [status])

	const isValid = () => !progressions.length && !isEmpty(organizationId) && !isEmpty(name) && (!!visual || !isEmpty(file))

	const fetchVisual = () => {
		if (params.id && params.id !== 'new' && params.id !== 'newTemplate') {
			dispatch(getVisual(params.id))
		}
	}

	function prefillForm() {
		if (params.id !== 'new' && visual && params.id !== 'newTemplate') {
			setOrganizationId(visual.organizationId)
			setName(visual.name)
			setEan(visual.ean)
			setTags(visual.tags)
		}
	}

	const handleWorkflowStatusChange = () => {
		switch (status) {
			case 'created':
				close ? doClose() : navigate(`/visuals/${createdVisualId}`)
				dispatch(clearState())
				break
			case 'updated':
				close ? doClose() : dispatch(getVisual(params.id))
				break
			case 'deleted':
				doClose()
				break
		}
	}

	const handleSave = () => {
		setClose(true)
		apply()
	}

	const handleDelete = () => {
		dispatch(deleteVisuals([visual.id]))
	}

	const handleCancel = () => {
		navigate(editorSvg ? '/editor' : '/visuals')
	}

	const doClose = () => {
		navigate('/visuals')
	}

	function apply() {
		if (visual) {
			dispatch(updateVisual(visual.id, organizationId, name, file, ean, tags))
		} else if (params.id == 'new') {
			dispatch(createVisual(organizationId, name, file, ean, tags, false))
		} else {
			dispatch(createVisual(organizationId, name, file, ean, tags, true))
		}
	}

	return (
		<>
			<GTitleBar>{t('visuals.title')}</GTitleBar>
			<GCard title={t('visuals.properties.title')} row>
				<GOrganizationSelect id="organizationId" value={organizationId} onChange={setOrganizationId} maxLevel={1} permission="VISUALS_EDIT" />
				<GInput id="name" label={t('visuals.form.name')} value={name} onChange={setName} required />
				{editorSvg ? (
					<>
						<div className="row mb-4">
							<div className="col-md-2 d-flex align-items-center text-break">{t('visuals.form.file')} *</div>
							<div className="col-md-10">{t('visuals.form.file.svg_loaded')}</div>
						</div>
						<div className="row mb-4">
							<div className="col-md-2 d-flex align-items-center text-break">{t('aspectRatio.form.label')} *</div>
							<div className="col-md-10">{t(`aspectRatio.${editorSvg.format}`)}</div>
						</div>
					</>
				) : (
					<>
						<GInput id="file" type="file" label={t('visuals.form.file')} help={t('visuals.form.file.help')} onChange={setFile} required={!visual} />
					</>
				)}
				{params.id !== 'newTemplate' && (
					<>
						<GInput id="ean" type="number" label={t('visuals.form.ean')} value={ean} onChange={setEan} />
						<TagSelect id="tags" value={tags} onChange={setTags} />
					</>
				)}
				<GCrudFormActions
					canSave={isValid()}
					onApply={apply}
					onSave={handleSave}
					canCancel
					onCancel={handleCancel}
					canDelete={!!visual}
					onDelete={handleDelete}
					busy={pending}
					location={['page', 'page-top']}
				/>
			</GCard>
			{(visual || editorSvg) && (
				<GCard row>
					{visual && <GImage src={visual.fileThumbnailUri} maxWidth="100%" />}
					{editorSvg && <img src={`data:image/svg+xml;utf8,${encodeURIComponent(editorSvg.data)}`} alt="Output of svg editor" />}
				</GCard>
			)}
		</>
	)
}

export default VisualEdit
