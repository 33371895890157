import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { GSelect, isEmpty } from '@alatimier/genesis-uic'
import { getSegments } from '../../store/organizations/organizationsActions'

const OrganizationSegmentSelect = (props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [options, setOptions] = useState([])
	const [selectedOption, setSelectedOption] = useState()
	const [loading, setLoading] = useState(true)

	const organizationSegments = useSelector((state) => state.organizations.segments[props.organizationId])
	const organizationPending = useSelector((state) => state.organizations.pending)
	const segments = useMemo(() => organizationSegments ?? [], [organizationSegments])

	useEffect(() => fetchSegments(), [props.organizationId])
	useEffect(() => buildOptions(), [segments])
	useEffect(() => initSelectedOptions(), [props.value, options])

	const fetchSegments = () => {
		if (props.organizationId) {
			setLoading(true)
			setOptions([])
			setSelectedOption(undefined)
			dispatch(getSegments(props.organizationId))
		}
	}

	const buildOptions = () => {
		if (organizationSegments && !organizationPending) setLoading(false)
		setOptions(segments.map((d) => ({ value: d.id.toString(), label: d.name })))
	}

	const initSelectedOptions = () => {
		if (props.value && !loading) {
			const selected = options.find((option) => props.value.toString() === option.value)
			handleChange(selected)
			setSelectedOption(selected)
		}
	}

	const handleChange = (selectedSegment) => {
		props.onChange(selectedSegment ? parseInt(selectedSegment.value) : undefined)
	}

	if (props.autoHide && isEmpty(options)) {
		return <></>
	}

	return (
		<GSelect
			id={props.id}
			name={props.name}
			label={t('forms.segment')}
			labelPosition={props.labelPosition}
			placeholder={props.placeholder}
			value={selectedOption}
			options={options}
			loading={loading}
			clearable
			onChange={handleChange}
			onBlur={props.onBlur}
			error={props.error}
			required={props.required}
			disabled={props.disabled}
			className={props.className}
		/>
	)
}

OrganizationSegmentSelect.propTypes = {
	id: PropTypes.string.isRequired,
	organizationId: PropTypes.number,
	name: PropTypes.string,
	labelPosition: PropTypes.oneOf(['left', 'up']),
	placeholder: PropTypes.string,
	value: PropTypes.number,
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func,
	error: PropTypes.string,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	autoHide: PropTypes.bool,
	className: PropTypes.string,
}

export default OrganizationSegmentSelect
