import { useContext, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { apiClientService, GModal, GIcon } from '@alatimier/genesis-uic'
import FigureList from '../FigureList/FigureList'
import { getTemplates } from '../../../../store/visuals/visualsActions'
import { editorContext, ADD_VISUAL, DESELECT_FIGURE, SET_FORMAT, TAB_VISUAL, SET_RESOLUTION } from '../../editorContext'
import { sanitizeSvg } from '../../utils/image'
import { clearDrawing, setMode } from '../../utils/actions'
import { getFileExtension } from '../../../../utils/FileUtils'
import { loadSvg } from '../../utils/init'

const VisualPanel = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [editorState, dispatchEditorState] = useContext(editorContext)

	const visuals = useSelector((state) => state.visuals.templates)
	const pending = useSelector((state) => state.visuals.pending)

	const [confirmModal, setConfirmModal] = useState(false)
	const visualToLoad = useRef()

	useEffect(() => {
		dispatch(getTemplates())
	}, [])
	useEffect(() => {
		let canceled = false
		visuals?.forEach((visual) => {
			if (getFileExtension(visual.fileUri) !== 'svg') return
			apiClientService.get(visual.fileUri).then(async (res) => {
				const data = await res.blob()
				if (data.type.includes('svg') && !canceled) {
					const reader = new FileReader()
					reader.onloadend = ({ target: { result } }) => {
						if (!canceled) {
							let data = sanitizeSvg(result)
							dispatchEditorState({
								type: ADD_VISUAL,
								visual: { id: visual.id, name: visual.name, isSvg: true, data, format: visual.fileAspectRatioId },
							})
						}
					}
					reader.readAsText(data)
				}
			})
		})
		return () => (canceled = true) // prevent updating an unmounted component state
	}, [visuals])

	const loadVisual = () => {
		setConfirmModal(false)
		clearDrawing(editorState.canvas)
		dispatchEditorState({ type: SET_FORMAT, format: visualToLoad.current.format })
		dispatchEditorState({ type: SET_RESOLUTION, resolution: '4K' })
		loadSvg(editorState.canvas, visualToLoad.current.data)
		editorState.canvas.call('load')
		setMode(editorState, dispatchEditorState, 'select')
		dispatchEditorState({ type: DESELECT_FIGURE })
	}

	const abortLoadVisual = () => {
		setConfirmModal(false)
	}

	const loadVisualConfirm = (figure) => {
		visualToLoad.current = figure
		if (editorState.new) {
			loadVisual()
		} else {
			setConfirmModal(true)
		}
	}

	const loadVisualFromMenu = (figure) => () => loadVisualConfirm(figure)

	const menuActions = [{ label: t('editor.action.load'), icon: 'fa-share', fn: loadVisualFromMenu }]

	return (
		<>
			<h1 className="h5 ms-2 mb-4">
				<span className="me-3">{t(`editor.tab.${TAB_VISUAL}`)}</span>
				{pending && <GIcon name="fa-spinner" spin />}
			</h1>
			<FigureList figureKey="visuals" onClick={loadVisualConfirm} menuActions={menuActions} />
			<GModal show={confirmModal} color="danger" type="confirm" title={t('editor.action.load.long')} onClick={loadVisual} onCancel={abortLoadVisual}>
				{t('editor.action.load.confirmation')}
			</GModal>
		</>
	)
}

export default VisualPanel
