import { apiClientService as client, setErrors, setSuccess } from '@alatimier/genesis-uic'
import { localStorageKeys } from '../../utils/LocalStorageUtils'
import {
	VISUALS_CLEAR_STATE,
	VISUALS_CREATE_VISUAL,
	VISUALS_CREATE_VISUAL_FAILURE,
	VISUALS_CREATE_VISUAL_SUCCESS,
	VISUALS_DELETE_VISUAL,
	VISUALS_DELETE_VISUAL_FAILURE,
	VISUALS_DELETE_VISUAL_SUCCESS,
	VISUALS_GET_VISUAL,
	VISUALS_GET_VISUAL_FAILURE,
	VISUALS_GET_VISUAL_SUCCESS,
	VISUALS_GET_VISUALS,
	VISUALS_GET_VISUALS_FAILURE,
	VISUALS_GET_VISUALS_SUCCESS,
	VISUALS_UPDATE_VISUAL,
	VISUALS_UPDATE_VISUAL_FAILURE,
	VISUALS_UPDATE_VISUAL_SUCCESS,
	VISUALS_SET_SVG,
	VISUALS_GET_TEMPLATES_SUCCESS,
	VISUALS_GET_TEMPLATES_FAILURE,
	VISUALS_GET_TEMPLATES,
} from './visualsTypes'

const visualsEndPoint = '/api/admin/v1/visuals'

export const getVisuals = () => {
	return (dispatch) => {
		dispatch({ type: VISUALS_GET_VISUALS })
		return client.get(visualsEndPoint).then(async (res) => {
			if (res.ok) {
				const data = await res.json()
				dispatch({
					type: VISUALS_GET_VISUALS_SUCCESS,
					list: data,
				})
			} else {
				dispatch({
					type: VISUALS_GET_VISUALS_FAILURE,
				})
			}
		})
	}
}

export const getTemplates = () => {
	return (dispatch) => {
		dispatch({ type: VISUALS_GET_TEMPLATES })
		return client.get(`${visualsEndPoint}?template=true`).then(async (res) => {
			if (res.ok) {
				const data = await res.json()
				dispatch({
					type: VISUALS_GET_TEMPLATES_SUCCESS,
					list: data,
				})
			} else {
				dispatch({
					type: VISUALS_GET_TEMPLATES_FAILURE,
				})
			}
		})
	}
}

export const getVisual = (id) => {
	return (dispatch) => {
		dispatch({ type: VISUALS_GET_VISUAL })
		return client.get(`${visualsEndPoint}/${id}`).then(async (res) => {
			if (res.ok) {
				const data = await res.json()
				dispatch({
					type: VISUALS_GET_VISUAL_SUCCESS,
					visual: data,
				})
			} else {
				dispatch({
					type: VISUALS_GET_VISUAL_FAILURE,
				})
			}
		})
	}
}

export const createVisual = (organizationId, name, file, ean, tags, template) => {
	return (dispatch, getState) => {
		dispatch({ type: VISUALS_CREATE_VISUAL })
		return client
			.postMultipart(
				`${visualsEndPoint}?template=${template}`,
				{
					organizationId,
					name,
					file,
					ean,
					tags,
				},
				true
			)
			.then(async (res) => {
				const data = await res.json()
				if (res.ok) {
					if (getState().visuals.svg) {
						localStorage.removeItem(localStorageKeys.svgString)
						localStorage.removeItem(localStorageKeys.svgOptions)
					}
					dispatch(setSuccess('action.save.success'))
					dispatch({
						type: VISUALS_CREATE_VISUAL_SUCCESS,
						newId: data.id,
					})
				} else {
					dispatch(setErrors(data.errors, data.validationErrors))
					dispatch({
						type: VISUALS_CREATE_VISUAL_FAILURE,
					})
				}
			})
	}
}

export const updateVisual = (id, organizationId, name, file, ean, tags) => {
	return (dispatch) => {
		dispatch({ type: VISUALS_UPDATE_VISUAL })
		return client
			.putMultipart(
				`${visualsEndPoint}/${id}`,
				{
					organizationId,
					name,
					...(file && { file }),
					ean,
					tags,
				},
				true
			)
			.then(async (res) => {
				if (res.ok) {
					dispatch(setSuccess('action.save.success'))
					dispatch({
						type: VISUALS_UPDATE_VISUAL_SUCCESS,
					})
				} else {
					const data = await res.json()
					dispatch(setErrors(data.errors, data.validationErrors))
					dispatch({
						type: VISUALS_UPDATE_VISUAL_FAILURE,
					})
				}
			})
	}
}

export const deleteVisuals = (ids) => {
	return (dispatch) => {
		dispatch({ type: VISUALS_DELETE_VISUAL })
		const promises = ids.map((id) => client.del(`${visualsEndPoint}/${id}`))
		return Promise.all(promises).then(async (results) => {
			if (results.every((r) => r.ok)) {
				dispatch(setSuccess('action.delete.success'))
			} else {
				const data = await results.find((r) => !r.ok).json()
				dispatch(setErrors(data.errors, data.validationErrors))
				dispatch({ type: VISUALS_DELETE_VISUAL_FAILURE })
			}
			if (results.some((r) => r.ok)) dispatch({ type: VISUALS_DELETE_VISUAL_SUCCESS })
		})
	}
}

export const setVisualSvg = (svg) => ({ type: VISUALS_SET_SVG, svg })

export const clearState = () => {
	return {
		type: VISUALS_CLEAR_STATE,
	}
}
