import { apiClientService as client } from '@alatimier/genesis-uic'
import DateUtils from '../../utils/DateUtils'
import { getObjectiveType, objectiveTypes } from '../../components/objectives/utils'

export class ServerException extends Error {}

const getStatisticsEndPoint = (visualId) => `/api/admin/v1/statistics/visual/${visualId}`
const getSalesEndPoint = (visualId) => `/api/admin/v1/visuals/${visualId}/performance`

const getSalesStatsRequests = (visuals, startDate, endDate, wmCredit) => {
	const queryParams = new URLSearchParams({
		from: DateUtils.toZonedDateTime(startDate) ?? '',
		to: DateUtils.toZonedDateTime(endDate) ?? '',
		...(wmCredit ? { wmCredit } : {}),
	})
	return visuals.map((visual) => client.get(`${getSalesEndPoint(visual.id)}?${queryParams.toString()}`).then((res) => ({ res })))
}

const getViewsStatsRequests = (visuals, startDate, endDate, organizationId) => {
	const queryParams = new URLSearchParams({
		from: DateUtils.toZonedDateTime(startDate) ?? '',
		to: DateUtils.toZonedDateTime(endDate) ?? '',
		organizationId: organizationId,
	})
	return visuals.map((visual) => client.get(`${getStatisticsEndPoint(visual.id)}?${queryParams.toString()}`).then((res) => ({ res })))
}

const getSelectedVisuals = (state, organizationId, campaign, visual) => {
	return visual ? [visual] : state.organizations.visuals[organizationId]?.filter((v) => campaign.visualIds.includes(v.id)) ?? []
}

const getReferenceVisuals = (state, organizationId, referenceCampaign, referenceVisualId) => {
	if (referenceCampaign) {
		const referenceVisual = state.organizations.visuals[organizationId].find((v) => v.id === referenceVisualId)
		return getSelectedVisuals(state, organizationId, referenceCampaign, referenceVisual)
	} else {
		return state.organizations.visuals[organizationId]
	}
}

const getVisualsStatsData = async (requests) => {
	return Promise.all(requests).then(async (results) => {
		if (results.every((r) => r.res?.ok)) {
			const visualsData = await Promise.all(
				results.map(async ({ res }) => ({
					visualData: await res.json(),
				}))
			)
			return visualsData
		} else {
			throw new ServerException()
		}
	})
}

const sumVisualsSales = (data) => {
	const visualsSales = {}
	for (const { visualData } of data) {
		for (const date of Object.keys(visualData)) {
			if (visualsSales[date]) {
				visualsSales[date].turnover += visualData[date].turnover
				visualsSales[date].benefit += visualData[date].benefit
				visualsSales[date].quantity += visualData[date].quantity
			} else {
				visualsSales[date] = { ...visualData[date] }
			}
		}
	}
	return visualsSales
}

const getCumulatedSales = (data) => {
	const cumulated = {}
	let lastValue = { turnover: 0, benefit: 0, quantity: 0 }
	Object.keys(data)
		.sort()
		.forEach((date) => {
			cumulated[date] = {
				turnover: lastValue.turnover + data[date].turnover,
				benefit: lastValue.benefit + data[date].benefit,
				quantity: lastValue.quantity + data[date].quantity,
			}
			lastValue = cumulated[date]
		})
	return cumulated
}

const computeSalesMetricStats = (measuredData, referenceData) => {
	const measured = measuredData[0].visualData
	const reference = sumVisualsSales(referenceData)
	return {
		measured,
		reference,
		measuredCumulated: getCumulatedSales(measured),
		referenceCumulated: getCumulatedSales(reference),
	}
}

const getDateAttentionTime = (date, data) => {
	let viewsTotal = 0
	const attentionTimexViews = data.reduce((attentionTimexViews, { visualData }) => {
		const dateData = visualData.histogram?.[date]
		const views = dateData?.views?.global.total
		const attentionTime = dateData?.attentionTime?.global.average
		if (views && attentionTime) {
			viewsTotal += views
			if (attentionTimexViews) return attentionTimexViews + views * attentionTime
			else return views * attentionTime
		} else {
			return attentionTimexViews
		}
	}, undefined)
	return { date, attentionTime: viewsTotal ? attentionTimexViews / viewsTotal : null, views: viewsTotal }
}

const getDataByDate = (data) => {
	const dates = [
		...new Set(
			data
				.reduce((dates, { visualData }) => {
					return dates.concat(Object.keys(visualData.histogram ?? {}))
				}, [])
				.sort()
		),
	]
	return dates.map((date) => {
		return getDateAttentionTime(date, data)
	})
}

const computeAttentionTimeMetricStats = (measuredData, referenceData) => {
	return {
		measured: getDataByDate(measuredData),
		reference: getDataByDate(referenceData),
	}
}

const computeReachStats = (data) => {
	const targetedViews = data.reduce((total, { visualData }) => {
		const visualViews = visualData.global.views?.global.total
		return total + (visualViews ?? 0)
	}, 0)
	return { views: targetedViews }
}

const getReferenceDates = (referenceStartDate, referenceEndDate, referenceCampaign) => {
	if (referenceStartDate && referenceEndDate) {
		return { referenceStartDate, referenceEndDate }
	} else if (referenceCampaign) {
		return { referenceStartDate: referenceCampaign.startDate, referenceEndDate: referenceCampaign.endDate }
	}
}

export const getObjectiveStats = async (
	state,
	{
		organizationId,
		targetOrganizationId,
		type,
		metric,
		campaignId,
		visualId,
		referenceStartDate,
		referenceEndDate,
		referenceCampaignId,
		referenceVisualId,
		startDate,
		deadline,
	}
) => {
	const objectiveType = getObjectiveType(type, metric)
	const campaign = objectiveType.selectors.campaign && campaignId && state.organizations.campaigns[targetOrganizationId]?.find((c) => c.id === campaignId)
	const visual = objectiveType.selectors.visual && visualId && state.organizations.visuals[organizationId]?.find((v) => v.id === visualId)
	const measureEndDate = campaign?.endDate ? (deadline < campaign.endDate ? deadline : campaign.endDate) : deadline

	let stats
	if (objectiveType === objectiveTypes.IMPROVE_SALES) {
		const measuredRequests = getSalesStatsRequests([visual], campaign.startDate, measureEndDate, true)
		const referenceRequests = getSalesStatsRequests([visual], referenceStartDate, referenceEndDate)
		const [measuredData, referenceData] = await Promise.all([measuredRequests, referenceRequests].map((requests) => getVisualsStatsData(requests)))
		stats = {
			referenceStartDate,
			referenceEndDate,
			measureEndDate,
			...computeSalesMetricStats(measuredData, referenceData),
		}
	} else if (objectiveType === objectiveTypes.IMPROVE_ATTENTION_TIME) {
		const referenceCampaign = referenceCampaignId && state.organizations.campaigns[targetOrganizationId].find((c) => c.id === referenceCampaignId)
		const measuredVisuals = getSelectedVisuals(state, organizationId, campaign, visual)
		const measuredRequests = getViewsStatsRequests(measuredVisuals, campaign.startDate, measureEndDate, targetOrganizationId)
		const referenceVisuals = getReferenceVisuals(state, organizationId, referenceCampaign, referenceVisualId)
		const { referenceStartDate: computedReferenceStartDate, referenceEndDate: computedReferenceEndDate } = getReferenceDates(
			referenceStartDate,
			referenceEndDate,
			referenceCampaign
		)
		const referenceRequests = getViewsStatsRequests(referenceVisuals, computedReferenceStartDate, computedReferenceEndDate, targetOrganizationId)
		const [measuredData, referenceData] = await Promise.all([measuredRequests, referenceRequests].map((requests) => getVisualsStatsData(requests)))
		stats = {
			referenceStartDate: computedReferenceStartDate,
			referenceEndDate: computedReferenceEndDate,
			...computeAttentionTimeMetricStats(measuredData, referenceData),
		}
	} else if (objectiveType === objectiveTypes.ROI_SALES) {
		const data = await getVisualsStatsData(getSalesStatsRequests([visual], campaign.startDate, measureEndDate, true))
		stats = {
			measureEndDate,
			cumulatedData: getCumulatedSales(data[0].visualData),
		}
	} else if (objectiveType === objectiveTypes.ROI) {
		const data = await getVisualsStatsData(getSalesStatsRequests(state.organizations.visuals[organizationId], startDate, deadline, true))
		stats = {
			startDate,
			measureEndDate,
			cumulatedData: getCumulatedSales(sumVisualsSales(data)),
		}
	} else if (objectiveType === objectiveTypes.REACH) {
		const visuals = getSelectedVisuals(state, organizationId, campaign, visual)
		const data = await getVisualsStatsData(getViewsStatsRequests(visuals, campaign.startDate, measureEndDate, targetOrganizationId))
		stats = {
			...computeReachStats(data),
		}
	}

	return {
		type,
		metric,
		campaign,
		visual,
		deadline,
		...stats,
	}
}
