export const VISUALS_GET_VISUALS = '@@visuals/GET_VISUALS'
export const VISUALS_GET_VISUALS_SUCCESS = '@@visuals/GET_VISUALS_SUCCESS'
export const VISUALS_GET_VISUALS_FAILURE = '@@visuals/GET_VISUALS_FAILURE'

export const VISUALS_GET_TEMPLATES = '@@visuals/GET_TEMPLATES'
export const VISUALS_GET_TEMPLATES_SUCCESS = '@@visuals/GET_TEMPLATES_SUCCESS'
export const VISUALS_GET_TEMPLATES_FAILURE = '@@visuals/GET_TEMPLATES_FAILURE'

export const VISUALS_GET_VISUAL = '@@visuals/GET_VISUAL'
export const VISUALS_GET_VISUAL_SUCCESS = '@@visuals/GET_VISUAL_SUCCESS'
export const VISUALS_GET_VISUAL_FAILURE = '@@visuals/GET_VISUAL_FAILURE'

export const VISUALS_CREATE_VISUAL = '@@visuals/CREATE_VISUAL'
export const VISUALS_CREATE_VISUAL_SUCCESS = '@@visuals/CREATE_VISUAL_SUCCESS'
export const VISUALS_CREATE_VISUAL_FAILURE = '@@visuals/CREATE_VISUAL_FAILURE'

export const VISUALS_UPDATE_VISUAL = '@@visuals/UPDATE_VISUAL'
export const VISUALS_UPDATE_VISUAL_SUCCESS = '@@visuals/UPDATE_VISUAL_SUCCESS'
export const VISUALS_UPDATE_VISUAL_FAILURE = '@@visuals/UPDATE_VISUAL_FAILURE'

export const VISUALS_DELETE_VISUAL = '@@visuals/DELETE_VISUAL'
export const VISUALS_DELETE_VISUAL_SUCCESS = '@@visuals/DELETE_VISUAL_SUCCESS'
export const VISUALS_DELETE_VISUAL_FAILURE = '@@visuals/DELETE_VISUAL_FAILURE'

export const VISUALS_SET_SVG = '@@visuals/SET_SVG'

export const VISUALS_CLEAR_STATE = '@@visuals/CLEAR_STATE'
