export const genders = ['FEMALE', 'MALE']

export const segmentColors = {
	FEMALE: '#EAA8A2',
	MALE: '#A7BFD3',
	targeted: '#bcdb95', // strong green
	nonTargeted: '#db948e', // strong red
}

export const objectiveColors = {
	measured: '#a0b5c5',
	reference: '#ae9bb4',
	target: { lightMode: 'green', darkMode: 'lawngreen' },
}

export const fillPatternDefs = [
	{
		id: 'dots',
		type: 'patternDots',
		background: 'inherit',
		color: 'rgba(255, 255, 255, 0.3)',
		padding: 2,
		stagger: true,
	},
	{
		id: 'lines',
		type: 'patternLines',
		background: 'inherit',
		color: 'rgba(255, 255, 255, 0.3)',
		rotation: -45,
		lineWidth: 6,
		spacing: 10,
	},
]

export const fillPatterns = [
	{ match: { id: 'MALE' }, id: 'lines' },
	{ match: { id: 'FEMALE' }, id: 'dots' },
]

export const darkerBackgroundTheme = {
	grid: { line: { stroke: 'Silver' } },
}

export const darkModeTheme = {
	textColor: 'rgba(255, 255, 255, 0.8)',
	axis: {
		ticks: { text: { fill: 'rgba(255, 255, 255, 0.8)' } },
		legend: { text: { fill: 'rgba(255, 255, 255, 0.8)' } },
	},
	grid: { line: { stroke: 'DimGray' } },
	tooltip: { container: { color: 'black' } },
	crosshair: {
		line: {
			stroke: 'lightgray',
		},
	},
}
