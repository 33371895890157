import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import last from 'lodash/last'
import debounce from 'lodash/debounce'
import { GButton, GInput, GIcon } from '@alatimier/genesis-uic'
import {
	editorContext,
	ADD_DOCUMENT_COLOR,
	SET_DOCUMENT_COLOR,
	TAB_SHAPE,
	TAB_TEXT,
	SET_COLOR,
	SET_STROKE_WIDTH,
	SET_OPACITY,
	DELETE_DOCUMENT_COLOR,
} from '../editorContext'
import ColorButton from './ColorButton'
import ColorInput from './ColorInput'
import { defaultColors } from '../utils/constants'

const shapeTags = ['circle', 'ellipse', 'rect', 'path']

const RightPanel = () => {
	const { t } = useTranslation()
	const [editorState, dispatchEditorState] = useContext(editorContext)

	const getTagType = () => {
		if (editorState.selectedElement) {
			if (shapeTags.includes(editorState.selectedElement.tagName)) {
				return 'shape'
			} else if (editorState.selectedElement.tagName === 'text') {
				return 'text'
			} else {
				return 'image'
			}
		} else if (editorState.tab === TAB_SHAPE) {
			return 'shape'
		} else if (editorState.tab === TAB_TEXT) {
			return 'text'
		} else {
			return null
		}
	}

	const oldColorTag = useRef(getTagType())
	const menuRef = useRef()
	const [selectedColorType, setSelectedColorType] = useState('fill') // fill or stroke
	const [openMenu, setOpenMenu] = useState(null)

	const setColor = (colorType) => (color) => {
		if (!color) color = '#000000'
		const tagType = getTagType()
		if (tagType === 'shape') editorState.canvas.setColor(colorType, color)
		else {
			editorState.canvas.setFontColor(color)
			editorState.canvas.call('changed')
		}
		dispatchEditorState({ type: SET_COLOR, tagType, colorType, color })
	}

	const setStrokeWidth = (width) => {
		if (!width) width = 0
		editorState.canvas.setStrokeWidth(width)
		dispatchEditorState({ type: SET_STROKE_WIDTH, width })
	}

	const setOpacity = (opacity) => {
		if (!opacity) opacity = 100
		// The "if" is to prevent unnecessary undo stacking
		if (editorState.canvas.getOpacity() !== opacity / 100) {
			editorState.canvas.setOpacity(opacity / 100)
			dispatchEditorState({ type: SET_OPACITY, opacity })
			editorState.canvas.call('changed')
		}
	}

	const updateTagAndColorType = () => {
		const newTag = getTagType()
		if (oldColorTag.current !== newTag) {
			setSelectedColorType('fill')
			oldColorTag.current = newTag
		}
	}

	const shouldGetColorFrom = (elementTagName) => {
		return shapeTags.includes(elementTagName) || elementTagName === 'text'
	}

	// Adapt properties to those of the selected element and compute color type and tag type
	useEffect(() => {
		if (editorState.selectedElement) {
			if (shouldGetColorFrom(editorState.selectedElement.tagName)) {
				setColor('fill')(editorState.selectedElement.getAttribute('fill'))
				if (editorState.selectedElement.tagName !== 'text') {
					setColor('stroke')(editorState.selectedElement.getAttribute('stroke'))
					setStrokeWidth(editorState.selectedElement.getAttribute('stroke-width'))
				}
			}
			setOpacity((editorState.selectedElement.getAttribute('opacity') ?? 1) * 100)
		}
		updateTagAndColorType()
	}, [editorState.selectedElement])

	useEffect(updateTagAndColorType, [editorState.tab])

	useEffect(() => {
		const hideMenu = () => setOpenMenu(null)
		document.addEventListener('click', hideMenu)
		return () => document.removeEventListener('click', hideMenu)
	}, [])

	const addColor = () => {
		const newColorInput = document.querySelector('input#newColor')
		newColorInput.click()
		dispatchEditorState({ type: ADD_DOCUMENT_COLOR, color: newColorInput.value })
	}

	const setNewColor = (color) => dispatchEditorState({ type: SET_DOCUMENT_COLOR, color })

	const displayDocumentColorMenu = (id) => (e) => {
		e.preventDefault()
		// Place menu at left of mouse if it would be hidden by the screen edge
		if (e.clientX + menuRef.current.offsetWidth > window.innerWidth) {
			e.clientX = e.clientX - menuRef.current.offsetWidth
		}
		setOpenMenu({ id, x: e.clientX, y: e.clientY })
	}
	const deleteDocumentColor = () => {
		dispatchEditorState({ type: DELETE_DOCUMENT_COLOR, id: openMenu.id })
	}

	const tagType = getTagType()
	const opacity = ((editorState.canvas?.getOpacity() ?? 0) * 100).toLocaleString(undefined, { maximumFractionDigits: 0 })

	if (!tagType) return null

	return (
		<div className="rightPanel px-3 py-3 position-absolute end-0 h-100">
			<h1 className="small text-center">{t(`editor.legend.${tagType}`)}</h1>
			{(tagType === 'shape' || tagType === 'text') && (
				<>
					<div className="my-3">
						{tagType === 'shape' && (
							<>
								<ColorInput
									colorType="fill"
									legend={t('editor.legend.color.fill')}
									value={editorState.color.shape.fill}
									selected={selectedColorType === 'fill'}
									setSelectedColorType={setSelectedColorType}
									onChange={setColor}
								/>
								<ColorInput
									colorType="stroke"
									legend={t('editor.legend.color.stroke')}
									value={editorState.color.shape.stroke}
									selected={selectedColorType === 'stroke'}
									setSelectedColorType={setSelectedColorType}
									onChange={setColor}
								/>
							</>
						)}
						{tagType === 'text' && (
							<ColorInput colorType="fill" legend={t('editor.legend.color')} value={editorState.color.text.fill} onChange={setColor} />
						)}
					</div>
					<div className="my-3">
						<h2 className="h6 mb-2">{t('editor.legend.color.document')}</h2>
						<div className="colorCategory position-relative">
							<GButton className="color bg-dark" icon="fa-plus" onClick={addColor} title={t('editor.action.color.new')} />
							<GInput
								id="newColor"
								type="color"
								value={last(editorState.documentColors.list)?.color}
								onChange={debounce(setNewColor, 200)}
								inputClassName="hiddenColorInput"
								simple
							/>
							{editorState.documentColors.list.map(({ id, color }) => (
								<ColorButton key={id} color={color} onClick={setColor(selectedColorType)} onContextMenu={displayDocumentColorMenu(id)} />
							))}
						</div>
					</div>
					<div className="my-3">
						<h2 className="h6 mb-2">{t('editor.legend.color.default')}</h2>
						<div className="colorCategory">
							{defaultColors.map((color, index) => (
								<ColorButton key={index} color={color} onClick={setColor(selectedColorType)} />
							))}
						</div>
					</div>
					{tagType === 'shape' && (
						<div className="my-3">
							<label className="h6 mb-2" htmlFor="strokeWidthInput">
								{t('editor.legend.stroke.width')}
							</label>
							<div className="d-flex align-items-center">
								<GInput
									id="strokeWidthInput"
									type="range"
									rangeMin={0}
									rangeMax={20}
									title={editorState.strokeWidth.toString()}
									value={editorState.strokeWidth}
									onChange={setStrokeWidth}
									simple
								/>
								<span className="pb-1 text-end" style={{ width: '2em' }}>
									{editorState.strokeWidth}
								</span>
							</div>
						</div>
					)}
				</>
			)}
			<div className="my-3">
				<label className="h6 mb-2" htmlFor="opacityInput">
					{t('editor.legend.opacity')}
				</label>
				<div className="d-flex align-items-center">
					<GInput
						id="opacityInput"
						type="range"
						rangeMin={0}
						rangeMax={100}
						title={opacity.toString()}
						value={opacity}
						onChange={setOpacity}
						simple
					/>
					<span className="pb-1 text-end" style={{ width: '2em' }}>
						{opacity}
					</span>
				</div>
			</div>
			<div ref={menuRef} role="menu" className={`contextMenu ${openMenu ? '' : 'invisible'}`} style={{ top: openMenu?.y, left: openMenu?.x }}>
				<button onClick={deleteDocumentColor}>
					{t('editor.action.delete')}
					<GIcon name="fa-trash" className="ms-3" />
				</button>
			</div>
		</div>
	)
}

export default RightPanel
