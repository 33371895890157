import PropTypes from 'prop-types'
import { GInput } from '@alatimier/genesis-uic'
import debounce from 'lodash/debounce'

const ColorInput = (props) => {
	const className = props.selected !== undefined ? `px-1 py-2 ${props.selected === true ? 'selected' : ''}` : ''

	const selectField = () => props.setSelectedColorType && props.setSelectedColorType(props.colorType)

	return (
		<label className={`colorInput ${className}`} htmlFor={props.colorType} onClick={selectField}>
			<div className="d-flex align-items-center mb-2">
				{props.selected !== undefined && (
					<input id={props.colorType} type="radio" name="colorType" className="me-2" checked={props.selected} readOnly />
				)}
				<div className="h6">{`${props.legend} :`}</div>
			</div>
			<div className="d-flex align-items-center">
				<GInput
					id="colorInput"
					type="color"
					value={props.value}
					onChange={debounce(props.onChange(props.colorType), 200)}
					title={props.legend}
					simple
					inputClassName="me-1"
				/>
				<GInput id="colorInput" type="text" value={props.value} onChange={props.onChange(props.colorType)} simple />
			</div>
		</label>
	)
}

ColorInput.propTypes = {
	colorType: PropTypes.string.isRequired,
	legend: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	selected: PropTypes.bool,
	setSelectedColorType: PropTypes.func,
	onChange: PropTypes.func.isRequired,
}

export default ColorInput
