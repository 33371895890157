import { useContext } from 'react'
import PropTypes from 'prop-types'
import { GButton } from '@alatimier/genesis-uic'
import { editorContext } from '../../editorContext'
import { setMode } from '../../utils/actions'
import paths from '../../utils/shapes/paths'

export const shapeSize = 300

const ShapeButton = (props) => {
	const [editorState, dispatchEditorState] = useContext(editorContext)

	const humanReadable = (name) => name.charAt(0).toUpperCase() + name.slice(1).replaceAll('_', ' ')

	const clickShape = () => {
		setMode(editorState, dispatchEditorState, props.mode)
	}

	const off = shapeSize * 0.05
	const vb = [-off, -off, shapeSize + off * 2, shapeSize + off * 2].join(' ')
	const svgImage = `
		<svg xmlns="http://www.w3.org/2000/svg">
			<svg
				viewBox="${vb}"
				fill="${editorState.color.shape.fill.replace('#', '%23')}"
				stroke="${editorState.color.shape.stroke.replace('#', '%23')}"
				stroke-width="${props.minStrokeWidth ? Math.max(editorState.strokeWidth, props.minStrokeWidth) : editorState.strokeWidth}"
			>
				${props.svg ?? `<path d="${paths[props.mode]}" />`}
			</svg>
		</svg>`

	return (
		<GButton
			color="link"
			className="text-secondary px-0 mx-3 my-2"
			label={<img src={`data:image/svg+xml;utf8,${svgImage}`} />}
			onClick={clickShape}
			title={props.title ?? humanReadable(props.mode)}
		/>
	)
}

ShapeButton.propTypes = {
	mode: PropTypes.string.isRequired,
	svg: PropTypes.string,
	minStrokeWidth: PropTypes.number,
	title: PropTypes.string,
}

export default ShapeButton
