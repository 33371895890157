import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { getTags } from '../../store/tag/tagActions'
import { GSelect } from '@alatimier/genesis-uic'

const TagSelect = (props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [value, setValue] = useState([])

	const tags = useSelector((state) => state.tags.list)
	const loading = useSelector((state) => state.tags.pending)

	useEffect(() => {
		dispatch(getTags())
	}, [])

	useEffect(() => {
		setValue(mapToOptions(props.value))
	}, [props.value])

	const debouncedGetTags = _.debounce((inputValue) => dispatch(getTags(inputValue)), 500)
	const handleInputChange = (inputValue) => {
		debouncedGetTags(inputValue)
	}

	const handleChange = (selectedTags) => {
		props.onChange(selectedTags.map((v) => v.value))
	}

	const mapToOptions = (strArray) =>
		strArray.map((e) => ({
			value: e,
			label: e,
		}))

	return (
		<GSelect
			id={props.id}
			name={props.name}
			label={t('tags.form.label')}
			labelPosition={props.labelPosition}
			placeholder={props.placeholder}
			value={value}
			options={mapToOptions(tags)}
			loading={loading}
			creatable
			multiple
			clearable
			onInputChange={handleInputChange}
			onChange={handleChange}
			onBlur={props.onBlur}
			help={props.help}
			error={props.error}
			required={props.required}
			disabled={props.disabled}
			className={props.className}
		/>
	)
}

TagSelect.defaultProps = {
	value: [],
}

TagSelect.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string,
	labelPosition: PropTypes.oneOf(['left', 'up']),
	placeholder: PropTypes.string,
	value: PropTypes.arrayOf(PropTypes.string),
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func,
	help: PropTypes.string,
	error: PropTypes.string,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	className: PropTypes.string,
}

export default TagSelect
