import { apiClientService as client } from '@alatimier/genesis-uic'
import { WEATHERS_GET_WEATHERS, WEATHERS_GET_WEATHERS_FAILURE, WEATHERS_GET_WEATHERS_SUCCESS } from './weathersTypes'

const weathersEndPoint = '/api/admin/v1/weathers'

export const getWeathers = () => {
	return (dispatch) => {
		dispatch({ type: WEATHERS_GET_WEATHERS })
		return client.get(weathersEndPoint).then(async (res) => {
			if (res.ok) {
				const data = await res.json()
				dispatch({
					type: WEATHERS_GET_WEATHERS_SUCCESS,
					list: data,
				})
			} else {
				dispatch({
					type: WEATHERS_GET_WEATHERS_FAILURE,
				})
			}
		})
	}
}
