import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { GSelect, isEmpty } from '@alatimier/genesis-uic'
import { getSpaces } from '../../store/organizations/organizationsActions'

const OrganizationSpacesSelect = (props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [options, setOptions] = useState([])
	const [selectedOptions, setSelectedOptions] = useState([])
	const [loading, setLoading] = useState(true)

	const organizationSpaces = useSelector((state) => state.organizations.spaces[props.organizationId])
	const organizationPending = useSelector((state) => state.organizations.pending)
	const spaces = useMemo(
		() => organizationSpaces?.filter((space) => !props.filteredSpaceIds || props.filteredSpaceIds.includes(space.id)) ?? [],
		[organizationSpaces, props.filteredSpaceIds]
	)

	useEffect(() => fetchOrganizationSpaces(), [props.organizationId])
	useEffect(() => buildOptions(), [spaces])
	useEffect(() => initSelectedOptions(), [props.value, options])

	const fetchOrganizationSpaces = () => {
		if (props.organizationId) {
			setLoading(true)
			setOptions([])
			setSelectedOptions([])
			dispatch(getSpaces(props.organizationId))
		}
	}

	const buildOptions = () => {
		if (organizationSpaces && !organizationPending) setLoading(false)
		setOptions(spaces.map((s) => ({ value: s.id.toString(), label: s.name })))
	}

	const initSelectedOptions = () => {
		if (props.value && !loading) {
			const newSelectedOptions = options.filter((option) => {
				if (props.multiple) {
					return props.value.some((spaceId) => spaceId.toString() === option.value)
				} else {
					return props.value.toString() === option.value
				}
			})
			const selectedOptionsChanged = newSelectedOptions.length !== selectedOptions.length
			const organizationChanged = isEmpty(newSelectedOptions) && !isEmpty(props.value)
			if (selectedOptionsChanged || organizationChanged) {
				handleChange(props.multiple ? newSelectedOptions : newSelectedOptions[0])
				setSelectedOptions(newSelectedOptions)
			}
		}
	}

	const handleChange = (selected) => {
		if (props.multiple) {
			props.onChange(selected.map((s) => parseInt(s.value)))
		} else {
			props.onChange(selected ? parseInt(selected.value) : undefined)
		}
	}

	if (props.autoHide && isEmpty(options)) {
		return <></>
	}

	return (
		<GSelect
			id={props.id}
			name={props.name}
			label={props.multiple ? t('forms.spaces') : t('forms.space')}
			labelPosition={props.labelPosition}
			placeholder={props.placeholder}
			value={selectedOptions}
			options={options}
			loading={loading}
			multiple={props.multiple}
			clearable
			onChange={handleChange}
			onBlur={props.onBlur}
			error={props.error}
			required={props.required}
			disabled={props.disabled}
			className={props.className}
		/>
	)
}

OrganizationSpacesSelect.propTypes = {
	id: PropTypes.string.isRequired,
	organizationId: PropTypes.number,
	filteredSpaceIds: PropTypes.arrayOf(PropTypes.number),
	name: PropTypes.string,
	labelPosition: PropTypes.oneOf(['left', 'up']),
	placeholder: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func,
	error: PropTypes.string,
	multiple: PropTypes.bool,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	autoHide: PropTypes.bool,
	className: PropTypes.string,
}

export default OrganizationSpacesSelect
