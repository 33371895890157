import { apiClientService as client, isEmpty } from '@alatimier/genesis-uic'
import { STATISTICS_GET_STATISTICS, STATISTICS_GET_STATISTICS_SUCCESS, STATISTICS_GET_STATISTICS_FAILURE } from './statisticsTypes'

const statisticsEndPoint = '/api/admin/v1/statistics'

const getVisuals = (state, dashboardName, organizationId, campaign, visualIds) => {
	const organizationVisuals = state.organizations.visuals[organizationId]
	if (dashboardName === 'audience') return organizationVisuals
	if (isEmpty(visualIds) && campaign) visualIds = campaign.visualIds
	return organizationVisuals.filter((v) => visualIds.includes(v.id))
}

export const getStatistics = (dashboardName, organizationId, targetOrganizationId, campaignId, visualIds, spaceIds, deviceIds, from = '', to = '') => {
	return (dispatch, getState) => {
		dispatch({ type: STATISTICS_GET_STATISTICS })
		const state = getState()
		const campaign = state.organizations.campaigns[targetOrganizationId]?.find((c) => c.id === campaignId)
		const visuals = getVisuals(state, dashboardName, organizationId, campaign, visualIds)
		const queryParams = new URLSearchParams({ from, to, spaceIds, deviceIds, organizationId: targetOrganizationId })
		const promises = visuals.map((visual) =>
			client.get(`${statisticsEndPoint}/visual/${visual.id}?${queryParams.toString()}`).then((res) => ({ visual, res }))
		)
		return Promise.all(promises).then(async (results) => {
			if (results.every((r) => r.res.ok)) {
				const visualsData = await Promise.all(
					results.map(async ({ visual, res }) => ({
						visual,
						visualData: await res.json(),
					}))
				)
				dispatch({
					type: STATISTICS_GET_STATISTICS_SUCCESS,
					filters: {
						from,
						to,
						campaign,
						visuals,
						spaces: state.organizations.spaces[targetOrganizationId]?.filter((s) => spaceIds.includes(s.id)),
						devices: state.organizations.devices[targetOrganizationId]?.filter((d) => deviceIds.includes(d.id)),
						targetOrganization: state.g_organizations.list?.flatMap((o) => [o, ...(o.children || [])]).find((o) => o.id === targetOrganizationId),
					},
					data: { [dashboardName]: visualsData },
				})
			} else {
				dispatch({ type: STATISTICS_GET_STATISTICS_FAILURE })
			}
		})
	}
}
