export const SEGMENTS_GET_SEGMENTS = '@@segments/GET_SEGMENTS'
export const SEGMENTS_GET_SEGMENTS_SUCCESS = '@@segments/GET_SEGMENTS_SUCCESS'
export const SEGMENTS_GET_SEGMENTS_FAILURE = '@@segments/GET_SEGMENTS_FAILURE'

export const SEGMENTS_GET_SEGMENT = '@@segments/GET_SEGMENT'
export const SEGMENTS_GET_SEGMENT_SUCCESS = '@@segments/GET_SEGMENT_SUCCESS'
export const SEGMENTS_GET_SEGMENT_FAILURE = '@@segments/GET_SEGMENT_FAILURE'

export const SEGMENTS_CREATE_SEGMENT = '@@segments/CREATE_SEGMENT'
export const SEGMENTS_CREATE_SEGMENT_SUCCESS = '@@segments/CREATE_SEGMENT_SUCCESS'
export const SEGMENTS_CREATE_SEGMENT_FAILURE = '@@segments/CREATE_SEGMENT_FAILURE'

export const SEGMENTS_UPDATE_SEGMENT = '@@segments/UPDATE_SEGMENT'
export const SEGMENTS_UPDATE_SEGMENT_SUCCESS = '@@segments/UPDATE_SEGMENT_SUCCESS'
export const SEGMENTS_UPDATE_SEGMENT_FAILURE = '@@segments/UPDATE_SEGMENT_FAILURE'

export const SEGMENTS_DELETE_SEGMENT = '@@segments/DELETE_SEGMENT'
export const SEGMENTS_DELETE_SEGMENT_SUCCESS = '@@segments/DELETE_SEGMENT_SUCCESS'
export const SEGMENTS_DELETE_SEGMENT_FAILURE = '@@segments/DELETE_SEGMENT_FAILURE'

export const SEGMENTS_GET_GENDERS = '@@segments/GET_GENDERS'
export const SEGMENTS_GET_GENDERS_SUCCESS = '@@segments/GET_GENDERS_SUCCESS'
export const SEGMENTS_GET_GENDERS_FAILURE = '@@segments/GET_GENDERS_FAILURE'

export const SEGMENTS_GET_CLOTHES = '@@segments/GET_CLOTHES'
export const SEGMENTS_GET_CLOTHES_SUCCESS = '@@segments/GET_CLOTHES_SUCCESS'
export const SEGMENTS_GET_CLOTHES_FAILURE = '@@segments/GET_CLOTHES_FAILURE'

export const SEGMENTS_CLEAR_STATE = '@@segments/CLEAR_STATE'
