import { useTranslation } from 'react-i18next'
import { GButton } from '@alatimier/genesis-uic'
import { TAB_SHAPE } from '../../editorContext'
import ShapeButton, { shapeSize } from './ShapeButton'

const ShapePanel = () => {
	const { t } = useTranslation()

	return (
		<>
			<h1 className="h5 ms-2 mb-4">
				<span>{t(`editor.tab.${TAB_SHAPE}`)}</span>
				<GButton icon="fa-question-circle" color="link" tooltip={t('editor.shape.help')} tooltipPosition="right" className="text-info" />
			</h1>
			<div className="shapePanel">
				<ShapeButton mode="line" svg={`<line x1="0" x2="${shapeSize}" y1="0" y2="${shapeSize}" />`} minStrokeWidth={1} title={t('editor.shape.line')} />
				<ShapeButton
					mode="circle"
					svg={`<circle cx="${shapeSize / 2}" cy="${shapeSize / 2}" r="${shapeSize / 2}" />`}
					title={t('editor.shape.circle')}
				/>
				<ShapeButton
					mode="ellipse"
					svg={`<ellipse cx="${shapeSize / 2}" cy="${shapeSize / 2}" rx="${shapeSize / 2}" ry="${shapeSize / 3}" />`}
					title={t('editor.shape.ellipse')}
				/>
				<ShapeButton mode="square" svg={`<rect width="${shapeSize}" height="${shapeSize}" />`} title={t('editor.shape.square')} />
				<ShapeButton
					mode="rect"
					svg={`<rect width="${shapeSize}" height="${shapeSize / 1.5}" y="${shapeSize / 5}" />`}
					title={t('editor.shape.rectangle')}
				/>
				<ShapeButton mode="triangle" />
				<ShapeButton mode="star" />
				<ShapeButton mode="pentagon" />
				<ShapeButton mode="dialog_balloon" />
				<ShapeButton mode="smiley" minStrokeWidth={1} />
				<ShapeButton mode="arrow_turn" />
			</div>
		</>
	)
}

export default ShapePanel
