import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { memo } from 'react'
import { ResponsiveStream } from '@nivo/stream'
import { fillPatternDefs, segmentColors, fillPatterns, darkModeTheme, genders } from '../../../utils/ChartUtils'
import { GCard, GMessage, isEmpty } from '@alatimier/genesis-uic'
import { DateTime } from 'luxon'
import { useSelector } from 'react-redux'

const PopulationRepartitionChart = memo((props) => {
	const { t } = useTranslation()

	const isDarkMode = useSelector((store) => store.g_display.darkMode)

	// Remove date ticks when they are too many (more than 15)
	const dateTickStep = Math.ceil(props.dates.length / 15)

	let dataIsEmpty = true
	const ageKeySet = new Set()
	const initialDateData = genders.reduce((dateData, segment) => ({ ...dateData, [segment]: 0 }), {})
	const computedData = props.dates.map((date) => ({ ...initialDateData, date }))
	if (!isEmpty(props.data)) {
		for (const { visualData } of props.data) {
			if (!visualData.histogram) continue
			for (const date of props.dates) {
				if (visualData.histogram[date] && visualData.histogram[date].views) {
					const computedDataDate = computedData.find((d) => d.date === date)
					for (const dataKey of ['ages', 'genders']) {
						if (visualData.histogram[date].views.global[dataKey]) {
							for (const [key, value] of Object.entries(visualData.histogram[date].views.global[dataKey])) {
								if (value) {
									if (computedDataDate[key]) computedDataDate[key] += value
									else computedDataDate[key] = value
									if (dataKey === 'ages') ageKeySet.add(key)
									dataIsEmpty = false
								}
							}
						}
					}
				}
			}
		}
	}
	const ageKeys = Array.from(ageKeySet).sort()
	const ageInitialData = ageKeys.reduce((data, age) => ({ ...data, [age]: 0 }), {})
	computedData.forEach((dateData, index, data) => {
		data[index] = { ...ageInitialData, ...dateData }
	})

	const chartProperties = {
		margin: { top: 10, right: 100, bottom: 20, left: 60 },
		axisBottom: {
			format: (index) =>
				index % dateTickStep === 0 && computedData[index] ? DateTime.fromISO(computedData[index].date).toLocaleString(props.dateFormat) : '',
		},
		axisLeft: { format: (e) => Math.floor(e) === e && e, legend: t('dashboard.populationRepartition.legend'), legendPosition: 'middle', legendOffset: -50 },
		offsetType: 'none',
		curve: 'stepAfter',
		defs: fillPatternDefs,
		fill: fillPatterns,
		legends: [
			{
				anchor: 'bottom-right',
				direction: 'column',
				translateX: 90,
				itemWidth: 80,
				itemHeight: 20,
				itemTextColor: '#777777',
				symbolSize: 12,
				symbolShape: 'circle',
				effects: [{ on: 'hover', style: { itemTextColor: '#000000', symbolSize: 15 } }],
			},
		],
		tooltip: ({ layer: { label, color } }) => <div style={{ padding: 12, color, background: '#222222', borderRadius: '8px' }}>{label}</div>,
		stackTooltip: ({ slice: { index, stack } }) => {
			const dateTotalViews = stack.reduce((sum, { value }) => sum + value, 0)
			return (
				<div style={{ padding: 12, color: 'white', background: '#222222', borderRadius: '8px' }}>
					{DateTime.fromISO(computedData[index].date).toLocaleString(props.dateFormat)}
					<br />
					{t('dashboard.tooltip.total')}: {dateTotalViews}
					<br />
					{stack.map(({ layerId, layerLabel, value, color }) => (
						<div key={layerId} className="d-flex align-items-center" style={{ color }}>
							<div style={{ width: '10px', height: '10px', backgroundColor: color, marginRight: '5px' }}></div>
							{layerLabel}: {value}
							{value ? ` (${Math.round((value / dateTotalViews) * 100)} %)` : null}
							<br />
						</div>
					))}
				</div>
			)
		},
		theme: isDarkMode ? darkModeTheme : undefined,
	}

	return (
		<GCard row type="collapsable" title={props.title}>
			{dataIsEmpty ? (
				<GMessage value={t('dashboard.nodata')} />
			) : (
				<>
					<div className="row mb-5" style={{ height: '270px' }} data-testid="PopulationRepartitionGenders">
						<ResponsiveStream
							data={computedData}
							{...chartProperties}
							keys={genders}
							label={({ id }) => t(`gender.${id}.plural`)}
							colors={({ id }) => segmentColors[id]}
						/>
					</div>
					<div className="row" style={{ height: '270px' }} data-testid="PopulationRepartitionAges">
						<ResponsiveStream data={computedData} {...chartProperties} keys={ageKeys} colors={{ scheme: 'pastel1' }} />
					</div>
				</>
			)}
		</GCard>
	)
})

PopulationRepartitionChart.propTypes = {
	title: PropTypes.string.isRequired,
	data: PropTypes.array,
	dates: PropTypes.array.isRequired,
	dateFormat: PropTypes.object.isRequired,
}

export default PopulationRepartitionChart
