import PropTypes from 'prop-types'
import { ResponsiveBar } from '@nivo/bar'
import { segmentColors, darkModeTheme, fillPatternDefs, fillPatterns, genders } from '../../../utils/ChartUtils'
import { useTranslation } from 'react-i18next'
import { isEmpty, GCard } from '@alatimier/genesis-uic'
import { memo } from 'react'
import { useSelector } from 'react-redux'

const AttentionTimeChart = memo((props) => {
	const { t } = useTranslation()

	const isDarkMode = useSelector((store) => store.g_display.darkMode)

	const computedData = []
	const ageKeySet = new Set()
	if (!isEmpty(props.data)) {
		for (const {
			visual,
			visualData: { global },
		} of props.data) {
			if (!global.attentionTime || !global.attentionTime.global) continue
			const visualDataResult = {
				visual: visual.shortcut,
				targeted: global.attentionTime.targeted?.average,
				nonTargeted: global.attentionTime.nonTargeted?.average,
			}
			if (global.attentionTime.global.ages) {
				for (const [key, value] of Object.entries(global.attentionTime.global.ages)) {
					if (value) {
						visualDataResult[key] = value
						ageKeySet.add(key)
					}
				}
			}
			if (global.attentionTime.global.genders) {
				for (const [key, value] of Object.entries(global.attentionTime.global.genders)) {
					if (value) visualDataResult[key] = value
				}
			}
			if (Object.keys(visualDataResult) > 3 || visualDataResult.targeted || visualDataResult.nonTargeted) {
				computedData.push(visualDataResult)
			}
		}
	}
	const ageKeys = Array.from(ageKeySet).sort()

	const commonBarProperties = {
		indexBy: 'visual',
		padding: computedData.length === 1 ? 0.05 : 0.15,
		colors: ({ id }) => segmentColors[id],
		valueFormat: (value) => `${Number(value).toLocaleString(undefined, { maximumFractionDigits: 3 })}${t('dashboard.attentionTime.unit.short')}`,
		labelSkipHeight: 5,
		axisBottom: computedData.length === 1 ? { tickSize: 0, tickPadding: 10 } : { tickRotation: -90 },
		theme: isDarkMode ? darkModeTheme : undefined,
	}

	const completeAxisLeft = {
		tickValues: 7,
		legend: `${t('dashboard.attentionTime.title')} (${t('dashboard.attentionTime.unit')})`,
		legendPosition: 'middle',
		legendOffset: -40,
	}

	// The three charts are aligned for one visual and superposed (2-1) for several visuals
	return isEmpty(computedData) ? null : (
		<GCard row type="collapsable" title={props.title}>
			<div className="row" data-testid="AttentionTime">
				<div className={computedData.length === 1 ? 'col-md-3' : 'col-md-12'} style={{ height: '350px' }}>
					<ResponsiveBar
						data={computedData}
						{...commonBarProperties}
						keys={['targeted', 'nonTargeted']}
						groupMode="grouped"
						margin={{ top: 20, right: 10, bottom: computedData.length === 1 ? 30 : 90, left: 45 }}
						label={({ id }) => t(`dashboard.${id}`)}
						labelSkipWidth={52}
						axisLeft={completeAxisLeft}
						tooltip={({ indexValue, id, formattedValue, color }) => (
							<div style={{ padding: 12, color, background: '#222222', borderRadius: '8px' }}>
								{indexValue} - {t(`dashboard.${id}`)}: {formattedValue}
							</div>
						)}
					/>
				</div>
				<div className={computedData.length < 16 ? 'col-md-6' : 'col-md-12'} style={{ height: '350px' }}>
					<ResponsiveBar
						data={computedData}
						{...commonBarProperties}
						keys={ageKeys}
						groupMode={computedData.length === 1 ? 'grouped' : 'stacked'}
						margin={{ top: 20, right: 10, bottom: computedData.length === 1 ? 30 : 90, left: computedData.length === 1 ? 45 : 10 }}
						colors={{ scheme: 'pastel1' }}
						label="id"
						labelSkipWidth={30}
						axisLeft={computedData.length === 1 ? completeAxisLeft : null}
						tooltip={({ indexValue, id, formattedValue, color }) => (
							<div style={{ padding: 12, color, background: '#222222', borderRadius: '8px' }}>
								{indexValue} - {id}: {formattedValue}
							</div>
						)}
					/>
				</div>
				<div className={computedData.length === 1 ? 'col-md-3' : computedData.length < 16 ? 'col-md-6' : 'col-md-12'} style={{ height: '350px' }}>
					<ResponsiveBar
						data={computedData}
						{...commonBarProperties}
						keys={genders}
						groupMode={computedData.length === 1 ? 'grouped' : 'stacked'}
						margin={{ top: 20, right: 10, bottom: computedData.length === 1 ? 30 : 90, left: computedData.length === 1 ? 45 : 10 }}
						label={({ id }) => t(`gender.${id}.plural`)}
						labelSkipWidth={45}
						defs={fillPatternDefs}
						fill={fillPatterns}
						axisLeft={computedData.length === 1 ? completeAxisLeft : null}
						tooltip={({ indexValue, id, formattedValue, color }) => (
							<div style={{ padding: 12, color, background: '#222222', borderRadius: '8px' }}>
								{indexValue} - {t(`gender.${id}.plural`)}: {formattedValue}
							</div>
						)}
					/>
				</div>
			</div>
		</GCard>
	)
})

AttentionTimeChart.propTypes = {
	title: PropTypes.string.isRequired,
	data: PropTypes.array,
}

export default AttentionTimeChart
