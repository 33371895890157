import { useContext } from 'react'
import { editorContext, TAB_SHAPE, TAB_TEXT, TAB_VISUAL, TAB_IMAGES, DESELECT_FIGURE } from '../editorContext'
import ShapePanel from './ShapePanel/ShapePanel'
import TextPanel from './TextPanel/TextPanel'
import VisualPanel from './VisualPanel/VisualPanel'
import ImagesPanel from './ImagesPanel/ImagesPanel'
import GeneralTools from './GeneralTools/GeneralTools'

const LeftPanel = () => {
	const [editorState, dispatchEditorState] = useContext(editorContext)

	const handlePanelClick = (e) => {
		if (!['IMG', 'FIGURE', 'FIGCAPTION'].includes(e.target.tagName)) {
			dispatchEditorState({ type: DESELECT_FIGURE })
		}
	}

	const renderPanel = () => {
		switch (editorState.tab) {
			case TAB_SHAPE:
				return <ShapePanel />
			case TAB_TEXT:
				return <TextPanel />
			case TAB_IMAGES:
				return <ImagesPanel />
			case TAB_VISUAL:
				return <VisualPanel />
			default:
				throw new Error('Unknown editor tab')
		}
	}

	return (
		<div className="leftPanel position-absolute h-100" onClick={handlePanelClick}>
			<div className="px-4 py-3">{renderPanel()}</div>
			<GeneralTools />
		</div>
	)
}

export default LeftPanel
