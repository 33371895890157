import PropTypes from 'prop-types'
import React, { memo } from 'react'
import { ResponsiveCirclePacking } from '@nivo/circle-packing'
import { ResponsiveStream } from '@nivo/stream'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { GCard, GMessage, isEmpty } from '@alatimier/genesis-uic'
import { useSelector } from 'react-redux'
import { darkModeTheme } from '../../../utils/ChartUtils'

const CirclePackingLabel = ({ label, node }) => {
	return (
		<text
			x={node.x}
			y={node.y}
			textAnchor="middle"
			dominantBaseline="central"
			style={{
				fontSize: node.path.length === 1 ? 'max(1.0vw, 25px)' : 'max(0.3vw, 9px)',
				fontWeight: 600,
			}}
		>
			{label}
		</text>
	)
}

const DisplayVolumeChart = memo((props) => {
	const { t } = useTranslation()

	const isDarkMode = useSelector((store) => store.g_display.darkMode)

	const computedData = { global: {}, timed: [] }
	let totalDisplays = 0
	const visualKeys = props.data?.map(({ visual }) => visual.shortcut)
	if (!isEmpty(props.data)) {
		const displayVolume = []
		for (const { visual, visualData } of props.data) {
			if (!visualData.global.displays) continue
			totalDisplays += visualData.global.displays
			displayVolume.push({ shortcut: visual.shortcut, name: visual.name, value: visualData.global.displays })
		}
		if (displayVolume.length === 1) {
			computedData.global = displayVolume[0]
		} else {
			computedData.global = {
				shortcut: t('dashboard.displayVolume.all'),
				name: t('dashboard.displayVolume.all'),
				children: displayVolume,
			}
		}
		const visualsData = visualKeys.reduce((visualsData, visual) => ({ ...visualsData, [visual]: 0 }), {})
		computedData.timed = props.dates.map((date) => ({ date, ...visualsData }))
		for (const { visual, visualData } of props.data) {
			if (!visualData.histogram) continue
			for (const date of props.dates) {
				if (visualData.histogram[date]) {
					computedData.timed.find((d) => d.date === date)[visual.shortcut] = visualData.histogram[date].displays
				}
			}
		}
	}

	// Remove date ticks when they are too many
	const dateTickStep = Math.ceil(props.dates.length / 15)

	const circlePackingProperties = {
		margin: { top: 5, right: 5, bottom: 5, left: 5 },
		colors: { scheme: isDarkMode ? 'paired' : 'nivo' },
		valueFormat: '>-',
		enableLabels: true,
		label: computedData.global.children ? 'id' : (d) => `${d.data.name}: ${d.value}`,
		labelsFilter: ({ node }) => node.depth === 1 || !computedData.global.children,
		labelComponent: CirclePackingLabel,
		tooltip: ({ value, color, data: { name } }) => (
			<div style={{ padding: 12, color, background: '#222222', borderRadius: '8px' }}>
				{name}: {value} ({Math.round((value / totalDisplays) * 100)} %)
			</div>
		),
	}

	const timedChartProperties = {
		margin: { top: 10, right: 125, bottom: 20, left: 60 },
		colors: { scheme: isDarkMode ? 'paired' : 'nivo' },
		axisBottom: {
			format: (index) =>
				index % dateTickStep === 0 && computedData.timed[index]
					? DateTime.fromISO(computedData.timed[index].date).toLocaleString(props.dateFormat)
					: '',
		},
		axisLeft: {
			format: (e) => Math.floor(e) === e && e,
			legend: t('dashboard.displayVolume.legend'),
			legendPosition: 'middle',
			legendOffset: -50,
		},
		offsetType: 'none',
		curve: 'stepAfter',
		legends: [
			{
				anchor: 'bottom-right',
				direction: 'column',
				translateX: 125,
				itemWidth: 100,
				itemHeight: 20,
				itemTextColor: isDarkMode ? 'rgba(255, 255, 255, 0.8)' : '#777777',
				symbolSize: 12,
				symbolShape: 'circle',
				effects: [{ on: 'hover', style: { itemTextColor: isDarkMode ? 'white' : 'black', symbolSize: 15 } }],
			},
		],
		label: (layer) => {
			return props.data?.find(({ visual }) => visual.shortcut === layer.id)?.visual.name
		},
		tooltip: ({ layer: { label, color } }) => <div style={{ padding: 12, color, background: '#222222', borderRadius: '8px' }}>{label}</div>,
		stackTooltip: ({ slice: { index, stack } }) => {
			const dateTotalDisplays = stack.reduce((sum, { value }) => sum + value, 0)
			return (
				<div style={{ padding: 12, color: 'white', background: '#222222', borderRadius: '8px' }}>
					{DateTime.fromISO(computedData.timed[index].date).toLocaleString(props.dateFormat)}
					<br />
					{t('dashboard.tooltip.total')}: {dateTotalDisplays}
					<br />
					{stack.map(({ layerId, layerLabel, value, color }) => (
						<div key={layerId} className="d-flex align-items-center" style={{ color }}>
							<div style={{ width: '10px', height: '10px', backgroundColor: color, marginRight: '5px' }}></div>
							{layerLabel}: {value}
							{value && props.data.length > 1 ? ` (${Math.round((value / dateTotalDisplays) * 100)} %)` : null}
							<br />
						</div>
					))}
				</div>
			)
		},
		theme: isDarkMode ? darkModeTheme : undefined,
	}

	return (
		<GCard row type="collapsable" title={props.title}>
			{!totalDisplays && !computedData.global?.value ? (
				<GMessage value={t('dashboard.nodata')} />
			) : (
				<div className="row align-items-center" style={{ minHeight: '400px' }}>
					<div className="col-lg-4" style={{ height: '300px' }} data-testid="DisplayVolumeGlobal">
						<ResponsiveCirclePacking key={props.data} id="shortcut" data={computedData.global} {...circlePackingProperties} />
					</div>
					<div className="col-lg-8" style={{ height: '400px' }}>
						<ResponsiveStream data={computedData.timed} {...timedChartProperties} keys={visualKeys} />
					</div>
				</div>
			)}
		</GCard>
	)
})

DisplayVolumeChart.propTypes = {
	title: PropTypes.string.isRequired,
	data: PropTypes.array,
	dates: PropTypes.array.isRequired,
	dateFormat: PropTypes.object.isRequired,
}

export default DisplayVolumeChart
