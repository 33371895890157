export const ORGANIZATION_GET_SPACES = '@@organization/GET_SPACES'
export const ORGANIZATION_GET_SPACES_SUCCESS = '@@organization/GET_SPACES_SUCCESS'
export const ORGANIZATION_GET_SPACES_FAILURE = '@@organization/GET_SPACES_FAILURE'

export const ORGANIZATION_GET_SEGMENTS = '@@organization/GET_SEGMENTS'
export const ORGANIZATION_GET_SEGMENTS_SUCCESS = '@@organization/GET_SEGMENTS_SUCCESS'
export const ORGANIZATION_GET_SEGMENTS_FAILURE = '@@organization/GET_SEGMENTS_FAILURE'

export const ORGANIZATION_GET_VISUALS = '@@organization/GET_VISUALS'
export const ORGANIZATION_GET_VISUALS_SUCCESS = '@@organization/GET_VISUALS_SUCCESS'
export const ORGANIZATION_GET_VISUALS_FAILURE = '@@organization/GET_VISUALS_FAILURE'

export const ORGANIZATION_GET_DEVICES = '@@organization/GET_DEVICES'
export const ORGANIZATION_GET_DEVICES_SUCCESS = '@@organization/GET_DEVICES_SUCCESS'
export const ORGANIZATION_GET_DEVICES_FAILURE = '@@organization/GET_DEVICES_FAILURE'

export const ORGANIZATION_GET_CAMPAIGNS = '@@organization/GET_CAMPAIGNS'
export const ORGANIZATION_GET_CAMPAIGNS_SUCCESS = '@@organization/GET_CAMPAIGNS_SUCCESS'
export const ORGANIZATION_GET_CAMPAIGNS_FAILURE = '@@organization/GET_CAMPAIGNS_FAILURE'
