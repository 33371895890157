import React, { useContext, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { GButton, GIcon, GInput, GSecured } from '@alatimier/genesis-uic'
import { setVisualSvg } from '../../../store/visuals/visualsActions'
import { editorContext, SET_TAB, SET_ZOOM, STORE_OPTIONS, TAB_IMAGES, UPDATE } from '../editorContext'
import { updateWorkareaZoom } from '../utils/workarea'
import { keyHandlers } from '../utils/constants'
import { importImage } from '../utils/image'
import { cancelUndoStacking } from '../utils/cancelUndoStacking'
import Workarea from './Workarea/Workarea'

// TODO: Filter spamming console errors from svgedit library
// (fixed on git, waiting for release > 7.1.1 on npm, then it can be removed)
const originalConsoleError = console.error
console.error = (message) => {
	if (!message.startsWith('unknown mode')) originalConsoleError(message)
}

const EditorView = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const dispatch = useDispatch()
	const aspectRatios = useSelector((state) => state.ratios.list)
	const resolutions = useSelector((state) => state.resolutions.list)
	const [editorState, dispatchEditorState] = useContext(editorContext)

	const workareaRef = useRef(null)
	const canvasRef = useRef(null)

	useEffect(() => {
		if (editorState.canvas && aspectRatios.length > 0 && resolutions.length > 0) {
			updateResolution(editorState.canvas)
		}
	}, [editorState.canvas, editorState.format, editorState.resolution, aspectRatios, resolutions])

	useEffect(() => {
		if (editorState.canvas) {
			updateWorkareaZoom(editorState.zoom / 100, editorState.canvas, canvasRef.current, workareaRef.current)
			editorState.canvas.clearSelection()
			// Must be rebinded at each zoom change because it uses zoom
			editorState.canvas.bind('load', () => {
				canvasRef.current.querySelector('title').textContent = t('editor.hint.canvas')
				updateResolution()
				// Force this call because zoom state may not have changed, but it has to be updated in canvas
				updateWorkareaZoom(editorState.zoom / 100, editorState.canvas, canvasRef.current, workareaRef.current)
				editorState.canvas.undoMgr.resetUndoStack()
			})
		}
	}, [editorState.zoom])
	useEffect(() => bindKeyDown(), [editorState])
	useEffect(() => {
		dispatchEditorState({ type: STORE_OPTIONS })
	}, [editorState.format, editorState.color, editorState.strokeWidth, editorState.opacity, editorState.text, editorState.documentColors])

	const setZoom = (zoom) => dispatchEditorState({ type: SET_ZOOM, zoom })

	const getRatioForResolution = (aspectRatio, resolution) => {
		const isVertical = aspectRatio.minWidth < aspectRatio.minHeight
		return {
			width: aspectRatio.nbDisplay * (isVertical ? resolution.height : resolution.width),
			height: aspectRatio.nbDisplay * (isVertical ? resolution.width : resolution.height),
		}
	}

	const zoomFit = () => {
		const aspectRatio = aspectRatios.find((aspectRatio) => aspectRatio.id === editorState.format)
		if (!aspectRatio) throw new Error('Invalid ratio')
		const resolution = resolutions.find((resolution) => resolution.id === editorState.resolution)
		if (!resolution) throw new Error('Invalid resolution')

		const { width, height } = workareaRef.current.getBoundingClientRect()

		const ratio = getRatioForResolution(aspectRatio, resolution)
		const fitZoom = Math.min(width / ratio.width, height / ratio.height) * 0.9
		setZoom(fitZoom * 100)

		return { fitZoom, aspectRatio, resolution }
	}

	const updateResolution = () => {
		const { aspectRatio, resolution } = zoomFit()
		const wasNew = editorState.new
		const { width, height } = getRatioForResolution(aspectRatio, resolution)
		editorState.canvas.setResolution(width, height)
		if (wasNew) dispatchEditorState({ type: UPDATE, new: true })
		cancelUndoStacking(editorState.canvas)
	}

	const bindKeyDown = () => {
		const onKeyDown = (e) => {
			const key = `${e.altKey ? 'alt+' : ''}${e.shiftKey ? 'shift+' : ''}${e.metaKey ? 'meta+' : ''}${e.ctrlKey ? 'ctrl+' : ''}${e.key.toLowerCase()}`
			if (e.target.type !== 'text' && keyHandlers[key]) {
				e.preventDefault()
				keyHandlers[key](editorState, dispatchEditorState)
			}
		}
		document.addEventListener('keydown', onKeyDown)
		return () => document.removeEventListener('keydown', onKeyDown)
	}

	const handleDragOver = (e) => {
		// Prevent opening the image in a new tab
		e.preventDefault()
		e.stopPropagation()
	}
	const dropFile = (e) => {
		dispatchEditorState({ type: SET_TAB, tab: TAB_IMAGES })
		importImage(e.dataTransfer.files[0], dispatchEditorState, editorState.canvas)
	}

	const useAsVisual = () => {
		editorState.canvas.clearSelection()
		editorState.canvas.setSvgOption('apply', true) // embed images
		dispatch(
			setVisualSvg({
				data: editorState.canvas.svgCanvasToString(),
				format: editorState.format,
				resolution: editorState.resolution,
			})
		)
		navigate(`/visuals/new`)
	}

	const useAsTemplate = () => {
		editorState.canvas.clearSelection()
		editorState.canvas.setSvgOption('apply', true) // embed images
		dispatch(
			setVisualSvg({
				data: editorState.canvas.svgCanvasToString(),
				format: editorState.format,
				resolution: editorState.resolution,
			})
		)
		navigate(`/visuals/newTemplate`)
	}

	const handleRedirectToLab = () => {
		navigate('/lab/campaign/new')
	}

	return (
		<div id="editorView" onDragOver={handleDragOver} onDrop={dropFile}>
			<Workarea workareaRef={workareaRef} canvasRef={canvasRef} />
			<div className="zoom px-2">
				<GButton icon="fa-expand" color="link" title={t('editor.action.zoom.fit')} onClick={zoomFit} className="ps-0" />
				<GIcon name="fa-search" />
				<GInput id="zoom" type="range" rangeMin={10} rangeMax={200} value={editorState.zoom} onChange={setZoom} simple />
				<div>{Math.round(editorState.zoom)}%</div>
			</div>
			<GButton
				id="editor_save_template"
				label={t('editor.action.use_as_template')}
				onClick={useAsTemplate}
				disabled={editorState.new}
				location="page-top"
			/>
			<GButton id="editor_save" label={t('editor.action.use_as_visual')} onClick={useAsVisual} disabled={editorState.new} location="page-top" />
			<GSecured permission="LAB_CAMPAIGN_EDIT">
				<Link to="/lab/campaigns/new">
					<GButton label={t('lab.action.create')} location={['page', 'page-top']} />
				</Link>
			</GSecured>
		</div>
	)
}

export default EditorView
