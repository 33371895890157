import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { GButton } from '@alatimier/genesis-uic'
import { editorContext, SET_TAB } from '../editorContext'
import { setMode } from '../utils/actions'

const TabIcon = (props) => {
	const { t } = useTranslation()
	const [editorState, dispatchEditorState] = useContext(editorContext)

	const handleTabClick = (tab) => () => {
		dispatchEditorState({ type: SET_TAB, tab })
		if (editorState.mode === 'textedit') editorState.canvas.textActions.toSelectMode()
		editorState.canvas.clearSelection()
		setMode(editorState, dispatchEditorState, 'select')
	}

	return (
		<>
			<GButton
				icon={props.icon}
				color="link"
				className="tabIcon fs-2 text-info"
				css={{ opacity: editorState.tab === props.name ? 1 : 0.6, ':hover': { opacity: 1 } }}
				tooltip={t(`editor.tab.${props.name}`)}
				tooltipPosition="bottom"
				onClick={handleTabClick(props.name)}
			/>
		</>
	)
}

TabIcon.propTypes = {
	icon: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
}

export default TabIcon
