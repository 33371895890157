import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { GCard, GIcon, GMessage } from '@alatimier/genesis-uic'
import SalesChart from '../SalesChart/SalesChart'
import AttentionCompareChart from '../AttentionCompareChart/AttentionCompareChart'
import TradeMarketingChart from '../TradeMarketingChart/TradeMarketingChart'
import GeneralROIChart from '../GeneralROIChart/GeneralROIChart'
import ReachChart from '../ReachChart/ReachChart'
import { getObjectiveProgression, isType, objectiveGraphContext, objectiveTypes } from '../utils'

const ObjectivePreview = (props) => {
	const { t } = useTranslation()

	const previewData = useSelector((state) => state.objectives.previewData)
	const loading = useSelector((state) => state.objectives.pendingPreviewNb > 0)

	const progression = useMemo(() => {
		return previewData && getObjectiveProgression(previewData, props)
	}, [previewData, props.target, props.visualCost, props.hardwareCost, props.monthlySoftwareCost])

	const now = new Date().toISOString()
	const missedDeadline = previewData?.deadline && progression < 100 && now > previewData?.deadline
	const progressionString =
		progression != null
			? `${t('objectives.form.progression')}: ${Math.floor(progression)}%${missedDeadline ? ` (${t('objectives.chart.deadline.missed')})` : ''}`
			: ''
	let progressionColor = 'info'
	if (progression != null && previewData?.deadline) {
		if (progression >= 100) progressionColor = 'success'
		else if (missedDeadline) progressionColor = 'danger'
		else if (progression >= 66.6) progressionColor = 'info'
		else if (progression >= 33.3) progressionColor = 'warning'
		else progressionColor = 'danger'
	}

	return (
		<GCard
			type="collapsable"
			row
			title={
				<>
					{t('objectives.card.preview')} {loading && <GIcon name="fa-spinner" spin />}
				</>
			}
		>
			{previewData && isType(previewData, props.type) && (
				<>
					{progression != null && <GMessage value={progressionString} color={progressionColor} />}
					{isType(previewData, objectiveTypes.IMPROVE_SALES) && (
						<SalesChart data={previewData} target={props.target} context={objectiveGraphContext.EDIT} />
					)}
					{isType(previewData, objectiveTypes.IMPROVE_ATTENTION_TIME) && (
						<AttentionCompareChart data={previewData} target={props.target} context={objectiveGraphContext.EDIT} />
					)}
					{isType(previewData, objectiveTypes.ROI_SALES) && (
						<TradeMarketingChart data={previewData} cost={props.visualCost} context={objectiveGraphContext.EDIT} />
					)}
					{isType(previewData, objectiveTypes.ROI) && (
						<GeneralROIChart
							data={previewData}
							initialCost={props.hardwareCost}
							monthlyCost={props.monthlySoftwareCost}
							target={props.target}
							context={objectiveGraphContext.EDIT}
						/>
					)}
					{isType(previewData, objectiveTypes.REACH) && <ReachChart data={previewData} target={props.target} progression={progression} />}
				</>
			)}
		</GCard>
	)
}

ObjectivePreview.propTypes = {
	type: PropTypes.object.isRequired,
	target: PropTypes.number.isRequired,
	visualCost: PropTypes.number,
	hardwareCost: PropTypes.number,
	monthlySoftwareCost: PropTypes.number,
}

export default ObjectivePreview
