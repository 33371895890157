import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GCard, GCrudFormActions, GInput, GOrganizationSelect, GTitleBar, GModal, isEmpty } from '@alatimier/genesis-uic'
import { createLabCampaign } from '../../store/lab/labActions'
import AspectRatioSelect from '../../components/ratios/AspectRatioSelect'
import ResolutionSelect from '../../components/resolutions/ResolutionSelect'

const LabCampaignCreate = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const status = useSelector((state) => state.lab.status)
	const url = useSelector((state) => state.lab.url)

	const [organizationId, setOrganizationId] = useState()
	const [name, setName] = useState()
	const [aspectRatioId, setAspectRatioId] = useState()
	const [resolutionId, setResolutionId] = useState()
	const [offerId, setOfferId] = useState('CUSTOM')
	const [showModal, setShowModal] = useState(false)

	useEffect(() => {
		if (status === 'created' && url) {
			window.open(url, '_bank')
			navigate('/lab/campaigns')
		}
	}, [status])

	const isValid = () => !isEmpty(organizationId) && !isEmpty(name) && !isEmpty(aspectRatioId) && !isEmpty(resolutionId) && !isEmpty(offerId)

	const handleSave = () => {
		setShowModal(true)
	}

	const handleCancel = () => {
		navigate('/lab/campaigns')
	}

	const abortCreateCampaign = () => {
		setShowModal(false)
	}

	function apply() {
		dispatch(createLabCampaign(organizationId, name, aspectRatioId, resolutionId, offerId))
	}

	const descriptionStyle = {
		fontSize: '15.5x',
		boxShadow: '0 3px 2px rgba(0, 0, 0, 0.2)',
		backgroundColor: '#f8f8f8',
		marginTop: '2px',
		marginBottom: '20px',
		padding: '15px',
	}

	return (
		<>
			<GTitleBar>{t('visuals.title')}</GTitleBar>
			<GCard title={t('visuals.properties.title')} row>
				<GOrganizationSelect id="organizationId" value={organizationId} onChange={setOrganizationId} maxLevel={1} permission="LAB_CAMPAIGN_EDIT" />
				<GInput id="name" label={t('lab.table.name')} value={name} onChange={setName} required />
				<AspectRatioSelect id="aspectRatioId" value={aspectRatioId} onChange={setAspectRatioId} required />
				<ResolutionSelect id="resolutionId" value={resolutionId} onChange={setResolutionId} required />
				<GCrudFormActions canSave={isValid()} onSave={handleSave} canCancel onCancel={handleCancel} location={['page', 'page-top']} />
			</GCard>
			<div style={descriptionStyle} dangerouslySetInnerHTML={{ __html: t('lab.description') }} />
			<GModal show={showModal} color="danger" type="confirm" title={t('lab.action.create')} onClick={apply} onCancel={abortCreateCampaign}>
				{t('lab.action.create.confirmation')}
			</GModal>
		</>
	)
}

export default LabCampaignCreate
