import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GButton, GCard, GIcon, GMessage, GSecured, GTable, GTitleBar, isEmpty } from '@alatimier/genesis-uic'
import { getLabCampaigns } from '../../store/lab/labActions'

const LabCampaignList = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const labcampaigns = useSelector((state) => state.lab.list)
	const pending = useSelector((state) => state.lab.pending)
	const status = useSelector((state) => state.lab.status)

	const organizations = useSelector((state) => state.g_organizations.list)

	useEffect(() => fetchLabCampaigns(), [])

	const fetchLabCampaigns = () => {
		dispatch(getLabCampaigns())
	}

	const columns = [
		{ itemProp: 'name', label: t('lab.table.name') },
		{ itemProp: 'statusId', label: t('lab.table.state') },
		{ itemProp: 'organization', label: t('lab.table.organization') },
	]

	const rowActions = [
		{
			title: t('action.edit'),
			icon: 'fa-edit',
			fn: (item) => window.open(item.dashboardUrl, '_bank'),
		},
	]

	const renderPending = () => (
		<div className="text-center">
			<GIcon name="fa-spinner" spin />
		</div>
	)

	const humanReadableMapper = (labcampaign) => ({
		...labcampaign,
		organization: organizations
			.flatMap((o) => [o, ...(o.children || [])])
			.filter((o) => o.id === labcampaign.organizationId)
			.map((o) => o.name),
	})

	const renderLabCampaign = () => {
		if (isEmpty(labcampaigns)) {
			return <GMessage value={t('lab.list.empty')} />
		}
		return (
			<GTable
				id="LabcampaignList"
				items={labcampaigns.map(humanReadableMapper)}
				columns={columns}
				hover
				columnSorter
				columnFilter
				rowActions={rowActions}
				pagination
				itemsPerPage={15}
			/>
		)
	}

	return (
		<>
			<GTitleBar>{t('lab.card.title')}</GTitleBar>
			<GCard title={t('lab.list.title')} row>
				{pending && isEmpty(labcampaigns) ? renderPending() : renderLabCampaign()}
				<GSecured permission="LAB_CAMPAIGN_EDIT">
					<Link to="/lab/campaigns/new">
						<GButton label={t('lab.action.create')} location={['page', 'page-top']} />
					</Link>
				</GSecured>
			</GCard>
		</>
	)
}

export default LabCampaignList
