import { apiClientService as client } from '@alatimier/genesis-uic'
import { RATIOS_GET_ASPECT_RATIOS, RATIOS_GET_ASPECT_RATIOS_FAILURE, RATIOS_GET_ASPECT_RATIOS_SUCCESS } from './aspectRatiosTypes'

const aspectRatioEndPoint = '/api/admin/v1/ratios'

export const getAspectRatios = () => {
	return (dispatch) => {
		dispatch({ type: RATIOS_GET_ASPECT_RATIOS })
		return client.get(aspectRatioEndPoint).then(async (res) => {
			const data = await res.json()
			if (res.ok) {
				dispatch({
					type: RATIOS_GET_ASPECT_RATIOS_SUCCESS,
					list: data,
				})
			} else {
				dispatch({
					type: RATIOS_GET_ASPECT_RATIOS_FAILURE,
				})
			}
		})
	}
}
