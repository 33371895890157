import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import PopulationRepartitionChart from '../../components/dashboard/PopulationRepartitionChart/PopulationRepartitionChart'

const AudienceDashboard = (props) => {
	const { t } = useTranslation()

	return (
		props.data !== null && (
			<PopulationRepartitionChart
				title={t('dashboard.populationRepartition.title')}
				data={props.data}
				dates={props.dates}
				dateFormat={props.dateFormat}
			/>
		)
	)
}

AudienceDashboard.propTypes = {
	data: PropTypes.array,
	dates: PropTypes.array.isRequired,
	dateFormat: PropTypes.object.isRequired,
}

export default AudienceDashboard
