import { Interval, DateTime } from 'luxon'

function toZonedDateTime(date) {
	return date ? DateTime.fromISO(date).toISO() : undefined
}

function toLocaleDateTime(date) {
	return date ? DateTime.fromISO(date).toISO({ includeOffset: false }) : ''
}

function toLocaleDate(date) {
	return date ? DateTime.fromISO(date).toISODate() : ''
}

/** Handles missing start/end dates like API from/to */
function toInterval(startDate, endDate) {
	const end = endDate ? DateTime.fromISO(endDate) : DateTime.now()
	const start = startDate ? DateTime.fromISO(startDate) : end.plus({ month: -1 })
	return Interval.fromDateTimes(start, end)
}

export default {
	toZonedDateTime: toZonedDateTime,
	toLocaleDateTime: toLocaleDateTime,
	toLocaleDate: toLocaleDate,
	toInterval: toInterval,
}
