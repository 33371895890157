import PropTypes from 'prop-types'
import './FiltersReminder.scss'
import { GCard, isEmpty } from '@alatimier/genesis-uic'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'

const Strong = (props) => <strong style={{ color: 'var(--cui-primary)' }}>{props.children}</strong>

const NameList = ({ array }) =>
	array?.length > 0 && array.map(({ id, name }) => <Strong key={id}>{name}</Strong>).reduce((prev, newName) => [prev, ', ', newName])

const FiltersReminder = (props) => {
	const { t } = useTranslation()

	const filters = useSelector((state) => state.statistics.filters)

	// Show if the user scrolls past the dashboard form
	const filtersReminderCard = document.querySelector('#filtersReminderContainer .card')
	useEffect(() => {
		if (filtersReminderCard) {
			const dashboardForm = document.querySelector(`.${props.dashboardFormClassName}`)
			const scrollingElement = document.querySelector('html')
			const handleScroll = () => {
				if (scrollingElement.scrollTop > dashboardForm.offsetHeight) {
					filtersReminderCard.classList.remove('hidden')
				} else {
					filtersReminderCard.classList.add('hidden')
				}
			}
			document.addEventListener('scroll', handleScroll)
			return () => document.removeEventListener('scroll', handleScroll)
		}
	}, [filtersReminderCard])

	if (isEmpty(filters.to)) filters.to = DateTime.now().toISO()
	if (isEmpty(filters.from)) filters.from = DateTime.fromISO(filters.to).plus({ month: -1 }).toISO()
	const dates = (
		<>
			{t('forms.date.start.short')} <Strong>{DateTime.fromISO(filters.from).toLocaleString(DateTime.DATETIME_MED)}</Strong> {t('forms.date.end.short')}{' '}
			<Strong>{DateTime.fromISO(filters.to).toLocaleString(DateTime.DATETIME_MED)}</Strong>
			<br />
		</>
	)
	const campaign = filters.campaign && (
		<>
			{t('forms.campaign')} : <Strong>{filters.campaign.name}</Strong>
			<br />
		</>
	)
	const visuals = (
		<>
			{filters.visuals?.length > 1 ? t('forms.visuals') : t('forms.visual')} : <NameList array={filters.visuals} />
			<br />
		</>
	)
	const locations =
		filters.devices?.length > 0 ? (
			<>
				{filters.devices?.length > 1 ? t('forms.devices') : t('forms.device')} : <NameList array={filters.devices} />
			</>
		) : filters.spaces?.length > 0 ? (
			<>
				{filters.spaces?.length > 1 ? t('forms.spaces') : t('forms.space')} : <NameList array={filters.spaces} />
			</>
		) : null
	const targetOrganization = filters.targetOrganization && (
		<>
			{t('forms.targetOrganization')} : <Strong>{filters.targetOrganization.name}</Strong>
		</>
	)

	return (
		<div id="filtersReminderContainer">
			<GCard row type="collapsable" title={t('dashboard.filters.selected')} className="hidden">
				{dates}
				{props.dashboardName === 'views' && campaign}
				{props.dashboardName === 'views' && visuals}
				{props.dashboardName === 'views' ? locations : locations ?? targetOrganization}
			</GCard>
		</div>
	)
}

FiltersReminder.propTypes = {
	dashboardName: PropTypes.string.isRequired,
	dashboardFormClassName: PropTypes.string.isRequired,
}

export default FiltersReminder
