import {
	OBJECTIVES_CLEAR_STATE,
	OBJECTIVES_CREATE_OBJECTIVE,
	OBJECTIVES_CREATE_OBJECTIVE_FAILURE,
	OBJECTIVES_CREATE_OBJECTIVE_SUCCESS,
	OBJECTIVES_DELETE_OBJECTIVE,
	OBJECTIVES_DELETE_OBJECTIVE_FAILURE,
	OBJECTIVES_DELETE_OBJECTIVE_SUCCESS,
	OBJECTIVES_GET_OBJECTIVE,
	OBJECTIVES_GET_OBJECTIVE_FAILURE,
	OBJECTIVES_GET_OBJECTIVE_SUCCESS,
	OBJECTIVES_GET_OBJECTIVES,
	OBJECTIVES_GET_OBJECTIVES_FAILURE,
	OBJECTIVES_GET_OBJECTIVES_SUCCESS,
	OBJECTIVES_UPDATE_OBJECTIVE,
	OBJECTIVES_UPDATE_OBJECTIVE_FAILURE,
	OBJECTIVES_UPDATE_OBJECTIVE_SUCCESS,
	OBJECTIVES_GET_PREVIEW_STATISTICS,
	OBJECTIVES_GET_PREVIEW_STATISTICS_SUCCESS,
	OBJECTIVES_GET_PREVIEW_STATISTICS_FAILURE,
	OBJECTIVES_GET_OBJECTIVE_STATISTICS_SUCCESS,
	OBJECTIVES_GET_OBJECTIVE_STATISTICS_FAILURE,
} from './objectivesTypes'

export const initialState = {
	pending: false,
	list: [],
	objective: null,
	newId: null,
	status: null,
	previewData: null,
	pendingPreviewNb: 0,
}

const objectivesReducer = (state = initialState, action) => {
	switch (action.type) {
		case OBJECTIVES_GET_OBJECTIVES:
		case OBJECTIVES_GET_OBJECTIVE:
		case OBJECTIVES_CREATE_OBJECTIVE:
		case OBJECTIVES_UPDATE_OBJECTIVE:
		case OBJECTIVES_DELETE_OBJECTIVE:
			return {
				...state,
				pending: true,
				status: null,
			}
		case OBJECTIVES_GET_PREVIEW_STATISTICS:
			return {
				...state,
				pendingPreviewNb: (state.pendingPreviewNb ?? 0) + 1,
			}
		case OBJECTIVES_GET_OBJECTIVES_SUCCESS:
			return {
				pending: false,
				list: action.list.map((objective) => ({
					...objective,
					data: state.list.find((o) => o.id === objective.id)?.data,
				})),
			}
		case OBJECTIVES_GET_OBJECTIVE_STATISTICS_SUCCESS:
			return {
				...state,
				list: state.list?.map((objective) =>
					objective.id === action.id
						? {
								...objective,
								data: action.data,
						  }
						: objective
				),
			}
		case OBJECTIVES_GET_OBJECTIVE_SUCCESS:
			return {
				...state,
				previewData: null,
				pending: false,
				objective: action.objective,
			}
		case OBJECTIVES_CREATE_OBJECTIVE_SUCCESS:
			return {
				...state,
				pending: false,
				newId: action.newObjective.id,
				status: 'created',
				list: [...state.list, action.newObjective],
			}
		case OBJECTIVES_UPDATE_OBJECTIVE_SUCCESS:
			return {
				...state,
				pending: false,
				status: 'updated',
			}
		case OBJECTIVES_DELETE_OBJECTIVE_SUCCESS:
			return {
				...state,
				pending: false,
				status: 'deleted',
			}
		case OBJECTIVES_GET_PREVIEW_STATISTICS_SUCCESS:
			return {
				...state,
				pendingPreviewNb: state.pendingPreviewNb - 1,
				previewData: action.data,
			}
		case OBJECTIVES_GET_OBJECTIVES_FAILURE:
		case OBJECTIVES_GET_OBJECTIVE_FAILURE:
		case OBJECTIVES_CREATE_OBJECTIVE_FAILURE:
		case OBJECTIVES_UPDATE_OBJECTIVE_FAILURE:
		case OBJECTIVES_DELETE_OBJECTIVE_FAILURE:
			return {
				...state,
				pending: false,
			}
		case OBJECTIVES_GET_OBJECTIVE_STATISTICS_FAILURE:
			return {
				...state,
				list: state.list?.map((objective) =>
					objective.id === action.id
						? {
								...objective,
								error: true,
						  }
						: objective
				),
			}
		case OBJECTIVES_GET_PREVIEW_STATISTICS_FAILURE:
			return {
				...state,
				pendingPreviewNb: state.pendingPreviewNb - 1,
			}
		case OBJECTIVES_CLEAR_STATE:
			return {
				...initialState,
				list: state.list,
			}
		default:
			return state
	}
}

export default objectivesReducer
