import PropTypes from 'prop-types'

const ColorButton = (props) => {
	const handleClick = () => props.onClick(props.color)

	return (
		<button
			className="btn color"
			title={props.color}
			onClick={handleClick}
			onContextMenu={props.onContextMenu}
			style={{ backgroundColor: props.color, '&:hover, &:focus': { backgroundColor: `${props.color} !important` } }}
		/>
	)
}

ColorButton.propTypes = {
	color: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
	onContextMenu: PropTypes.func,
}

export default ColorButton
