export const DEVICES_GET_DEVICE_TYPES = '@@devices/GET_DEVICE_TYPES'
export const DEVICES_GET_DEVICE_TYPES_SUCCESS = '@@devices/GET_DEVICE_TYPES_SUCCESS'
export const DEVICES_GET_DEVICE_TYPES_FAILURE = '@@devices/GET_DEVICE_TYPES_FAILURE'

export const DEVICES_GET_INFERENCE_TYPES = '@@devices/GET_INFERENCE_TYPES'
export const DEVICES_GET_INFERENCE_TYPES_SUCCESS = '@@devices/GET_INFERENCE_TYPES_SUCCESS'
export const DEVICES_GET_INFERENCE_TYPES_FAILURE = '@@devices/GET_INFERENCE_TYPES_FAILURE'

export const DEVICES_GET_DEVICES = '@@devices/GET_DEVICES'
export const DEVICES_GET_DEVICES_SUCCESS = '@@devices/GET_DEVICES_SUCCESS'
export const DEVICES_GET_DEVICES_FAILURE = '@@devices/GET_DEVICES_FAILURE'

export const DEVICES_GET_DEVICE = '@@devices/GET_DEVICE'
export const DEVICES_GET_DEVICE_SUCCESS = '@@devices/GET_DEVICE_SUCCESS'
export const DEVICES_GET_DEVICE_FAILURE = '@@devices/GET_DEVICE_FAILURE'

export const DEVICES_CREATE_DEVICE = '@@devices/CREATE_DEVICE'
export const DEVICES_CREATE_DEVICE_SUCCESS = '@@devices/CREATE_DEVICE_SUCCESS'
export const DEVICES_CREATE_DEVICE_FAILURE = '@@devices/CREATE_DEVICE_FAILURE'

export const DEVICES_UPDATE_DEVICE = '@@devices/UPDATE_DEVICE'
export const DEVICES_UPDATE_DEVICE_SUCCESS = '@@devices/UPDATE_DEVICE_SUCCESS'
export const DEVICES_UPDATE_DEVICE_FAILURE = '@@devices/UPDATE_DEVICE_FAILURE'

export const DEVICES_DELETE_DEVICE = '@@devices/DELETE_DEVICE'
export const DEVICES_DELETE_DEVICE_SUCCESS = '@@devices/DELETE_DEVICE_SUCCESS'
export const DEVICES_DELETE_DEVICE_FAILURE = '@@devices/DELETE_DEVICE_FAILURE'

export const DEVICES_CLEAR_STATE = '@@devices/CLEAR_STATE'
