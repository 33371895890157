import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { ResponsiveBullet } from '@nivo/bullet'
import { GMessage } from '@alatimier/genesis-uic'
import { darkModeTheme } from '../../../utils/ChartUtils'

const colors = {
	poor: 'rgba(103, 0, 31, 0.6)',
	inProgress: 'rgba(196, 65, 3, 0.6)',
	closing: 'rgba(8, 61, 126, 0.6)',
	overPerformed: 'rgba(0, 84, 34, 0.6)',
	measure: '#f8efa5',
	target: 'lightgreen',
}

const textColors = {
	poor: 'crimson',
	inProgress: 'orange',
	closing: 'lightblue',
	overPerformed: 'mediumseagreen',
	measure: colors.measure,
	target: colors.target,
}

const ReachChart = memo((props) => {
	const { t } = useTranslation()

	const isDarkMode = useSelector((store) => store.g_display.darkMode)

	const { campaign, visual } = props.data

	if (!props.target) return <GMessage value={t('objectives.chart.noTarget')} className="mb-0" />

	const data = [
		{
			id: `${visual ? visual.name : campaign.name}`,
			ranges: [Math.round(props.target / 3), Math.round((props.target * 2) / 3), props.target, Math.round((props.target * 4) / 3)],
			measures: [props.data.views],
			markers: [props.target],
		},
	]

	return (
		<div data-testid="reach-chart" style={{ height: '100px', width: '100%', marginTop: '20px' }}>
			<ResponsiveBullet
				data={data}
				margin={{ top: 10, right: 30, bottom: 30, left: 120 }}
				rangeColors={[colors.poor, colors.inProgress, colors.closing, colors.overPerformed, colors.overPerformed]}
				measureColors={colors.measure}
				measureSize={0.2}
				markerColors={colors.target}
				markerSize={0.8}
				titleAlign="start"
				titleOffsetX={-120}
				tooltip={({ color, v0, v1 }) => {
					const key = Object.keys(colors).find((key) => colors[key] === color)
					return (
						<div style={{ padding: 12, color: textColors[key], background: '#222222', borderRadius: '8px' }}>
							{key === 'measure'
								? `${t('objectives.chart.views.measure')}: ${v0} (${Math.floor(props.progression)}%)`
								: key === 'target'
								? `${t('objectives.chart.target')}: ${v0} ${t('objectives.chart.views')}`
								: `${t(`objectives.chart.progression.${key}`)}: ${v0} - ${v1}`}
						</div>
					)
				}}
				theme={isDarkMode ? darkModeTheme : undefined}
			/>
		</div>
	)
})

ReachChart.propTypes = {
	data: PropTypes.object.isRequired,
	target: PropTypes.number.isRequired,
	progression: PropTypes.number,
}

export default ReachChart
