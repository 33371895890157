import { RATIOS_GET_ASPECT_RATIOS, RATIOS_GET_ASPECT_RATIOS_FAILURE, RATIOS_GET_ASPECT_RATIOS_SUCCESS } from './aspectRatiosTypes'

const initialState = {
	pending: false,
	list: [],
}

const aspectRatiosReducer = (state = initialState, action) => {
	switch (action.type) {
		case RATIOS_GET_ASPECT_RATIOS:
			return {
				...state,
				pending: true,
			}
		case RATIOS_GET_ASPECT_RATIOS_SUCCESS:
			return {
				pending: false,
				list: action.list,
			}
		case RATIOS_GET_ASPECT_RATIOS_FAILURE:
			return {
				...state,
				pending: false,
			}
		default:
			return state
	}
}

export default aspectRatiosReducer
