import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { GIcon } from '@alatimier/genesis-uic'
import { editorContext, SET_FIGURE_NAME, SET_SELECTED_FIGURE } from '../../editorContext'

const FigureList = (props) => {
	const { t } = useTranslation()

	const [editorState, dispatchEditorState] = useContext(editorContext)
	const [openNameInput, setOpenNameInput] = useState(null)
	const [openMenu, setOpenMenu] = useState(null)
	const imageListRef = useRef()

	useEffect(() => {
		if (openNameInput) imageListRef.current.querySelector('input').select()
	}, [openNameInput])

	useEffect(() => {
		const hideMenu = () => setOpenMenu(null)
		document.addEventListener('click', hideMenu)
		return () => document.removeEventListener('click', hideMenu)
	}, [])

	const showNameInput = (id) => () => {
		setOpenMenu(null)
		setOpenNameInput(id)
	}

	const changeName = (id) => (e) => {
		setOpenNameInput(null)
		dispatchEditorState({ type: SET_FIGURE_NAME, figureKey: props.figureKey, id, name: e.target.value })
	}

	const handleInputKeypress = (id) => (e) => {
		if (e.key === 'Enter') {
			changeName(id)(e)
		}
	}

	const selectFigure = (figure) => (e) => {
		if (e.target.tagName === 'INPUT') return
		editorState.canvas.clearSelection()
		dispatchEditorState({ type: SET_SELECTED_FIGURE, figureKey: props.figureKey, figure })
		setOpenMenu(null)
		setOpenNameInput(null)
	}

	const handleFigureClick = (figure) => (e) => {
		e.stopPropagation()
		if (openMenu) return
		props.onClick(figure)
	}

	const displayContextMenu = (figure) => (e) => {
		e.preventDefault()
		dispatchEditorState({ type: SET_SELECTED_FIGURE, figureKey: props.figureKey, figure })
		setOpenMenu({ figure, x: e.clientX, y: e.clientY })
	}

	return (
		<div ref={imageListRef} className="d-flex justify-content-around align-items-end flex-wrap">
			{editorState[props.figureKey].list.map((figure) => {
				return (
					<figure
						key={figure.id}
						onClick={selectFigure(figure)}
						onContextMenu={displayContextMenu(figure)}
						className={`me-2 mb-2 ${figure === editorState[props.figureKey].selected && openNameInput !== figure.id ? 'selected' : ''}`}
					>
						<img
							src={figure.isSvg ? `data:image/svg+xml;utf8,${encodeURIComponent(figure.data)}` : figure.data}
							onClick={handleFigureClick(figure)}
						/>
						<figcaption title={t('editor.hint.image.name')} onDoubleClick={showNameInput(figure.id)}>
							{openNameInput === figure.id ? (
								<input
									type="text"
									defaultValue={figure.name}
									onBlur={changeName(figure.id)}
									onKeyPress={handleInputKeypress(figure.id)}
									className="form-control-plaintext"
								/>
							) : (
								figure.name
							)}
						</figcaption>
					</figure>
				)
			})}
			{openMenu && (
				<div className="contextMenu" style={{ top: openMenu.y, left: openMenu.x }}>
					{props.menuActions.map((menuAction) => (
						<button key={menuAction.label} onClick={menuAction.fn(openMenu.figure)}>
							{menuAction.label}
							<GIcon name={menuAction.icon} className="ms-3" />
						</button>
					))}
				</div>
			)}
		</div>
	)
}

const menuActionShape = PropTypes.shape({
	label: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	fn: PropTypes.func.isRequired,
})
FigureList.propTypes = {
	figureKey: PropTypes.oneOf(['images', 'visuals']).isRequired,
	menuActions: PropTypes.arrayOf(menuActionShape).isRequired,
	onClick: PropTypes.func.isRequired,
}

export default FigureList
