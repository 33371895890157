import { createContext, useReducer } from 'react'
import last from 'lodash/last'
import { initialConfig, loadOptions, loadSvg } from './utils/init'
import { localStorageKeys } from '../../utils/LocalStorageUtils'

/** A figure can be an image or a visual. */

export const INIT_CANVAS = 'editor/INIT_CANVAS'
export const SET_FORMAT = 'editor/SET_FORMAT'
export const SET_RESOLUTION = 'editor/SET_RESOLUTION'
export const SET_ZOOM = 'editor/SET_ZOOM'
export const SET_TAB = 'editor/SET_TAB'
export const SET_MODE = 'editor/SET_MODE'
export const SET_COLOR = 'editor/SET_COLOR'
export const SET_STROKE_WIDTH = 'editor/SET_STROKE_WIDTH'
export const SET_OPACITY = 'editor/SET_OPACITY'
export const SET_TEXT_PROPERTIES = 'editor/SET_TEXT_PROPERTIES'
export const ADD_DOCUMENT_COLOR = 'editor/ADD_DOCUMENT_COLOR'
export const SET_DOCUMENT_COLOR = 'editor/SET_DOCUMENT_COLOR'
export const DELETE_DOCUMENT_COLOR = 'editor/DELETE_DOCUMENT_COLOR'
export const SET_SELECTED_ELEMENT = 'editor/SET_SELECTED_ELEMENT'
export const UPDATE = 'editor/UPDATE'
export const SAVE_IMAGE = 'editor/SAVE_IMAGE'
export const ADD_VISUAL = 'editor/ADD_VISUAL'
export const SET_SELECTED_FIGURE = 'editor/SET_SELECTED_FIGURE'
export const SET_FIGURE_NAME = 'editor/SET_FIGURE_NAME'
export const DELETE_IMAGE = 'editor/DELETE_IMAGE'
export const DESELECT_FIGURE = 'editor/DESELECT_FIGURE'
export const STORE_OPTIONS = 'editor/STORE_OPTIONS'

export const TAB_SHAPE = 'shape'
export const TAB_TEXT = 'text'
export const TAB_IMAGES = 'images'
export const TAB_VISUAL = 'visual'
export const TAB_WIDGET = 'widget'

export const initialState = {
	canvas: null,
	format: '16_9',
	resolution: 'HD',
	zoom: 0,
	tab: TAB_SHAPE,
	mode: initialConfig.initTool,
	color: {
		shape: {
			fill: `#${initialConfig.initFill.color}`,
			stroke: `#${initialConfig.initStroke.color}`,
		},
		text: {
			fill: '#000000',
		},
	},
	strokeWidth: initialConfig.initStroke.width.toString(),
	opacity: initialConfig.initOpacity * 100,
	text: {
		fontFamily: initialConfig.text.font_family,
		fontSize: initialConfig.text.font_size,
		bold: false,
		italic: false,
		underline: false,
		strike: false,
	},
	new: true,
	documentColors: {
		list: [],
		nextId: 1,
	},
	images: {
		list: [],
		selected: null,
		nextId: 1,
	},
	visuals: {
		list: [],
		selected: null,
	},
	selectedElement: null,
	multiSelected: false,
}

export const editorReducer = (state, action) => {
	switch (action.type) {
		case INIT_CANVAS:
			const svgString = localStorage.getItem(localStorageKeys.svgString)
			if (svgString) loadSvg(action.canvas, svgString)
			const options = localStorage.getItem(localStorageKeys.svgOptions)
			if (options) loadOptions(action.canvas, JSON.parse(options))
			return { ...state, ...JSON.parse(options), canvas: action.canvas, new: svgString ? false : true }
		case SET_FORMAT:
			return { ...state, format: action.format }
		case SET_RESOLUTION:
			return { ...state, resolution: action.resolution }
		case SET_ZOOM:
			return { ...state, zoom: action.zoom }
		case SET_TAB:
			if (state.tab === action.tab) return state
			return {
				...state,
				tab: action.tab,
				images: { ...state.images, selected: null },
				visuals: { ...state.visuals, selected: null },
			}
		case SET_MODE:
			return { ...state, mode: action.mode }
		case SET_COLOR:
			return {
				...state,
				color: {
					...state.color,
					[action.tagType]: {
						...state.color[action.tagType],
						[action.colorType]: action.color,
					},
				},
			}
		case SET_STROKE_WIDTH:
			return { ...state, strokeWidth: action.width }
		case SET_OPACITY:
			return { ...state, opacity: action.opacity }
		case SET_TEXT_PROPERTIES:
			return {
				...state,
				text: {
					...state.text,
					...action.textProperties,
				},
			}
		case ADD_DOCUMENT_COLOR:
			return {
				...state,
				documentColors: {
					list: [
						...state.documentColors.list,
						{
							id: state.documentColors.nextId,
							color: action.color,
						},
					],
					nextId: state.documentColors.nextId + 1,
				},
			}
		case SET_DOCUMENT_COLOR:
			return {
				...state,
				documentColors: {
					...state.documentColors,
					list: [
						...state.documentColors.list.slice(0, -1),
						{
							...last(state.documentColors.list),
							color: action.color,
						},
					],
				},
			}
		case DELETE_DOCUMENT_COLOR:
			return {
				...state,
				documentColors: {
					...state.documentColors,
					list: state.documentColors.list.filter((color) => color.id !== action.id),
				},
			}
		case SET_SELECTED_ELEMENT:
			const elementsAreSelected = action.selectedElement || action.multiSelected
			return {
				...state,
				selectedElement: action.selectedElement,
				multiSelected: action.multiSelected,
				mode: action.mode,
				images: {
					...state.images,
					selected: elementsAreSelected ? null : state.images.selected,
				},
				visuals: {
					...state.visuals,
					selected: elementsAreSelected ? null : state.visuals.selected,
				},
			}
		case UPDATE:
			if (state.canvas) {
				localStorage.setItem(localStorageKeys.svgString, state.canvas.getSvgString())
			}
			return {
				...state,
				new: action.new ?? false,
			}
		case SAVE_IMAGE:
			return {
				...state,
				images: {
					...state.images,
					nextId: state.images.nextId + 1,
					list: [
						...state.images.list,
						{
							id: state.images.nextId,
							data: action.data,
							isSvg: action.isSvg,
							name: action.name,
						},
					],
				},
			}
		case ADD_VISUAL:
			const oldVisual = state.visuals.list.find((v) => v.id === action.visual.id)
			if (oldVisual) {
				const newVisualsList = state.visuals.list.slice()
				newVisualsList.splice(state.visuals.list.indexOf(oldVisual), 1, action.visual)
				return {
					...state,
					visuals: {
						...state.visuals,
						list: newVisualsList,
					},
				}
			} else {
				return {
					...state,
					visuals: {
						...state.visuals,
						list: [...state.visuals.list, action.visual],
					},
				}
			}
		case SET_SELECTED_FIGURE:
			return {
				...state,
				[action.figureKey]: {
					...state[action.figureKey],
					selected: action.figure,
				},
			}
		case SET_FIGURE_NAME:
			return {
				...state,
				[action.figureKey]: {
					...state[action.figureKey],
					list: state[action.figureKey].list.map((figure) =>
						figure.id === action.id
							? {
									...figure,
									name: action.name,
							  }
							: figure
					),
				},
			}
		case DELETE_IMAGE:
			return {
				...state,
				images: {
					...state.images,
					list: state.images.list.filter((image) => image !== state.images.selected),
					selected: null,
				},
			}
		case DESELECT_FIGURE:
			if (!state.images.selected && !state.visuals.selected) return state
			return {
				...state,
				images: { ...state.images, selected: null },
				visuals: { ...state.visuals, selected: null },
			}
		case STORE_OPTIONS:
			localStorage.setItem(
				localStorageKeys.svgOptions,
				JSON.stringify({
					format: state.format,
					color: state.color,
					strokeWidth: state.strokeWidth,
					opacity: state.opacity,
					text: state.text,
					documentColors: state.documentColors,
				})
			)
			return state
		default:
			throw new Error(`Unknown editor action type: ${action.type}`)
	}
}

export const editorContext = createContext()

export const EditorContextProvider = (props) => {
	const [editorState, dispatchEditorState] = useReducer(editorReducer, initialState)

	return <editorContext.Provider value={[editorState, dispatchEditorState]}>{props.children}</editorContext.Provider>
}
