import { TAG_GET_TAGS, TAG_GET_TAGS_FAILURE, TAG_GET_TAGS_SUCCESS } from './tagTypes'

const initialState = {
	pending: false,
	list: [],
}

const tagReducer = (state = initialState, action) => {
	switch (action.type) {
		case TAG_GET_TAGS:
			return {
				...state,
				pending: true,
			}
		case TAG_GET_TAGS_SUCCESS:
			return {
				pending: false,
				list: action.list,
			}
		case TAG_GET_TAGS_FAILURE:
			return {
				...state,
				pending: false,
			}
		default:
			return state
	}
}

export default tagReducer
