import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GCard, GCrudFormActions, GIcon, GInput, GMessage, GOrganizationSelect, GSecured, GTitleBar, isEmpty, userService } from '@alatimier/genesis-uic'
import { clearState, createDevice, deleteDevices, getDevice, updateDevice } from '../../store/devices/devicesActions'
import AspectRatioSelect from '../../components/ratios/AspectRatioSelect'
import OrganizationSpacesSelect from '../../components/spaces/OrganizationSpacesSelect'
import VariantsEdit from '../../components/devices/variants/VariantsEdit'
import DeviceTypeSelect from '../../components/deviceTypes/DeviceTypeSelect'
import ResolutionSelect from '../../components/resolutions/ResolutionSelect'
import InferenceTypeSelect from '../../components/devices/inferenceType/InferenceTypeSelect'

const DeviceEdit = () => {
	const { t } = useTranslation()
	const params = useParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [organizationId, setOrganizationId] = useState()
	const [name, setName] = useState()
	const [ip, setIp] = useState()
	const [address, setAddress] = useState()
	const [city, setCity] = useState()
	const [zipCode, setZipCode] = useState()
	const [country, setCountry] = useState()
	const [targeting, setTargeting] = useState(false)
	const [monitoring, setMonitoring] = useState(false)
	const [aspectRatioId, setAspectRatioId] = useState(null)
	const [resolutionId, setResolutionId] = useState(null)
	const [spaceId, setSpaceId] = useState(null)
	const [close, setClose] = useState(false)
	const [variants, setVariants] = useState([])
	const [vpnKey, setVpnKey] = useState()
	const [deviceTypeId, setDeviceTypeId] = useState()
	const [inferenceTypeId, setInferenceTypeId] = useState()

	const device = useSelector((state) => state.devices.device)
	const pending = useSelector((state) => state.devices.pending)
	const deviceVariants = useSelector((state) => state.devices.variants)
	const workflowStatus = useSelector((state) => state.devices.status)
	const newDeviceId = useSelector((state) => state.devices.newId)

	useEffect(() => fetchDevice(), [params.id])
	useEffect(() => prefillForm(), [device, deviceVariants])
	useEffect(() => handleWorkflowStatusChange(), [workflowStatus])

	const fetchDevice = () => {
		if (params.id !== 'new' && params.id !== 'clone') {
			dispatch(getDevice(params.id))
		}
	}

	function prefillForm() {
		if (params.id !== 'new' && device) {
			setOrganizationId(device.organizationId)
			setName(device.name)
			setIp(device.ip)
			setAddress(device.address)
			setCity(device.city)
			setZipCode(device.zipCode)
			setCountry(device.country)
			setTargeting(device.targeting)
			setAspectRatioId(device.aspectRatioId)
			setResolutionId(device.resolutionId)
			setSpaceId(device.spaceId)
			if (deviceVariants) {
				setVariants(deviceVariants)
			}
			setVpnKey(device.vpnKey)
			setDeviceTypeId(device.deviceTypeId)
			setMonitoring(device.monitoring)
		}
	}

	const handleWorkflowStatusChange = () => {
		switch (workflowStatus) {
			case 'created':
				close ? doClose() : navigate(`/devices/${newDeviceId}`)
				dispatch(clearState())
				break
			case 'updated':
				close ? doClose() : dispatch(getDevice(params.id))
				break
			case 'deleted':
				doClose()
				break
		}
	}

	const handleSave = () => {
		setClose(true)
		apply()
	}

	const handleDelete = () => {
		dispatch(deleteDevices([device.id]))
	}

	const doClose = () => {
		navigate('/devices')
	}

	function apply() {
		if (device && params.id !== 'new' && params.id !== 'clone') {
			dispatch(
				updateDevice(
					device.id,
					organizationId,
					name,
					ip,
					address,
					city,
					zipCode,
					country,
					targeting,
					aspectRatioId,
					resolutionId,
					spaceId,
					variants,
					vpnKey,
					deviceTypeId,
					inferenceTypeId,
					monitoring
				)
			)
		} else {
			dispatch(
				createDevice(
					organizationId,
					name,
					ip,
					address,
					city,
					zipCode,
					country,
					targeting,
					aspectRatioId,
					resolutionId,
					spaceId,
					variants,
					vpnKey,
					deviceTypeId,
					inferenceTypeId,
					monitoring
				)
			)
		}
	}

	const isValid = () =>
		!isEmpty(organizationId) &&
		!isEmpty(name) &&
		!isEmpty(ip) &&
		!isEmpty(address) &&
		!isEmpty(city) &&
		!isEmpty(zipCode) &&
		!isEmpty(country) &&
		!isEmpty(aspectRatioId) &&
		!isEmpty(resolutionId) &&
		!isEmpty(deviceTypeId)

	const renderHealth = () => {
		if (device && device.monitoring && device.health) {
			let icon
			let color
			if (device.health.status === 'OFF') {
				color = 'secondary'
				icon = 'fa-warning'
			}
			if (device.health.status === 'RED') {
				color = 'danger'
				icon = 'fa-warning'
			}
			if (device.health.status === 'ORANGE') {
				color = 'warning'
				icon = 'fa-warning'
			}
			if (device.health.status === 'GREEN') {
				color = 'success'
				icon = 'fa-circle-check'
			}
			const message = (
				<div>
					<span>
						<GIcon name={icon} className="m-1" />
						{t(`devices.health.${device.health?.status}`)}
					</span>
					{['RED', 'ORANGE'].includes(device.health?.status) && (
						<ul>
							{device.health?.hd !== 'GREEN' && <li>t(`devices.health.hd.${device.health?.hd}`)</li>}
							<li>{device.health?.displays !== 'GREEN' && t(`devices.health.displays.${device.health?.displays}`)}</li>
						</ul>
					)}
				</div>
			)
			return <GMessage value={message} color={color} />
		}
		return <></>
	}

	return (
		<>
			<GTitleBar>{t('devices.title')}</GTitleBar>
			<GSecured permission={'DEVICE_HEALTH_READ'}>{renderHealth()}</GSecured>
			<GCard title={t('devices.properties.title')} row>
				<GOrganizationSelect id="organizationId" value={organizationId} onChange={setOrganizationId} permission="DEVICE_EDIT" />
				<GInput id="name" label={t('devices.form.name')} value={name} onChange={setName} required />
				<GInput id="ip" label={t('devices.form.ip')} value={ip} onChange={setIp} required />
				<GSecured permission="DEVICE_INSTALL_EDIT">
					<GInput id="vpnKey" label={t('devices.form.vpnKey')} type="textarea" value={vpnKey} onChange={setVpnKey} required />
				</GSecured>
				<GInput id="address" label={t('devices.form.address')} value={address} onChange={setAddress} required />
				<GInput id="city" label={t('devices.form.city')} value={city} onChange={setCity} required />
				<GInput id="zipCode" label={t('devices.form.zipCode')} value={zipCode} onChange={setZipCode} required />
				<GInput id="country" label={t('devices.form.country')} value={country} onChange={setCountry} required />
				<GInput id="targeting" label={t('devices.form.targeting')} type="checkbox" value={targeting} onChange={setTargeting} />
				<GInput id="monitoring" label={t('devices.form.monitoring')} type="checkbox" value={monitoring} onChange={setMonitoring} />
				<AspectRatioSelect id="aspectRatioId" value={aspectRatioId} onChange={setAspectRatioId} required />
				<ResolutionSelect id="resolutionId" value={resolutionId} onChange={setResolutionId} required />
				<OrganizationSpacesSelect id="spaceId" organizationId={organizationId} value={spaceId} onChange={setSpaceId} autoHide />
				<DeviceTypeSelect id="deviceTypeId" value={deviceTypeId} onChange={setDeviceTypeId} required />
				<InferenceTypeSelect id="inferenceTypeId" value={inferenceTypeId} onChange={setInferenceTypeId} required />
				<GCrudFormActions
					canSave={isValid()}
					onApply={apply}
					onSave={handleSave}
					canCancel
					onCancel={doClose}
					canDelete={!!device}
					onDelete={handleDelete}
					busy={pending}
					location={['page', 'page-top']}
				/>
			</GCard>
			<GSecured permission="DVM_READ">
				{(userService.hasPermission('DVM_EDIT') || !isEmpty(variants)) && (
					<GCard title={t('variants.title')} row>
						<VariantsEdit variants={variants} onChange={setVariants} />
					</GCard>
				)}
			</GSecured>
		</>
	)
}

export default DeviceEdit
