import advertisementsReducer from './advertisements/advertisementsReducer'
import aspectRatiosReducer from './ratios/aspectRatiosReducer'
import campaignsReducer from './campaigns/campaignsReducer'
import devicesReducer from './devices/devicesReducer'
import exportReducer from './export/exportReducer'
import labReducer from './lab/labReducer'
import objectivesReducer from './objectives/objectivesReducer'
import organizationsReducer from './organizations/organizationsReducer'
import resolutionsReducer from './resolutions/resolutionsReducer'
import salesReducer from './sales/salesReducer'
import segmentsReducer from './segments/segmentsReducer'
import statisticsReducer from './statistics/statisticsReducer'
import tagReducer from './tag/tagReducer'
import visualWallsReducer from './visualwalls/visualWallsReducer'
import visualsReducer from './visuals/visualsReducer'
import weathersReducer from './weathers/weathersReducer'

const reducers = {
	advertisements: advertisementsReducer,
	campaigns: campaignsReducer,
	devices: devicesReducer,
	export: exportReducer,
	lab: labReducer,
	objectives: objectivesReducer,
	organizations: organizationsReducer,
	ratios: aspectRatiosReducer,
	resolutions: resolutionsReducer,
	sales: salesReducer,
	segments: segmentsReducer,
	statistics: statisticsReducer,
	tags: tagReducer,
	visualWalls: visualWallsReducer,
	visuals: visualsReducer,
	weathers: weathersReducer,
}

export default reducers
