import last from 'lodash/last'
import { DELETE_IMAGE, SET_MODE } from '../editorContext'

export const centerSelectedElements = (canvas) => {
	canvas.alignSelectedElements('middle', 'page')
	canvas.alignSelectedElements('center', 'page')
	while (last(canvas.undoMgr.undoStack)?.text === 'position') canvas.undoMgr.undoStack.pop()
	canvas.undoMgr.undoStackPointer = canvas.undoMgr.undoStack.length
}

export const deleteSelected = (editorState, dispatchEditorState) => {
	const elementsAreSelected = editorState.selectedElement || editorState.multiSelected
	if (elementsAreSelected) {
		editorState.canvas.deleteSelectedElements()
	} else if (editorState.images.selected) {
		dispatchEditorState({ type: DELETE_IMAGE })
	}
}

export const setMode = (editorState, dispatchEditorState, mode) => {
	editorState.canvas.setMode(mode)
	dispatchEditorState({ type: SET_MODE, mode })
}

export const rotateSelected = (editorState, clockwise, step) => {
	if (!editorState.selectedElement) return
	if (!clockwise) step *= -1
	const angle = editorState.canvas.getRotationAngle(editorState.selectedElement) + step
	editorState.canvas.setRotationAngle(angle)
}

export const clearDrawing = (canvas) => {
	canvas.clearSelection()
	const svgContents = document.querySelectorAll('#svgcontent > g > *:not(title)')
	svgContents.forEach((node) => node.remove())
	canvas.undoMgr.resetUndoStack()
}

export const undo = (editorState) => {
	const { undoMgr, textActions } = editorState.canvas
	if (undoMgr.getUndoStackSize() > 0) {
		undoMgr.undo()
		if (editorState.mode === 'textedit') textActions.clear()
	}
}

export const redo = (editorState) => {
	const { undoMgr } = editorState.canvas
	if (undoMgr.getRedoStackSize() > 0) {
		undoMgr.redo()
	}
}

export const copySelected = (editorState) => {
	try {
		editorState.canvas.copySelectedElements()
	} catch (e) {
		// Catching code error in svgedit because we don't use their editor
	}
}

export const cutSelected = (editorState) => {
	copySelected(editorState)
	editorState.canvas.deleteSelectedElements()
}

export const pasteInCenter = (editorState) => {
	editorState.canvas.pasteElements('point', 0, 0)
	centerSelectedElements(editorState.canvas)
}

export const pasteOnClick = (editorState) => {
	editorState.canvas.pasteElements()
}
