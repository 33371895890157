import { copySelected, cutSelected, deleteSelected, pasteInCenter, redo, rotateSelected, setMode, undo } from './actions'
import { getKeyHandlers } from './getKeyHandlers'

export const defaultColors = [
	'#000000',
	'#ffffff',
	'#808080',
	'#c0c0c0',
	'#800000',
	'#ff0000',
	'#800080',
	'#ff00ff',
	'#008000',
	'#00ff00',
	'#808000',
	'#ffff00',
	'#000080',
	'#0000ff',
	'#008080',
	'#00ffff',
]

// The fonts used must be installed on the svg reader
export const fontFamilies = [
	'Arial',
	'Times New Roman',
	'Courier New',
	'Verdana',
	'Georgia',
	'Trebuchet MS',
	'Arial Black',
	'Impact',
	'Comic Sans MS',
	'Andale Mono',
	'Roboto',
	'Roboto Light',
	'Webdings',
]

export const textPresets = [
	{ id: 'heading', size: 130, bold: true },
	{ id: 'subtitle', size: 90 },
	{ id: 'text', size: 70 },
]

const isMac = navigator.userAgent.includes('Macintosh')
const modKey = isMac ? 'meta+' : 'ctrl+'
const shortcuts = [
	{
		key: ['backspace', 'delete'],
		fn: deleteSelected,
	},
	{
		key: 's',
		fn: (editorState, dispatchEditorState) => {
			setMode(editorState, dispatchEditorState, 'select')
		},
	},
	{
		key: 'p',
		fn: (editorState, dispatchEditorState) => {
			setMode(editorState, dispatchEditorState, 'panning')
		},
	},
	{
		key: 'c',
		fn: (editorState) => editorState.canvas.cloneSelectedElements(20, 20),
	},
	{
		key: 'g',
		fn: (editorState) => {
			if (editorState.selectedElement?.tagName === 'g') editorState.canvas.ungroupSelectedElement()
			else if (editorState.multiSelected) editorState.canvas.groupSelectedElements()
		},
	},
	{
		key: modKey + 'z',
		fn: (editorState) => undo(editorState),
	},
	{
		key: ['shift' + modKey + 'z', modKey + 'y'],
		fn: (editorState) => redo(editorState),
	},
	{
		key: 'arrowup',
		fn: (editorState) => editorState.canvas.moveSelectedElements(0, -1),
	},
	{
		key: 'arrowdown',
		fn: (editorState) => editorState.canvas.moveSelectedElements(0, 1),
	},
	{
		key: 'arrowleft',
		fn: (editorState) => editorState.canvas.moveSelectedElements(-1, 0),
	},
	{
		key: 'arrowright',
		fn: (editorState) => editorState.canvas.moveSelectedElements(1, 0),
	},
	{
		key: 'shift+arrowup',
		fn: (editorState) => editorState.canvas.moveSelectedElements(0, -10),
	},
	{
		key: 'shift+arrowdown',
		fn: (editorState) => editorState.canvas.moveSelectedElements(0, 10),
	},
	{
		key: 'shift+arrowleft',
		fn: (editorState) => editorState.canvas.moveSelectedElements(-10, 0),
	},
	{
		key: 'shift+arrowright',
		fn: (editorState) => editorState.canvas.moveSelectedElements(10, 0),
	},
	{
		key: 'ctrl+arrowleft',
		fn: (editorState) => rotateSelected(editorState, false, 1),
	},
	{
		key: 'ctrl+arrowright',
		fn: (editorState) => rotateSelected(editorState, true, 1),
	},
	{
		key: modKey + 'a',
		fn: (editorState) => editorState.canvas.selectAllInCurrentLayer(),
	},
	{
		key: modKey + 'c',
		fn: (editorState) => copySelected(editorState),
	},
	{
		key: modKey + 'x',
		fn: (editorState) => cutSelected(editorState),
	},
	{
		key: modKey + 'v',
		fn: (editorState) => pasteInCenter(editorState),
	},
]
export const keyHandlers = getKeyHandlers(shortcuts)
