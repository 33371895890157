import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { isEmpty } from '@alatimier/genesis-uic'
import { segmentColors } from '../../utils/ChartUtils'
import DisplayVolumeChart from '../../components/dashboard/DisplayVolumeChart/DisplayVolumeChart'
import SegmentedViewsChart from '../../components/dashboard/SegmentedViewsChart/SegmentedViewsChart'
import AttentionTimeChart from '../../components/dashboard/AttentionTimeChart/AttentionTimeChart'

const ViewsDashboard = (props) => {
	const { t } = useTranslation()

	return (
		!isEmpty(props.data) && (
			<>
				<DisplayVolumeChart title={t('dashboard.displayVolume.title')} data={props.data} dates={props.dates} dateFormat={props.dateFormat} />
				<SegmentedViewsChart
					title={t('dashboard.genderViews.title')}
					data={props.data}
					dataKey="genders"
					pieProperties={{
						margin: { top: 35, right: 25, bottom: 10, left: 25 },
						arcLabel: (d) => `${d.value} ${d.value <= 1 ? t(`gender.${d.id}`).toLowerCase() : d.label.toLowerCase()}`,
						colors: ({ data }) => segmentColors[data.id],
						enableArcLinkLabels: false,
					}}
				/>
				<SegmentedViewsChart
					title={t('dashboard.ageViews.title')}
					data={props.data}
					dataKey="ages"
					pieProperties={{
						margin: { top: 35, right: 25, bottom: 10, left: 25 },
						arcLabel: 'value',
						colors: { scheme: 'pastel1' },
						arcLabelsRadiusOffset: 0.8,
					}}
				/>
				<AttentionTimeChart title={t('dashboard.attentionTime.title')} data={props.data} />
			</>
		)
	)
}

ViewsDashboard.propTypes = {
	data: PropTypes.array,
	dates: PropTypes.array.isRequired,
	dateFormat: PropTypes.object.isRequired,
}

export default ViewsDashboard
