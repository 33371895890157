export const updateWorkareaZoom = (zoom, canvas, domCanvas, domWorkarea) => {
	// Update canvas zoom
	canvas.setZoom(zoom)

	// Update canvas dimensions
	let workareaWidth = parseFloat(getComputedStyle(domWorkarea).width.replace('px', ''))
	let workareaHeight = parseFloat(getComputedStyle(domWorkarea).height.replace('px', ''))

	const ratioWorkareaCanvas = 1.1

	const newCanvasWidth = Math.max(workareaWidth, canvas.contentW * zoom * ratioWorkareaCanvas)
	const newCanvasHeight = Math.max(workareaHeight, canvas.contentH * zoom * ratioWorkareaCanvas)
	domCanvas.style.width = newCanvasWidth + 'px'
	domCanvas.style.height = newCanvasHeight + 'px'
	canvas.updateCanvas(newCanvasWidth, newCanvasHeight)

	// Update scrollbars
	const oldCanvasX = parseFloat(getComputedStyle(domCanvas).width.replace('px', '')) / 2
	const oldCanvasY = parseFloat(getComputedStyle(domCanvas).height.replace('px', '')) / 2
	const newCanvasX = newCanvasWidth / 2
	const newCanvasY = newCanvasHeight / 2

	const oldCenter = {
		x: domWorkarea.scrollLeft + workareaWidth / 2,
		y: domWorkarea.scrollTop + workareaHeight / 2,
	}

	const oldDistX = oldCenter.x - oldCanvasX
	const oldDistY = oldCenter.y - oldCanvasY
	const ratio = newCanvasX / oldCanvasX
	const newCenter = {
		x: newCanvasX + oldDistX * ratio,
		y: newCanvasY + oldDistY * ratio,
	}

	domWorkarea.scrollLeft = newCenter.x - workareaWidth / 2
	domWorkarea.scrollTop = newCenter.y - workareaHeight / 2
}
