export const VISUAL_WALLS_GET_VISUAL_WALLS = '@@visualwalls/GET_VISUAL_WALLS'
export const VISUAL_WALLS_GET_VISUAL_WALLS_SUCCESS = '@@visualwalls/GET_VISUAL_WALLS_SUCCESS'
export const VISUAL_WALLS_GET_VISUAL_WALLS_FAILURE = '@@visualwalls/GET_VISUAL_WALLS_FAILURE'

export const VISUAL_WALLS_GET_VISUAL_WALL = '@@visualwalls/GET_VISUAL_WALL'
export const VISUAL_WALLS_GET_VISUAL_WALL_SUCCESS = '@@visualwalls/GET_VISUAL_WALL_SUCCESS'
export const VISUAL_WALLS_GET_VISUAL_WALL_FAILURE = '@@visualwalls/GET_VISUAL_WALL_FAILURE'

export const VISUAL_WALLS_CREATE_VISUAL_WALL = '@@visualwalls/CREATE_VISUAL_WALL'
export const VISUAL_WALLS_CREATE_VISUAL_WALL_SUCCESS = '@@visualwalls/CREATE_VISUAL_WALL_SUCCESS'
export const VISUAL_WALLS_CREATE_VISUAL_WALL_FAILURE = '@@visualwalls/CREATE_VISUAL_WALL_FAILURE'

export const VISUAL_WALLS_UPDATE_VISUAL_WALL = '@@visualwalls/UPDATE_VISUAL_WALL'
export const VISUAL_WALLS_UPDATE_VISUAL_WALL_SUCCESS = '@@visualwalls/UPDATE_VISUAL_WALL_SUCCESS'
export const VISUAL_WALLS_UPDATE_VISUAL_WALL_FAILURE = '@@visualwalls/UPDATE_VISUAL_WALL_FAILURE'

export const VISUAL_WALLS_DELETE_VISUAL_WALL = '@@visualwalls/DELETE_VISUAL_WALL'
export const VISUAL_WALLS_DELETE_VISUAL_WALL_SUCCESS = '@@visualwalls/DELETE_VISUAL_WALL_SUCCESS'
export const VISUAL_WALLS_DELETE_VISUAL_WALL_FAILURE = '@@visualwalls/DELETE_VISUAL_WALL_FAILURE'

export const VISUAL_WALLS_CLEAR_STATE = '@@visualwalls/CLEAR_STATE'
