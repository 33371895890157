import SvgCanvas from 'svgedit/src/svgcanvas/svgcanvas'
import { INIT_CANVAS, SET_MODE, SET_SELECTED_ELEMENT, SET_ZOOM, UPDATE } from '../editorContext'
import shapeExtension from './shapes/ext-shapes'

export const initialConfig = {
	initFill: {
		color: 'c0c0c0',
	},
	initStroke: {
		width: 5,
		color: '000000',
	},
	text: {
		font_size: 70,
		font_family: 'Arial',
		stroke_width: 0,
	},
	initOpacity: 1,
	initTool: 'select',
	baseUnit: 'px',
}

export const loadSvg = (canvas, svgString) => {
	// Convert text-decoration attributes to data-text-decoration, then reverse
	// because "setSvgString" "sanitizes" the attribute text-decoration
	canvas.setSvgString(svgString.replaceAll('text-decoration', 'data-text-decoration'))
	document.querySelectorAll('#svgcontent [data-text-decoration]').forEach((node) => {
		node.setAttribute('text-decoration', node.dataset.textDecoration)
		node.removeAttribute('data-text-decoration')
	})
}

export const loadOptions = (canvas, options) => {
	canvas.setColor('fill', options.color.shape.fill)
	canvas.setColor('stroke', options.color.shape.stroke)
	canvas.setFontColor(options.color.text.fill)
	canvas.setStrokeWidth(options.strokeWidth)
	canvas.setOpacity(options.opacity / 100)
	canvas.setFontFamily(options.text.fontFamily)
	canvas.setFontSize(options.text.fontSize)
}

export const initCanvas = (domWorkarea, domCanvas, textInput, dispatchEditorState) => {
	const { width, height } = domWorkarea.getBoundingClientRect()
	initialConfig.dimensions = [width, height]
	const canvas = new SvgCanvas(domCanvas, initialConfig)
	canvas.textActions.setInputElem(textInput)

	dispatchEditorState({ type: INIT_CANVAS, canvas })

	canvas.addExtension(shapeExtension.name, shapeExtension.init.bind(canvas), {})

	// Custom events
	canvas.bind('changed', () => {
		dispatchEditorState({ type: UPDATE })
	})

	canvas.bind('selected', () => {
		domCanvas.querySelectorAll('.movable').forEach((node) => {
			node.classList.remove('movable')
		})
		const elems = canvas.getSelectedElements()
		if (canvas.getMode() === 'select' || canvas.getMode() === 'multiselect') {
			elems.forEach((elem) => elem.classList.add('movable'))
		}
		dispatchEditorState({
			type: SET_SELECTED_ELEMENT,
			selectedElement: elems[0] && !elems[1] ? elems[0] : undefined,
			multiSelected: elems.length >= 2,
			mode: canvas.getMode(),
		})
		// Fixes svgedit bug when selecting a shape or text property
		// (because only canvas setMode() updates selected set of properties)
		if (canvas.getMode() === 'select') canvas.setMode('select')
	})

	// shift + scroll zooms and calls zoomDone event
	canvas.bind('zoomDone', () => {
		dispatchEditorState({ type: SET_ZOOM, zoom: canvas.getZoom() * 100 })
	})

	// Get mode changes on double click (like text mode when editing a text)
	const setMode = () => dispatchEditorState({ type: SET_MODE, mode: canvas.getMode() })
	domCanvas.addEventListener('dblclick', setMode)
	return () => domCanvas.removeEventListener('dblclick', setMode)
}
