import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { GButton, GCard, GInput, GMessage, GOrganizationSelect, GSelect, isEmpty } from '@alatimier/genesis-uic'
import { getVisuals } from '../../store/organizations/organizationsActions'
import OrganizationCampaignSelect from '../../components/campaigns/select/OrganizationCampaignSelect'
import OrganizationVisualsSelect from '../../components/visuals/OrganizationVisualsSelect'
import OrganizationSpacesSelect from '../../components/spaces/OrganizationSpacesSelect'
import OrganizationDeviceSelect from '../../components/devices/select/OrganizationDeviceSelect'
import { useDispatch, useSelector } from 'react-redux'
import { getStatistics } from '../../store/statistics/statisticsActions'
import DateUtils from '../../utils/DateUtils'
import FiltersReminder from '../../components/dashboard/FiltersReminder/FiltersReminder'

const DashboardForm = (props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const timePeriodOptions = [
		{ value: 'today', label: t('timePeriod.today') },
		{ value: 'yesterday', label: t('timePeriod.yesterday') },
		{ value: 'lastWeek', label: t('timePeriod.lastWeek') },
		{ value: 'lastMonth', label: t('timePeriod.lastMonth') },
		{ value: 'custom', label: t('timePeriod.custom') },
	]

	const [timePeriod, setTimePeriod] = useState(timePeriodOptions[0])
	const [organizationId, setOrganizationId] = useState()
	const [targetOrganizationId, setTargetOrganizationId] = useState()
	const [campaignId, setCampaignId] = useState()
	const [visualIds, setVisualIds] = useState([])
	const [deviceIds, setDeviceIds] = useState([])
	const [spaceIds, setSpaceIds] = useState([])
	const [retailMode, setRetailMode] = useState(false)

	const pending = useSelector((state) => state.statistics.pending)
	const organizationCampaigns = useSelector((state) => state.organizations.campaigns[targetOrganizationId])
	const organizationVisuals = useSelector((state) => state.organizations.visuals[organizationId])
	const organizationDevices = useSelector((state) => state.organizations.devices[organizationId])
	const organizationIsLoaded = organizationVisuals && (organizationCampaigns || props.dashboardName === 'audience')

	useEffect(() => {
		if (props.dashboardName === 'audience' && organizationId) dispatch(getVisuals(organizationId))
	}, [organizationId, props.dashboardName])

	const filters = useMemo(() => {
		if (!organizationDevices) return {}
		const filters = {}
		if (props.dashboardName === 'views') {
			const campaign = organizationCampaigns?.find((c) => c.id === campaignId)
			filters.visualIds = campaign?.visualIds
			if (!isEmpty(campaign?.deviceIds)) {
				if (isEmpty(spaceIds)) {
					filters.deviceIds = campaign.deviceIds
				} else {
					filters.deviceIds = organizationDevices
						.filter((device) => campaign.deviceIds.includes(device.id) && spaceIds.includes(device.spaceId))
						.map((device) => device.id)
				}
				filters.spaceIds = [
					...new Set(campaign.deviceIds.map((deviceId) => organizationDevices.find((d) => d.id === deviceId)?.spaceId).filter(Boolean)),
				]
			} else if (!isEmpty(campaign?.spaceIds)) {
				if (isEmpty(spaceIds)) {
					filters.deviceIds = organizationDevices.filter(({ spaceId }) => campaign.spaceIds.includes(spaceId)).map((device) => device.id)
				} else {
					filters.deviceIds = organizationDevices
						.filter(({ spaceId }) => campaign.spaceIds.includes(spaceId) && spaceIds.includes(spaceId))
						.map((device) => device.id)
				}
				filters.spaceIds = campaign.spaceIds
			}
		}
		if (!filters.deviceIds && !isEmpty(spaceIds)) {
			filters.deviceIds = organizationDevices.filter((device) => spaceIds.includes(device.spaceId)).map((device) => device.id)
		}
		return filters
	}, [campaignId, organizationCampaigns, organizationDevices, spaceIds, props.dashboardName])

	const fetchStatistics = () => {
		dispatch(
			getStatistics(
				props.dashboardName,
				organizationId,
				targetOrganizationId,
				campaignId,
				visualIds,
				spaceIds,
				deviceIds,
				DateUtils.toZonedDateTime(props.from),
				DateUtils.toZonedDateTime(props.to)
			)
		)
	}
	const lastDashboardLoaded = useRef()
	useEffect(() => {
		if (
			lastDashboardLoaded.current !== props.dashboardName &&
			organizationVisuals &&
			(props.dashboardName === 'audience' || (organizationCampaigns && organizationCampaigns.find((c) => c.id === campaignId)))
		) {
			lastDashboardLoaded.current = props.dashboardName
			dispatch(
				getStatistics(
					props.dashboardName,
					organizationId,
					targetOrganizationId,
					campaignId,
					[],
					spaceIds,
					deviceIds,
					DateUtils.toZonedDateTime(props.from),
					DateUtils.toZonedDateTime(props.to)
				)
			)
		}
	}, [organizationVisuals, organizationCampaigns, campaignId, props.dashboardName])

	const dayGaps = {
		today: { from: 0, to: 0 },
		yesterday: { from: -1, to: -1 },
		lastWeek: { from: -7, to: 0 },
		lastMonth: { from: -30, to: 0 },
	}
	useEffect(() => {
		if (timePeriod.value !== 'custom') {
			const startOfToday = DateTime.now().startOf('day').set({ second: 0, millisecond: 0 })
			const endOfToday = DateTime.now().endOf('day').set({ second: 0, millisecond: 0 })
			props.onDateChange('from', startOfToday.plus({ day: dayGaps[timePeriod.value].from }).toISO({ includeOffset: false }))
			props.onDateChange('to', endOfToday.plus({ day: dayGaps[timePeriod.value].to }).toISO({ includeOffset: false }))
		}
	}, [timePeriod.value])

	const setDate = (date) => (value) => {
		setTimePeriod(timePeriodOptions.find((o) => o.value === 'custom'))
		props.onDateChange(date, value)
	}

	useEffect(() => {
		const campaign = organizationCampaigns?.find((c) => c.id === campaignId)
		if (campaign) {
			setRetailMode(!campaign.advertiserCampaign)
			if (campaign.endDate && DateTime.fromISO(campaign.endDate) < DateTime.now()) {
				/** Set period to campaign's if it's an old and finished campaign */
				setDate('from')(DateTime.fromISO(campaign.startDate).toISO({ includeOffset: false }))
				setDate('to')(DateTime.fromISO(campaign.endDate).toISO({ includeOffset: false }))
			} else if (DateTime.fromISO(props.to) < DateTime.fromISO(campaign.startDate)) {
				/** Go back to default options if selected period is older than compaign */
				setTimePeriod(timePeriodOptions[0])
			}
		}
	}, [campaignId])

	const dashboardFormClassName = 'dashboardForm'

	return (
		<>
			<GCard row type="collapsable" title={t('dashboard.filters.title')} className={dashboardFormClassName}>
				<div className="row">
					<div className="col">
						<GOrganizationSelect
							id="organizationId"
							permission="STATISTICS_VIEWS"
							labelPosition="up"
							value={organizationId}
							onChange={setOrganizationId}
							maxLevel={1}
						/>
						<GOrganizationSelect
							id="targetOrganizationId"
							topOrganizationId={organizationId}
							permission="STATISTICS_VIEWS"
							label={t('forms.targetOrganization')}
							labelPosition="up"
							value={targetOrganizationId}
							onChange={setTargetOrganizationId}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4">
						<GSelect
							id="timePeriod"
							value={timePeriod}
							label={t('forms.timePeriod')}
							labelPosition="up"
							options={timePeriodOptions}
							onChange={setTimePeriod}
						/>
						<GInput
							id="startDate"
							label={t('forms.date.start')}
							labelPosition="up"
							value={props.from}
							onChange={setDate('from')}
							type="datetime-local"
						/>
						<GInput id="endDate" label={t('forms.date.end')} labelPosition="up" value={props.to} onChange={setDate('to')} type="datetime-local" />
					</div>
					<div className="col-md-8 ps-lg-5">
						{props.dashboardName === 'views' && (
							<>
								<OrganizationCampaignSelect
									id="campaignId"
									labelPosition="up"
									organizationId={targetOrganizationId}
									value={campaignId}
									onChange={setCampaignId}
									onlyStarted
									semiRequired
								/>
								<OrganizationVisualsSelect
									id="visualIds"
									labelPosition="up"
									organizationId={organizationId}
									filteredVisualIds={filters.visualIds}
									value={visualIds}
									onChange={setVisualIds}
									multiple
									semiRequired
								/>
							</>
						)}
						{retailMode && (
							<>
								<OrganizationSpacesSelect
									id="spaceIds"
									labelPosition="up"
									organizationId={targetOrganizationId}
									filteredSpaceIds={filters.spaceIds}
									value={spaceIds}
									onChange={setSpaceIds}
									multiple
								/>
								<OrganizationDeviceSelect
									id="deviceIds"
									labelPosition="up"
									organizationId={targetOrganizationId}
									filteredDeviceIds={filters.deviceIds}
									value={deviceIds}
									onChange={setDeviceIds}
								/>
							</>
						)}
					</div>
				</div>
				{organizationIsLoaded && props.emptySelection && <GMessage value={t('dashboard.noselection')} color="danger" />}
				<GButton label={t('action.apply')} onClick={fetchStatistics} location={'card'} busy={pending} disabled={!organizationIsLoaded} />
			</GCard>
			<FiltersReminder dashboardName={props.dashboardName} dashboardFormClassName={dashboardFormClassName} />
		</>
	)
}

DashboardForm.propTypes = {
	dashboardName: PropTypes.string.isRequired,
	from: PropTypes.string,
	to: PropTypes.string,
	onDateChange: PropTypes.func.isRequired,
	emptySelection: PropTypes.bool,
}

export default DashboardForm
