import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { GSelect, isEmpty } from '@alatimier/genesis-uic'
import { getCampaigns } from '../../../store/organizations/organizationsActions'

const OrganizationCampaignSelect = (props) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [options, setOptions] = useState([])
	const [selectedOption, setSelectedOption] = useState()
	const [loading, setLoading] = useState(true)

	const organizationCampaigns = useSelector((state) => state.organizations.campaigns[props.organizationId])
	const organizationPending = useSelector((state) => state.organizations.pending)
	const campaigns = useMemo(() => organizationCampaigns ?? [], [organizationCampaigns])

	useEffect(() => fetchOrganizationCampaigns(), [props.organizationId])
	useEffect(() => buildOptions(), [campaigns, props.onlyStarted, props.startedBefore, props.filteredStatus])
	useEffect(() => initSelectedOptions(), [props.value, options])

	const fetchOrganizationCampaigns = () => {
		if (props.organizationId) {
			setLoading(true)
			setOptions([])
			setSelectedOption(undefined)
			dispatch(getCampaigns(props.organizationId, props.organizationType))
		}
	}

	const buildOptions = () => {
		if (organizationCampaigns && !organizationPending) setLoading(false)
		const statusOrder = { ACTIVE: 1, INACTIVE: 2 }
		const now = new Date().toISOString()
		setOptions(
			campaigns
				.filter((c) => {
					if (props.onlyStarted && c.startDate > now) return false
					if (props.startedBefore && c.startDate > props.startedBefore) return false
					if (props.filteredStatus && !props.filteredStatus.includes(c.status)) return false
					return true
				})
				.sort((c1, c2) => {
					if (c1.startDate > now !== c2.startDate > now) return c1.startDate < c2.startDate ? -1 : 1
					if (c1.status !== c2.status) return statusOrder[c1.status] - statusOrder[c2.status]
					return c1.startDate < c2.startDate ? 1 : -1
				})
				.map((c) => ({
					value: c.id.toString(),
					label: c.name,
				}))
		)
	}

	const initSelectedOptions = () => {
		if (!loading) {
			const defaultOption = props.autoSelect && options[0] ? options[0] : undefined
			let selected
			if (props.value === null && selectedOption) selected = null
			else selected = props.value ? options.find((c) => c.value === props.value.toString()) || defaultOption : defaultOption
			handleChange(selected)
			setSelectedOption(selected)
		}
	}

	const handleChange = (selectedCampaign) => {
		props.onChange(selectedCampaign ? parseInt(selectedCampaign.value) : null)
	}

	if (props.autoHide && isEmpty(options)) {
		return <></>
	}

	return (
		<GSelect
			id={props.id}
			name={props.name}
			label={`${props.label ? props.label : t('forms.campaign')}${props.semiRequired ? ' (*)' : ''}`}
			labelPosition={props.labelPosition}
			placeholder={props.placeholder}
			value={selectedOption}
			options={options}
			loading={loading}
			clearable
			onChange={handleChange}
			onBlur={props.onBlur}
			help={props.help}
			error={props.error}
			required={props.required}
			disabled={props.disabled}
			className={props.className}
		/>
	)
}

OrganizationCampaignSelect.defaultProps = {
	autoSelect: true,
}

OrganizationCampaignSelect.propTypes = {
	id: PropTypes.string.isRequired,
	organizationId: PropTypes.number,
	organizationType: PropTypes.oneOf(['PROPRIETARY', 'TARGET']),
	name: PropTypes.string,
	label: PropTypes.string,
	labelPosition: PropTypes.oneOf(['left', 'up']),
	placeholder: PropTypes.string,
	value: PropTypes.number,
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func,
	help: PropTypes.string,
	error: PropTypes.string,
	required: PropTypes.bool,
	semiRequired: PropTypes.bool,
	disabled: PropTypes.bool,
	autoHide: PropTypes.bool,
	autoSelect: PropTypes.bool,
	onlyStarted: PropTypes.bool,
	startedBefore: PropTypes.string,
	filteredStatus: PropTypes.arrayOf(PropTypes.oneOf(['ACTIVE', 'INACTIVE'])),
	className: PropTypes.string,
}

export default OrganizationCampaignSelect
