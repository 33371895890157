import {
	LAB_CLEAR_STATE,
	LAB_CREATE_CAMPAIGN,
	LAB_CREATE_CAMPAIGN_SUCCESS,
	LAB_CREATE_CAMPAIGN_FAILURE,
	LAB_GET_CAMPAIGNS,
	LAB_GET_CAMPAIGNS_SUCCESS,
	LAB_GET_CAMPAIGNS_FAILURE,
} from './labTypes'

const initialState = {
	pending: false,
	list: [],
	labcampaign: null,
	newId: null,
	url: null,
	status: null,
}

const labReducer = (state = initialState, action) => {
	switch (action.type) {
		case LAB_GET_CAMPAIGNS:
		case LAB_CREATE_CAMPAIGN:
			return {
				...state,
				pending: true,
				status: null,
			}
		case LAB_GET_CAMPAIGNS_SUCCESS:
			return {
				pending: false,
				list: action.list,
			}
		case LAB_CREATE_CAMPAIGN_SUCCESS:
			return {
				pending: false,
				newId: action.newId,
				url: action.url,
				status: 'created',
			}
		case LAB_GET_CAMPAIGNS_FAILURE:
		case LAB_CREATE_CAMPAIGN_FAILURE:
			return {
				...state,
				pending: false,
			}
		case LAB_CLEAR_STATE:
			return {
				...initialState,
			}
		default:
			return state
	}
}

export default labReducer
