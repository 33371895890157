import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { GButton, GCard, GCrudFormActions, GIcon, GInput, GOrganizationSelect, GTitleBar, isEmpty } from '@alatimier/genesis-uic'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { clearState, createVisualWall, deleteVisualWalls, getVisualWall, updateVisualWall } from '../../store/visualwalls/visualWallsActions'
import TagSelect from '../../components/tags/TagSelect'
import OrganizationVisualsSelect from '../../components/visuals/OrganizationVisualsSelect'

const VisualWallEdit = () => {
	const { t } = useTranslation()

	const isDarkMode = useSelector((store) => store.g_display.darkMode)

	const params = useParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [loading, setLoading] = useState(true)
	const [organizationId, setOrganizationId] = useState()
	const [name, setName] = useState()
	const [visualIdLines, setVisualIdLines] = useState([[]])
	const [tags, setTags] = useState([])

	const [close, setClose] = useState(false)

	const visualWall = useSelector((state) => state.visualWalls.visualWall)
	const pending = useSelector((state) => state.visualWalls.pending)
	const workflowStatus = useSelector((state) => state.visualWalls.status)
	const newVisualWallId = useSelector((state) => state.visualWalls.newId)

	useEffect(() => fetchVisualWall(), [params.id])
	useEffect(() => prefillForm(), [visualWall])
	useEffect(() => handleWorkflowStatusChange(), [workflowStatus])

	const isValid = () => !isEmpty(organizationId) && !isEmpty(name) && visualIdLines.every((line) => !isEmpty(line))

	const fetchVisualWall = () => {
		if (params.id && params.id !== 'new') {
			setLoading(true)
			dispatch(getVisualWall(params.id))
		} else {
			setLoading(false)
		}
	}

	function prefillForm() {
		if (params.id !== 'new' && visualWall) {
			setOrganizationId(visualWall.organizationId)
			setName(visualWall.name)
			setVisualIdLines(visualWall.visualLines.map((line) => line.visuals.map((visual) => visual.visualId)))
			setTags(visualWall.tags)
			setLoading(false)
		}
	}

	const handleWorkflowStatusChange = () => {
		switch (workflowStatus) {
			case 'created':
				close ? doClose() : navigate(`/visualWalls/${newVisualWallId}`)
				dispatch(clearState())
				break
			case 'updated':
				close ? doClose() : dispatch(getVisualWall(params.id))
				break
			case 'deleted':
				doClose()
				break
		}
	}

	const handleSave = () => {
		setClose(true)
		apply()
	}

	const handleDelete = () => {
		dispatch(deleteVisualWalls([visualWall.id]))
	}

	const doClose = () => {
		navigate('/visualWalls')
	}

	const apply = () => {
		if (visualWall) {
			dispatch(updateVisualWall(visualWall.id, organizationId, name, visualIdLines, tags))
		} else {
			dispatch(createVisualWall(organizationId, name, visualIdLines, tags))
		}
	}

	const renderPending = () => (
		<GCard title={t('visualwalls.properties.title')} row>
			<div className="text-center">
				<GIcon name="fa-spinner" spin />
			</div>
		</GCard>
	)

	const handleAddLine = () => {
		setVisualIdLines([...visualIdLines, []])
	}
	const handleRemoveLine = (index) => {
		setVisualIdLines(visualIdLines.filter((_, i) => i !== index))
	}
	const handleEditLine = (index, newLine) => {
		setVisualIdLines(
			visualIdLines.map((currentLine, idx) => {
				if (idx === index) {
					return newLine
				}
				return currentLine
			})
		)
	}

	const renderForm = () => (
		<>
			<GCard title={t('visualwalls.properties.title')} row>
				<GOrganizationSelect id="organizationId" value={organizationId} onChange={setOrganizationId} maxLevel={1} permission="VISUAL_WALL_EDIT" />
				<GInput id="name" label={t('visualwalls.form.name')} value={name} onChange={setName} required />
				<div className="row mb-3">
					<div className="col-md-2">
						<label className="form-label mb-1 text-break" htmlFor="name">
							{t('visualwalls.form.visuallines') + '*'}
						</label>
					</div>
					<div className="col-md-10">
						{visualIdLines.map((visualLine, index) => (
							<div className="row mb-3">
								<div className="col-md-10">
									<OrganizationVisualsSelect
										key={index}
										id="visualLines"
										organizationId={organizationId}
										value={visualLine}
										onChange={(newLine) => handleEditLine(index, newLine)}
										multiple
										required
										simple
									/>
								</div>
								<div className="col-md-2">
									{visualIdLines.length > 1 && (
										<GButton
											icon="fa-minus"
											title={t('action.remove')}
											onClick={() => handleRemoveLine(index)}
											color={isDarkMode ? 'dark' : 'light'}
											className="mx-1 mt-0"
										/>
									)}
									{index === visualIdLines.length - 1 && (
										<GButton
											icon="fa-plus"
											title={t('action.add')}
											onClick={handleAddLine}
											color={isDarkMode ? 'dark' : 'light'}
											className="mx-1 mt-0"
										/>
									)}
								</div>
							</div>
						))}
					</div>
				</div>
				<TagSelect id="tags" value={tags} onChange={setTags} />
			</GCard>
		</>
	)

	return (
		<>
			<GTitleBar>{t('visualwalls.title')}</GTitleBar>
			{loading ? renderPending() : renderForm()}
			<GCrudFormActions
				canSave={isValid()}
				onApply={apply}
				onSave={handleSave}
				canCancel
				onCancel={doClose}
				canDelete={!!visualWall}
				onDelete={handleDelete}
				busy={pending}
				location={['page', 'page-top']}
			/>
		</>
	)
}

export default VisualWallEdit
