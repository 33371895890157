import { apiClientService as client, setErrors, setSuccess } from '@alatimier/genesis-uic'
import {
	SEGMENTS_CLEAR_STATE,
	SEGMENTS_CREATE_SEGMENT,
	SEGMENTS_CREATE_SEGMENT_FAILURE,
	SEGMENTS_CREATE_SEGMENT_SUCCESS,
	SEGMENTS_DELETE_SEGMENT,
	SEGMENTS_DELETE_SEGMENT_FAILURE,
	SEGMENTS_DELETE_SEGMENT_SUCCESS,
	SEGMENTS_GET_GENDERS,
	SEGMENTS_GET_GENDERS_FAILURE,
	SEGMENTS_GET_GENDERS_SUCCESS,
	SEGMENTS_GET_CLOTHES,
	SEGMENTS_GET_CLOTHES_FAILURE,
	SEGMENTS_GET_CLOTHES_SUCCESS,
	SEGMENTS_GET_SEGMENT,
	SEGMENTS_GET_SEGMENT_FAILURE,
	SEGMENTS_GET_SEGMENT_SUCCESS,
	SEGMENTS_GET_SEGMENTS,
	SEGMENTS_GET_SEGMENTS_FAILURE,
	SEGMENTS_GET_SEGMENTS_SUCCESS,
	SEGMENTS_UPDATE_SEGMENT,
	SEGMENTS_UPDATE_SEGMENT_FAILURE,
	SEGMENTS_UPDATE_SEGMENT_SUCCESS,
} from './segmentsTypes'

const segmentsEndPoint = '/api/admin/v1/segments'

export const getSegments = () => {
	return (dispatch) => {
		dispatch({ type: SEGMENTS_GET_SEGMENTS })
		return client.get(segmentsEndPoint).then(async (res) => {
			if (res.ok) {
				const data = await res.json()
				dispatch({
					type: SEGMENTS_GET_SEGMENTS_SUCCESS,
					list: data,
				})
			} else {
				dispatch({
					type: SEGMENTS_GET_SEGMENTS_FAILURE,
				})
			}
		})
	}
}

export const getSegment = (id) => {
	return (dispatch) => {
		dispatch({ type: SEGMENTS_GET_SEGMENT })
		return client.get(`${segmentsEndPoint}/${id}`).then(async (res) => {
			if (res.ok) {
				const data = await res.json()
				dispatch({
					type: SEGMENTS_GET_SEGMENT_SUCCESS,
					segment: data,
				})
			} else {
				dispatch({
					type: SEGMENTS_GET_SEGMENT_FAILURE,
				})
			}
		})
	}
}

export const createSegment = (organizationId, name, genders, minAge, maxAge, clothes) => {
	return (dispatch) => {
		dispatch({ type: SEGMENTS_CREATE_SEGMENT })
		return client.post(segmentsEndPoint, { organizationId, name, genders, minAge, maxAge, clothes }).then(async (res) => {
			const data = await res.json()
			if (res.ok) {
				dispatch(setSuccess('action.save.success'))
				dispatch({
					type: SEGMENTS_CREATE_SEGMENT_SUCCESS,
					newId: data.id,
				})
			} else {
				dispatch(setErrors(data.errors, data.validationErrors))
				dispatch({
					type: SEGMENTS_CREATE_SEGMENT_FAILURE,
				})
			}
		})
	}
}

export const updateSegment = (id, organizationId, name, genders, minAge, maxAge, clothes) => {
	return (dispatch) => {
		dispatch({ type: SEGMENTS_UPDATE_SEGMENT })
		return client
			.put(`${segmentsEndPoint}/${id}`, {
				organizationId,
				name,
				genders,
				minAge,
				maxAge,
				clothes,
			})
			.then(async (res) => {
				if (res.ok) {
					dispatch(setSuccess('action.save.success'))
					dispatch({
						type: SEGMENTS_UPDATE_SEGMENT_SUCCESS,
					})
				} else {
					const data = await res.json()
					dispatch(setErrors(data.errors, data.validationErrors))
					dispatch({
						type: SEGMENTS_UPDATE_SEGMENT_FAILURE,
					})
				}
			})
	}
}

export const deleteSegments = (ids) => {
	return (dispatch) => {
		dispatch({ type: SEGMENTS_DELETE_SEGMENT })
		const promises = ids.map((id) => client.del(`${segmentsEndPoint}/${id}`))
		return Promise.all(promises).then(async (results) => {
			if (results.every((r) => r.ok)) {
				dispatch(setSuccess('action.delete.success'))
			} else {
				const data = await results.find((r) => !r.ok).json()
				dispatch(setErrors(data.errors, data.validationErrors))
				dispatch({ type: SEGMENTS_DELETE_SEGMENT_FAILURE })
			}
			if (results.some((r) => r.ok)) dispatch({ type: SEGMENTS_DELETE_SEGMENT_SUCCESS })
		})
	}
}

export const getGenders = () => {
	return (dispatch) => {
		dispatch({ type: SEGMENTS_GET_GENDERS })
		return client.get(`${segmentsEndPoint}/genders`).then(async (res) => {
			if (res.ok) {
				const data = await res.json()
				dispatch({
					type: SEGMENTS_GET_GENDERS_SUCCESS,
					genders: data,
				})
			} else {
				dispatch({
					type: SEGMENTS_GET_GENDERS_FAILURE,
				})
			}
		})
	}
}

export const getClothes = () => {
	return (dispatch) => {
		dispatch({ type: SEGMENTS_GET_CLOTHES })
		return client.get(`${segmentsEndPoint}/clothes`).then(async (res) => {
			if (res.ok) {
				const data = await res.json()
				dispatch({
					type: SEGMENTS_GET_CLOTHES_SUCCESS,
					clothes: data,
				})
			} else {
				dispatch({
					type: SEGMENTS_GET_CLOTHES_FAILURE,
				})
				// TODO: remove this (useful to test SegmentEdit)
				// dispatch({
				// 	type: SEGMENTS_GET_CLOTHES_SUCCESS,
				// 	clothes: ['Tank', 'Short', 'Skirt'],
				// })
			}
		})
	}
}

export const clearState = () => {
	return {
		type: SEGMENTS_CLEAR_STATE,
	}
}
