import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import head from 'lodash/head'
import last from 'lodash/last'
import { ResponsiveLine } from '@nivo/line'
import { isEmpty, GMessage, GTooltip } from '@alatimier/genesis-uic'
import { darkerBackgroundTheme, darkModeTheme, objectiveColors } from '../../../utils/ChartUtils'
import { formatDate, getDateUnitAndFormat, getMarker, objectiveGraphContext } from '../utils'

const TradeMarketingChart = memo((props) => {
	const { t } = useTranslation()

	const isDarkMode = useSelector((store) => store.g_display.darkMode)

	const { campaign, visual, cumulatedData, measureEndDate } = props.data
	const cost = props.cost || 0

	const dates = Object.keys(cumulatedData).sort()

	const { dateFormat } = getDateUnitAndFormat(campaign.startDate, measureEndDate, head(dates) ?? campaign.startDate, last(dates) ?? measureEndDate)

	const lineData = [
		{
			id: 'measured',
			data: Object.entries(cumulatedData).map(([date, { turnover }]) => {
				return { x: date, y: turnover }
			}),
		},
	]

	const emptyData = isEmpty(lineData[0].data.filter(({ y }) => y))
	if (emptyData) {
		return <GMessage value={t('objectives.chart.noData')} className="mb-0" />
	}

	const dateTickStep = Math.ceil(dates.length / 18)

	const formatValue = (value) => `${Number(value).toLocaleString(undefined, { maximumFractionDigits: 2 })}${t('objectives.currency')}`

	const totalTurnover = last(Object.values(cumulatedData)).turnover
	const totalBenefit = last(Object.values(cumulatedData)).benefit
	const totalQuantity = last(Object.values(cumulatedData)).quantity

	return (
		<div className="w-100">
			<div data-testid="trade-marketing-chart" style={{ height: props.context === objectiveGraphContext.LIST ? '250px' : '350px', width: '100%' }}>
				<ResponsiveLine
					data={lineData}
					margin={{ top: 20, right: 30, bottom: 25, left: 70 }}
					xFormat={formatDate(dateFormat)}
					yFormat={formatValue}
					yScale={{ type: 'linear', max: Math.max(cost, totalTurnover) }}
					axisLeft={{
						legend: `${t('objectives.chart.turnover')} (€)`,
						legendPosition: 'middle',
						legendOffset: -65,
					}}
					axisBottom={{
						format: (date) => dates.indexOf(date) % dateTickStep === 0 && formatDate(dateFormat)(date),
					}}
					colors={({ id }) => objectiveColors[id]}
					tooltip={({ point: { color, data, index } }) => (
						<div
							style={{
								padding: 12,
								color,
								background: '#222222',
								borderRadius: '8px',
								position: 'absolute',
								transform: index < dates.length / 2 ? 'translate(0,0)' : 'translate(-100%, 0)',
								width: 'max-content',
							}}
						>
							{data.xFormatted} - {visual.name}: {data.yFormatted}
						</div>
					)}
					useMesh
					enablePoints={false}
					animate={false}
					theme={isDarkMode ? darkModeTheme : props.context === objectiveGraphContext.LIST ? darkerBackgroundTheme : undefined}
					markers={cost ? [getMarker(cost, t('objectives.form.visualCost'), isDarkMode)] : []}
				/>
			</div>
			<div className="mt-3">
				{!!cost && !!totalQuantity && (
					<p className="mb-0">
						{t('objectives.chart.marketingCost')} :{' '}
						<GTooltip content={`${t('objectives.chart.formula.visualCost')} / ${t('objectives.chart.formula.soldItemsNumber')}`}>
							<strong>{formatValue(cost / totalQuantity)}</strong>
						</GTooltip>
					</p>
				)}
				{!!cost && totalTurnover > cost && (
					<p className="mb-0">
						<>
							{t('objectives.chart.marketingBenefit')} :{' '}
							<GTooltip content={`${t('objectives.chart.turnover')} - ${t('objectives.chart.formula.visualCost')}`}>
								<strong>{formatValue(totalTurnover - cost)}</strong>
							</GTooltip>
						</>
					</p>
				)}
				{!!cost && !!totalBenefit && (
					<p className="mb-0">
						<>
							{t('objectives.chart.makerProfitability')} :{' '}
							<GTooltip
								content={`(${t('objectives.chart.turnover')} - ${t('objectives.chart.benefit')}) / ${t('objectives.chart.formula.visualCost')}`}
							>
								<strong>{Math.floor((100 * (totalTurnover - totalBenefit)) / cost)}%</strong>
							</GTooltip>
						</>
					</p>
				)}
			</div>
		</div>
	)
})

TradeMarketingChart.propTypes = {
	data: PropTypes.object.isRequired,
	cost: PropTypes.number,
	context: PropTypes.oneOf(Object.values(objectiveGraphContext)).isRequired,
}

export default TradeMarketingChart
