import { saveAs } from 'file-saver'
import { apiClientService as client, setErrors } from '@alatimier/genesis-uic'
import { EXPORT_DOWNLOAD, EXPORT_DOWNLOAD_FAILURE, EXPORT_DOWNLOAD_SUCCESS } from './exportTypes'

const statisticsEndPoint = '/api/admin/v1/statistics'

export const download = (organizationId, from = '', to = '', spaceIds = [], deviceIds = []) => {
	return (dispatch) => {
		dispatch({ type: EXPORT_DOWNLOAD })
		const queryParams = new URLSearchParams({ from, to, spaceIds, deviceIds })
		return client.get(`${statisticsEndPoint}/organizations/${organizationId}/visuals?${queryParams.toString()}`).then(async (res) => {
			if (res.ok) {
				const filename = res.headers
					?.get('Content-Disposition')
					?.split(';')
					.find((n) => n.includes('filename='))
					.replace('filename=', '')
					.trim()
				const csvFile = await res.blob()
				saveAs(csvFile, filename)
				dispatch({
					type: EXPORT_DOWNLOAD_SUCCESS,
				})
			} else {
				dispatch(setErrors(['export.action.download.failure']))
				dispatch({
					type: EXPORT_DOWNLOAD_FAILURE,
				})
			}
		})
	}
}
