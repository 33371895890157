import { STATISTICS_GET_STATISTICS, STATISTICS_GET_STATISTICS_FAILURE, STATISTICS_GET_STATISTICS_SUCCESS } from './statisticsTypes'

const initialState = {
	pending: false,
	filters: {},
	data: {
		views: null,
		audience: null,
	},
}

const statisticsReducer = (state = initialState, action) => {
	switch (action.type) {
		case STATISTICS_GET_STATISTICS:
			return { ...state, pending: true }
		case STATISTICS_GET_STATISTICS_SUCCESS:
			return { ...state, pending: false, filters: action.filters, data: { ...state.data, ...action.data } }
		case STATISTICS_GET_STATISTICS_FAILURE:
			return { ...state, pending: false }
		default:
			return state
	}
}

export default statisticsReducer
