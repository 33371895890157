import { apiClientService as client } from '@alatimier/genesis-uic'
import { TAG_GET_TAGS, TAG_GET_TAGS_FAILURE, TAG_GET_TAGS_SUCCESS } from './tagTypes'

const tagEndPoint = '/api/admin/v1/tags'

export const getTags = (filter = '') => {
	return (dispatch) => {
		dispatch({ type: TAG_GET_TAGS })
		return client.get(`${tagEndPoint}?filter=${filter}`).then(async (res) => {
			const data = await res.json()
			if (res.ok) {
				dispatch({
					type: TAG_GET_TAGS_SUCCESS,
					list: data,
				})
			} else {
				dispatch({
					type: TAG_GET_TAGS_FAILURE,
				})
			}
		})
	}
}
