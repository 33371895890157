import { apiClientService as client } from '@alatimier/genesis-uic'
import {
	ORGANIZATION_GET_CAMPAIGNS,
	ORGANIZATION_GET_CAMPAIGNS_FAILURE,
	ORGANIZATION_GET_CAMPAIGNS_SUCCESS,
	ORGANIZATION_GET_DEVICES,
	ORGANIZATION_GET_DEVICES_FAILURE,
	ORGANIZATION_GET_DEVICES_SUCCESS,
	ORGANIZATION_GET_SEGMENTS,
	ORGANIZATION_GET_SEGMENTS_FAILURE,
	ORGANIZATION_GET_SEGMENTS_SUCCESS,
	ORGANIZATION_GET_SPACES,
	ORGANIZATION_GET_SPACES_FAILURE,
	ORGANIZATION_GET_SPACES_SUCCESS,
	ORGANIZATION_GET_VISUALS,
	ORGANIZATION_GET_VISUALS_FAILURE,
	ORGANIZATION_GET_VISUALS_SUCCESS,
} from './organizationsTypes'

const organizationEndPoint = '/api/admin/v1/organizations'

export const getSpaces = (organizationId) => {
	return (dispatch) => {
		dispatch({ type: ORGANIZATION_GET_SPACES })
		return client.get(`${organizationEndPoint}/${organizationId}/spaces`).then(async (res) => {
			if (res.ok) {
				const data = await res.json()
				dispatch({
					type: ORGANIZATION_GET_SPACES_SUCCESS,
					organizationId,
					list: data,
				})
			} else {
				dispatch({
					type: ORGANIZATION_GET_SPACES_FAILURE,
				})
			}
		})
	}
}

export const getSegments = (organizationId) => {
	return (dispatch) => {
		dispatch({ type: ORGANIZATION_GET_SEGMENTS })
		return client.get(`${organizationEndPoint}/${organizationId}/segments`).then(async (res) => {
			if (res.ok) {
				const data = await res.json()
				dispatch({
					type: ORGANIZATION_GET_SEGMENTS_SUCCESS,
					organizationId,
					list: data,
				})
			} else {
				dispatch({
					type: ORGANIZATION_GET_SEGMENTS_FAILURE,
				})
			}
		})
	}
}

export const getVisuals = (organizationId) => {
	return (dispatch) => {
		dispatch({ type: ORGANIZATION_GET_VISUALS })
		return client.get(`${organizationEndPoint}/${organizationId}/visuals`).then(async (res) => {
			if (res.ok) {
				const data = await res.json()
				dispatch({
					type: ORGANIZATION_GET_VISUALS_SUCCESS,
					organizationId,
					list: data,
				})
			} else {
				dispatch({
					type: ORGANIZATION_GET_VISUALS_FAILURE,
				})
			}
		})
	}
}

export const getDevices = (organizationId, strict = false) => {
	return (dispatch) => {
		dispatch({ type: ORGANIZATION_GET_DEVICES })
		return client.get(`${organizationEndPoint}/${organizationId}/devices?strict=${strict}`).then(async (res) => {
			if (res.ok) {
				const data = await res.json()
				dispatch({
					type: ORGANIZATION_GET_DEVICES_SUCCESS,
					organizationId,
					list: data,
				})
			} else {
				dispatch({
					type: ORGANIZATION_GET_DEVICES_FAILURE,
				})
			}
		})
	}
}

export const getCampaigns = (organizationId, organizationType) => {
	return (dispatch) => {
		dispatch({ type: ORGANIZATION_GET_CAMPAIGNS })
		const queryString = organizationType ? `?organizationType=${organizationType}` : ''
		return client.get(`${organizationEndPoint}/${organizationId}/campaigns${queryString}`).then(async (res) => {
			if (res.ok) {
				const data = await res.json()
				dispatch({
					type: ORGANIZATION_GET_CAMPAIGNS_SUCCESS,
					organizationId,
					list: data,
				})
			} else {
				dispatch({
					type: ORGANIZATION_GET_CAMPAIGNS_FAILURE,
				})
			}
		})
	}
}
