import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { GSecured, isEmpty } from '@alatimier/genesis-uic'
import VariantForm from './VariantForm'
import { variantShape } from './VariantTypes'

const VariantsEdit = (props) => {
	const { t } = useTranslation()

	const [creationError, setCreationError] = useState(null)

	const handleAddVariant = (newVariant) => {
		if (isEmpty(newVariant.software) || isEmpty(newVariant.name) || isEmpty(newVariant.value)) {
			setCreationError(t('devices.error.dvm.fields.required'))
			return false
		}
		if (props.variants.some((variant) => newVariant.software === variant.software && newVariant.name === variant.name)) {
			setCreationError(t('devices.error.dvm.duplicated'))
			return false
		}
		setCreationError(null)
		props.onChange([newVariant, ...props.variants])
		return true
	}

	const handleChangeVariant = (changedVariant, changedVariantIndex) => {
		props.onChange(props.variants.map((variant, index) => (index === changedVariantIndex ? changedVariant : variant)))
	}

	const handleRemoveVariant = (removedVariantIndex) => () => {
		props.onChange(props.variants.filter((_, index) => index !== removedVariantIndex))
	}

	return (
		<>
			<GSecured permission="DVM_EDIT">
				<div className="d-block invalid-feedback">{creationError}</div>
				<VariantForm onAdd={handleAddVariant} />
				{!isEmpty(props.variants) && <hr />}
			</GSecured>
			{props.variants?.map((variant, index) => (
				<VariantForm key={index} variant={variant} index={index} onChange={handleChangeVariant} onRemove={handleRemoveVariant(index)} />
			))}
		</>
	)
}

VariantsEdit.propTypes = {
	variants: PropTypes.arrayOf(variantShape).isRequired,
	onChange: PropTypes.func.isRequired,
}

export default VariantsEdit
