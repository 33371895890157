/** @jsxImportSource @emotion/react */
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { GInput, GSelect, isEmpty } from '@alatimier/genesis-uic'
import { periodKeys } from '../utils'

const dateOptions = { includeOffset: false, suppressSeconds: true }

const BaselineForm = (props) => {
	const { t } = useTranslation()

	const periodOptions = useMemo(() => {
		const options = Object.values(periodKeys).map((key) => ({ value: key, label: t(`timePeriod.${key}`) }))
		if (!props.campaignEnd)
			return options.filter((option) => option.value !== periodKeys.samePeriodBeforeCampaign && option.value !== periodKeys.samePeriodLastYear)
		else return options
	}, [props.campaignEnd])
	const customOption = periodOptions.find((o) => o.value === periodKeys.custom)

	const campaignStart = useMemo(() => {
		return props.campaignStart ? DateTime.fromISO(props.campaignStart) : undefined
	}, [props.campaignStart])
	const campaignEnd = useMemo(() => {
		return props.campaignEnd ? DateTime.fromISO(props.campaignEnd) : undefined
	}, [props.campaignEnd])

	const [period, setPeriod] = useState(periodOptions[0])
	const [error, setError] = useState(null)

	useEffect(() => prefillDates(), [period?.value, campaignStart, campaignEnd])
	useEffect(() => {
		// Period select should go back to first option when the campaign changes, except if it is optional and empty (meaning another reference has been selected)
		if (props.required || period) setPeriod(periodOptions[0])
	}, [campaignStart, campaignEnd])
	useEffect(() => {
		if (!props.start && !props.end) {
			setPeriod(undefined)
		} else if (props.start && props.end) {
			const periodFromPreset = getPeriodFromPreset()
			// If the dates don't match the preset, set preset to custom
			if (periodFromPreset && (+periodFromPreset.start !== +DateTime.fromISO(props.start) || +periodFromPreset.end !== +DateTime.fromISO(props.end))) {
				setPeriod(customOption)
			}
		} else {
			// If one date is missing, set preset to custom
			setPeriod(customOption)
		}
	}, [props.start, props.end])
	useEffect(() => {
		// On custom period option, prefill end date on start change
		// to have same period length than campaign
		if (props.start && period?.value === periodKeys.custom && props.campaignEnd) {
			const campaignTimeRange = campaignEnd.diff(campaignStart)
			const newEndDate = DateTime.fromISO(props.start).plus(campaignTimeRange)
			props.onEndChange(newEndDate.toISO(dateOptions))
		}
	}, [props.start])

	const getPeriodFromPreset = () => {
		if (!period || period.value === periodKeys.custom || !campaignStart) return
		if (period.value === periodKeys.samePeriodBeforeCampaign && props.campaignEnd) {
			return {
				start: campaignStart.minus(campaignEnd.diff(campaignStart)),
				end: campaignStart,
			}
		} else if (period.value === periodKeys.lastMonthBeforeCampaign) {
			return {
				start: campaignStart.minus({ month: 1 }),
				end: campaignStart,
			}
		} else if (period.value === periodKeys.lastYearBeforeCampaign) {
			return {
				start: campaignStart.minus({ year: 1 }),
				end: campaignStart,
			}
		} else if (period.value === periodKeys.samePeriodLastYear && props.campaignEnd) {
			return {
				start: campaignStart.minus({ year: 1 }),
				end: campaignEnd.minus({ year: 1 }),
			}
		}
	}

	const prefillDates = () => {
		if (props.allowAutoFill && props.campaignStart) {
			const periodFromPreset = getPeriodFromPreset()
			if (periodFromPreset) {
				props.onStartChange(periodFromPreset.start.toISO(dateOptions))
				props.onEndChange(periodFromPreset.end.toISO(dateOptions))
			}
		}
	}

	const overlapCampaign = () => props.end > campaignStart.toISO(dateOptions)
	const validate = () => {
		if (!isEmpty(props.start) && !isEmpty(props.end)) {
			if (props.start >= props.end) {
				setError(t('validation.error.date_interval'))
			} else if (overlapCampaign()) {
				setError(t('objectives.error.reference_period'))
			} else {
				setError(null)
			}
		} else {
			setError(null)
		}
	}

	return (
		<>
			<GSelect
				id="type"
				label={`${t('objectives.form.baseline')}${props.required ? ' *' : props.semiRequired ? ' (*)' : ''}`}
				options={periodOptions}
				value={period}
				onChange={setPeriod}
				clearable={props.semiRequired}
				css={{ marginBottom: '-0.8em' }}
			/>
			<div className="row mb-2">
				<div className="col-sm-6 col-md-5 offset-md-2">
					<div className="input-group align-items-center">
						<label htmlFor="baselineStart" className="col-auto me-2">
							{t('forms.date.start.short')}
						</label>
						<GInput id="baselineStart" type="datetime-local" value={props.start} onChange={props.onStartChange} onBlur={validate} simple />
					</div>
				</div>
				<div className="col-sm-6 col-md-5">
					<div className="input-group align-items-center">
						<label htmlFor="baselineEnd" className="col-auto me-2">
							{t('forms.date.end.short')}
						</label>
						<GInput id="baselineEnd" type="datetime-local" value={props.end} onChange={props.onEndChange} onBlur={validate} simple />
					</div>
				</div>
			</div>
			<div className="text-danger offset-md-2 input-error">{error}</div>
		</>
	)
}

BaselineForm.propTypes = {
	start: PropTypes.string,
	end: PropTypes.string,
	campaignStart: PropTypes.string,
	campaignEnd: PropTypes.string,
	onStartChange: PropTypes.func.isRequired,
	onEndChange: PropTypes.func.isRequired,
	required: PropTypes.bool,
	semiRequired: PropTypes.bool,
	allowAutoFill: PropTypes.bool,
}

export default BaselineForm
